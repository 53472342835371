'use client';

import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Button, Link, Box } from '@repo/ui';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { supportedCopilerLanguages } from '@repo/utils/articleUtils';
import styles from './CodeBlock.module.scss';

export default function CodeBlockWrapper({
    code,
    language,
    children,
    simple,
}: {
    code: string;
    language: string;
    children: ReactNode;
    simple: boolean;
}) {
    const copyCode = () => {
        navigator.clipboard.writeText(code);

        toast.success('Code copied to clipboard');
    };

    const [ref, setRefId] = useState<string>();

    // This must be in use effect because the server side rendering will generate not the same ref on both server and client
    useEffect(() => {
        setRefId(uuid());
    }, []);

    const setCodeRunnerData = () => {
        const codeRunnerData = JSON.parse(localStorage.getItem('codeRunnerData') || '{}');
        if (ref) {
            const newCodeRunnerData = JSON.stringify({
                ...codeRunnerData,
                [ref]: {
                    code,
                    language,
                    referrerUrl: window.location.href,
                    timestamp: new Date().getTime(),
                },
            });

            localStorage.setItem('codeRunnerData', newCodeRunnerData);
        }
    };

    const isCompilerSupported = supportedCopilerLanguages.includes(language);
    let url = `/${language}/online-compiler`;
    if (ref) {
        url += `?ref=${ref}`;
    }

    return (
        <div className={styles['code-block-wrapper']}>
            <Button onClick={copyCode} className={styles['copy-button']}>
                <ContentCopyIcon fontSize="small" />
            </Button>

            <div className={styles['code-block-content']}>{children}</div>

            {!simple && isCompilerSupported && (
                <Box className={styles['run-code-button']}>
                    <Link to={url} target="_blank">
                        <Button
                            onClick={setCodeRunnerData}
                            color="secondary"
                            startIcon={<PlayCircleIcon />}
                        >
                            Run
                        </Button>
                    </Link>
                </Box>
            )}
        </div>
    );
}
