'use client';

import { useState } from 'react';
import Box from '../Box';
import styles from './DropdownSelect.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useOuterClick } from '@repo/utils/hooksUtils';

type DropdownSelectItem = {
    key: string;
    text: string | JSX.Element;
};

type DropdownSelectProps = {
    items: DropdownSelectItem[];
    selected?: string;
    setSelected: any;
    maxHeight?: number;
};
const DropdownSelect = ({ items, selected, setSelected, maxHeight }: DropdownSelectProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toogleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (item: DropdownSelectItem) => {
        setSelected(item.key);

        toogleOpen();
    };

    const selectedItem = items.find((item) => item.key === selected)?.text || 'Select an option';

    const innerRef = useOuterClick(() => {
        setIsOpen(false);
    });

    return (
        <div ref={innerRef}>
            <Box className={styles.container} onClick={toogleOpen}>
                <Box className={styles.select}>
                    {selectedItem}
                    <ExpandMoreIcon />
                </Box>

                {isOpen && (
                    <Box className={styles.selectMenu} style={{ maxHeight }}>
                        {items.map((item) => (
                            <Box
                                key={item.key}
                                className={styles.option}
                                onClick={() => handleSelect(item)}
                            >
                                {item.text}
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default DropdownSelect;
