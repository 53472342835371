import styles from './IconTitleText.module.scss';
import { memo } from 'react';

import Box from '../../atoms/Box';
import Typography from '../../atoms/Typography';
import Card from '../../atoms/Card';

interface IIconTitleText {
    icon?: JSX.Element;
    title: string;
    body?: string;
}

const IconTitleText: React.FC<IIconTitleText> = ({ icon, title, body }) => {
    return (
        <Card className={styles['container']}>
            {icon && <Box className={styles['image-container']}>{icon}</Box>}

            <Box className={styles['title-body-container']}>
                <Box>
                    <Typography variant="h3" fontSize="1.5rem !important" margin="0 0 1rem 0">
                        {title}
                    </Typography>
                </Box>

                {body && (
                    <Box>
                        <Typography>{body}</Typography>
                    </Box>
                )}
            </Box>
        </Card>
    );
};

export default memo(IconTitleText);
