const JsonLdHeadTag = ({ jsonLd }: { jsonLd: any }) => {
    return (
        <>
            {/* Google Rich snippets */}
            {!!jsonLd && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(jsonLd),
                    }}
                />
            )}
        </>
    );
};

export default JsonLdHeadTag;
