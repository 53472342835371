import styles from './Logo.module.scss';

import classNames from 'classnames';
import FeaturedImage from '../FeaturedImage';

type LogoProps = {
    absolute?: boolean;
    width?: number;
    height?: number;
    Image: React.ElementType;
    companyName: string;
};

export default function Logo({
    absolute = false,
    width = 150,
    height = 60,
    Image,
    companyName = 'Code Highlights',
}: LogoProps) {
    if (!Image || Image === 'img') {
        throw new Error('Logo: Image props is required');
    }

    const logoClass = classNames(styles['logo-image'], {
        [styles['logo-image-absolute']]: absolute,
    });

    return (
        <div className={logoClass}>
            <FeaturedImage
                quality={100}
                Image={Image}
                lazyLoad={false}
                src="/logo.png"
                alt={`${companyName} logo`}
                width={width}
                height={height}
                noFullWidth
            />
        </div>
    );
}
