import { ArticleCard } from '@repo/ui';
import type { ArticlePrefix } from '@repo/utils/articleUtils';

const ArticleRenderer = ({
    item,
    prefix,
    Image,
    ...rest
}: {
    item: any;
    prefix: ArticlePrefix;
    Image: React.ElementType;
}) => <ArticleCard article={item} prefix={prefix} Image={Image} {...rest} />;

const CardCarouselItem = ({ CustomRenderer, Image, ...props }: any) => {
    if (!Image || Image === 'img') {
        throw new Error('CardCarouselItem: Image props is required');
    }

    if (CustomRenderer) {
        return <CustomRenderer {...props} Image={Image} />;
    }

    return <ArticleRenderer {...props} Image={Image} />;
};

export default CardCarouselItem;
