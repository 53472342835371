import Box from '../../atoms/Box';
import Typography from '../../atoms/Typography';

const SerpLocationPreview = ({ serpLocation }: { serpLocation: any }): JSX.Element => {
    return (
        <Box display="flex" alignItems="center">
            <img src={serpLocation.flag} alt={serpLocation.name} width={24} height={16} />

            <Typography margin="0 0 0 .5rem">{serpLocation.name}</Typography>
        </Box>
    )
};

export default SerpLocationPreview;
