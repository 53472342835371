import {
    ArticleCardGrid,
    PlatformCategoriesArticle,
    TrendingArticles,
    Box,
    RegularLayout,
} from '@repo/ui';
import type { SeoObjectInputs } from '@repo/utils/seoUtils';
import type { ArticlePrefix } from '@repo/utils/articleUtils';
import { generateItemList } from '@repo/utils/jsonLdUtils';
import ArticleListLayoutHeader from './ArticleListLayoutHeader';

const ArticleListLayout = ({
    defaultSeo,
    appCategories,
    footerLinks,
    seoData,
    subject,
    language,
    prefix,
    GuestsOnlyView,
    JoinNewsletter,
    articleState,
    Menu,
    Image,
    pathname,
    availableTabs,
    companyName = 'Code Highlights',
}: {
    defaultSeo: any;
    appCategories: any;
    footerLinks: any;
    seoData: Partial<SeoObjectInputs>;
    subject?: string;
    language?: string;
    prefix: ArticlePrefix;
    GuestsOnlyView?: any;
    JoinNewsletter?: any;
    articleState: any;
    Menu: any;
    Image: React.ElementType;
    pathname: string;
    availableTabs: ArticlePrefix[];
    companyName?: string;
}) => {
    if (!Image || Image === 'img') {
        throw new Error('ArticleListLayout: Image props is required');
    }

    const { languages, subjects } = appCategories;
    const { allArticles } = articleState[prefix];

    const jsonLDElementParams = {
        itemList: generateItemList(
            defaultSeo,
            'ARTICLE',
            `${companyName} ${prefix}`,
            allArticles || [],
        ),
    };

    return (
        <RegularLayout
            Image={Image}
            Menu={Menu}
            footerLinks={footerLinks}
            defaultSeo={defaultSeo}
            appCategories={appCategories}
            seoData={seoData}
            jsonLDElementParams={jsonLDElementParams}
            noBreadcrumbs
            headerSection={{
                titleRendered: () => null,
                children: (
                    <ArticleListLayoutHeader
                        Image={Image}
                        appCategories={appCategories}
                        seoData={seoData}
                        language={language}
                        subject={subject}
                        pathname={pathname}
                        availableTabs={availableTabs}
                    />
                ),
            }}
        >
            <Box>
                <PlatformCategoriesArticle
                    languages={languages}
                    subjects={subjects}
                    subject={subject}
                    language={language}
                    prefix={prefix}
                >
                    <TrendingArticles Image={Image} prefix={prefix} articleState={articleState} />

                    <ArticleCardGrid
                        Image={Image}
                        prefix={prefix}
                        articleState={articleState}
                        GuestsOnlyView={GuestsOnlyView}
                        JoinNewsletter={JoinNewsletter}
                    />
                </PlatformCategoriesArticle>
            </Box>
        </RegularLayout>
    );
};

export default ArticleListLayout;
