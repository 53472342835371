import classNames from "classnames"
import styles from "./Resources.module.scss"
import Link from "../../atoms/Link"

const ResourcesMenu = ({ resourceMenuItems }) => {
  const ResourcesMenuClass = classNames(styles["resources-menu-container"])
  const resourceMenuMapper = ({ route, name, target }) => (
    <Link to={route} target={target}>
      {name}
    </Link>
  )

  const splitOn = 2

  return (
    <div className={ResourcesMenuClass}>
      <div className={styles["resources-menu-items"]}>
        {resourceMenuItems.slice(0, splitOn).map(resourceMenuMapper)}
      </div>
      <div className={styles["resources-menu-items"]}>
        {resourceMenuItems.slice(splitOn).map(resourceMenuMapper)}
      </div>
    </div>
  )
}

export default ResourcesMenu
