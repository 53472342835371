import CircularProgress from '../CircularProgress';
import styles from './Spinner.module.scss';

type SpinnerProps = {
    margin?: string;
    size?: number;
};

export const Spinner = ({ margin = '2em 0 0 0', size }: SpinnerProps) => {
    return (
        <div className={styles['spinner-wrapper']} style={{ margin }}>
            <CircularProgress size={size} loading />
        </div>
    );
};

export default Spinner;
