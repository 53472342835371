'use client';

import type { FullWidthLayoutProps } from '@repo/ui';
import { Box } from '@repo/ui';
import FullWidthLayout from '../FullWidthLayout';

const DashboardLayout = ({
    defaultSeo,
    appCategories,
    footerLinks,
    seoData = {},
    Menu = () => <></>,
    children,
    loading = false,
    headerSection,
    HeaderSeparator,
    jsonLDElementParams,
    noHeader = false,
    noBreadcrumbs = false,
    sidebar,
    sidebarCollapsed,
    rightMenu,
    Image,
    pathname,
    searchParams,
}: FullWidthLayoutProps) => {
    return (
        <FullWidthLayout
            Image={Image}
            sidebar={sidebar}
            sidebarCollapsed={sidebarCollapsed}
            rightMenu={rightMenu}
            Menu={Menu}
            defaultSeo={defaultSeo}
            appCategories={appCategories}
            footerLinks={footerLinks}
            noHeader={noHeader}
            seoData={seoData}
            jsonLDElementParams={jsonLDElementParams}
            loading={loading}
            headerSection={headerSection}
            HeaderSeparator={HeaderSeparator}
            noBreadcrumbs={noBreadcrumbs}
            noFooter
            pathname={pathname}
            searchParams={searchParams}
        >
            <Box margin="2rem">{children}</Box>
        </FullWidthLayout>
    );
};

export default DashboardLayout;
