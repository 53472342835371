import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './NumberButton.module.scss';

export interface INumberButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'contained' | 'outlined' | 'text' | 'cta';
    color?: 'primary' | 'secondary' | 'default';
    // onClick: (e: any) => void
    // disabled
    loading?: boolean;
    margin?: string;
    padding?: string;
    fullWidth?: boolean;
    active?: boolean;
    className?: string;
}

export const NumberButton: React.FC<INumberButton> = ({
    children,
    onClick = () => {},
    disabled = false,
    type = 'button',
    // variant = "contained",
    // color = "primary",
    loading = false,
    // fullWidth = false,
    margin = '0',
    padding = '0.5rem 1rem',
    className = '',
    style = {},
    active,
    ...rest
}) => {
    const getButtonAriaLabel = useMemo(() => {
        if (typeof children === 'string') {
            return children;
        }
        return undefined;
    }, [children]);

    const onClickHandler = (e: any) => {
        onClick(e);

        // userBehaviourUtils.buttonPressed(getButtonAriaLabel)
    };

    const disabledButton = disabled || loading;

    const buttonClasses = classNames(styles['container'], {
        [styles['loading']]: loading,
        [styles['active']]: active,
        [styles['disabled']]: disabledButton,
        [className]: className,
    });

    return (
        <button
            onClick={onClickHandler}
            disabled={disabledButton}
            type={type}
            aria-label={getButtonAriaLabel}
            className={buttonClasses}
            style={{ ...style, margin, padding }}
            {...rest}
        >
            {loading ? (
                <div className={styles['loader']}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            ) : (
                children
            )}
        </button>
    );
};

export default NumberButton;
