import classNames from 'classnames';
import Box from '../../atoms/Box';
import styles from './Modal.module.scss';

export interface IModal {
    open: boolean;
    onClose?: () => void;
    style?: React.CSSProperties;
    children: React.ReactNode;
    fullWidth?: boolean;
    header?: React.ReactNode;
    footer?: React.ReactNode;
}

export const Modal: React.FC<IModal> = ({
    open,
    children,
    style,
    onClose,
    fullWidth = false,
    header,
    footer,
    ...rest
}) => {
    if (!open) return null;

    const dialogClass = classNames(styles.modalBackdrop, {
        [styles.fullWidth]: fullWidth,
    });
    return (
        <dialog
            className={dialogClass}
            onClick={() => {
                if (onClose) {
                    onClose();
                }
            }}
        >
            <Box
                className={styles.modalBox}
                style={{ ...modalStyle, ...style }}
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
                {...rest}
            >
                {header && <Box className={styles.modalHeader}>{header}</Box>}

                <Box className={styles.modalContent}>{children}</Box>

                {footer && <Box className={styles.modalFooter}>{footer}</Box>}
            </Box>
        </dialog>
    );
};

export default Modal;

const modalStyle: any = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '100vw', sm: 'unset' },
    maxWidth: { xs: '90vw', sm: 600, lg: 600 },
    bgcolor: 'var(--surface-color)',
    paddingBottom: '1rem',
    borderRadius: '4px',
    boxShadow:
        ' 0px 11px 1rem -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
};
