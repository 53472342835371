'use client';

// import userBehaviourUtils from "../../../utils/userBehaviour-utils"
import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './Button.module.scss';

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    // className
    variant?: 'contained' | 'outlined' | 'text' | 'cta';
    color?: 'primary' | 'secondary' | 'default' | 'accent';
    // onClick: (e: any) => void
    // disabled
    loading?: boolean;
    margin?: string;
    padding?: string;
    fullWidth?: boolean;
    className?: string;
    active?: boolean;
}

export const Button: React.FC<IButton> = ({
    startIcon,
    endIcon,
    children,
    onClick = () => {},
    disabled = false,
    type = 'button',
    variant = 'contained',
    color = 'primary',
    loading = false,
    fullWidth = false,
    margin = '0',
    padding = '0.5rem 1rem',
    className = '',
    style = {},
    active,
    ...rest
}) => {
    const getButtonAriaLabel = useMemo(() => {
        if (rest['aria-label']) {
            return rest['aria-label'];
        }

        if (typeof children === 'string') {
            return children;
        }

        return undefined;
    }, [children]);

    const onClickHandler = (e: any) => {
        onClick(e);

        // userBehaviourUtils.buttonPressed(getButtonAriaLabel)
    };

    const disabledButton = disabled || loading;
    const buttonClasses = classNames(styles['button'], {
        [styles['outlined']]: variant === 'outlined',
        [styles['text']]: variant === 'text',
        [styles['cta']]: variant === 'cta',
        [styles['secondary']]: color === 'secondary',
        [styles['accent']]: color === 'accent',
        [styles['loading']]: loading,
        [styles['fullWidth']]: fullWidth,
        [styles['disabled']]: disabledButton,
        [styles['active']]: active,
        [className]: className,
    });

    return (
        <button
            {...rest}
            onClick={onClickHandler}
            disabled={disabledButton}
            type={type}
            aria-label={getButtonAriaLabel}
            className={buttonClasses}
            style={{ ...(style || {}), margin, padding }}
        >
            {startIcon && <span className={'startIcon'}>{startIcon}</span>}

            {loading ? (
                <div className={styles['loader']}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            ) : (
                children
            )}

            {endIcon && <span className={'endIcon'}>{endIcon}</span>}
        </button>
    );
};

export default Button;
