'use client';

import { memo } from 'react';
import styles from './CodeBlock.module.scss';

const CodeBlock = ({ code, language = 'basic', className }: any) => {
    if (language === 'basic') {
        return <span className={styles['highlighted-code']}>{code}</span>;
    }

    return (
        <pre className={`${className} prism-highlight`}>
            <code className={`${className} prism-highlight`}>{code}</code>
        </pre>
    );
};

export default memo(CodeBlock);
