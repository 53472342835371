// Grid.tsx
import { ReactNode } from 'react';
import styles from './Grid.module.scss';
import classNames from 'classnames';
import { getComponentProps, muiStyleProps } from '../../../utils/component-style.utils';

type GridProps = {
    container?: boolean;
    item?: boolean;
    spacing?: number;
    rowSpacing?: number;
    columnSpacing?: number;
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
    display?: 'flex' | 'block';
    height?: string;
    justifyContent?:
        | 'flex-start'
        | 'center'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
        | 'space-evenly';
    flex?: number;
    flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    xs?: number | 'auto';
    sm?: number | 'auto';
    md?: number | 'auto';
    lg?: number | 'auto';
    xl?: number | 'auto';
    children: ReactNode;
    className?: string;
    gap?: number | string;
} & muiStyleProps;

const Grid = ({
    container,
    item,
    spacing = 0,
    rowSpacing = 0,
    columnSpacing = 0,
    direction = 'row',
    alignItems = 'stretch',
    justifyContent = 'flex-start',
    flexDirection,
    display = 'block',
    flex,
    height,
    xs,
    sm,
    md,
    lg,
    xl,
    gap,
    children,
    ...props
}: GridProps) => {
    const { style, rest } = getComponentProps(props);
    const containerClasses = classNames({
        [styles.container]: container,
        [styles[`spacing-${spacing}`]]: spacing,
        [styles[`rowSpacing-${rowSpacing}`]]: rowSpacing,
        [styles[`columnSpacing-${columnSpacing}`]]: columnSpacing,
        [styles[`direction-${direction}`]]: direction,
        [styles[`display-${display}`]]: display,
        [styles[`alignItems-${alignItems}`]]: alignItems,
        [styles[`justifyContent-${justifyContent}`]]: justifyContent,
        [rest.className]: rest.className,
    });

    const itemClasses = classNames({
        [styles.item]: item,
    });

    // Responsive classes
    const responsiveClasses = {
        [styles[`xs-${xs}`]]: xs,
        [styles[`sm-${sm}`]]: sm,
        [styles[`md-${md}`]]: md,
        [styles[`lg-${lg}`]]: lg,
        [styles[`xl-${xl}`]]: xl,
    };

    return (
        <div
            className={classNames(containerClasses, itemClasses, responsiveClasses)}
            style={{ ...style, height, flexDirection, flex, gap }}
        >
            {children}
        </div>
    );
};

export default Grid;
