import { ReactNode, CSSProperties } from 'react';

type CardProps = {
    children: ReactNode;
    style?: CSSProperties;
    elevation?: number;
    className?: string; // Add className prop
    onClick?: any;
};

export const Card = ({ children, style, elevation = 1, className, ...otherProps }: CardProps) => {
    const shadow = `0 ${elevation}px ${elevation * 2}px rgba(0, 0, 0, 0.2)`;
    const combinedStyles: CSSProperties = {
        boxShadow: shadow,
        borderRadius: '4px',
        color: 'var(--text-color)',
        padding: 0,
        backgroundColor: 'var(--surface-color)',
        ...style,
    };

    return (
        <div className={className} style={combinedStyles} {...otherProps}>
            {children}
        </div>
    );
};

export default Card;
