'use client';

import styles from './RatingList.module.scss';
import LinearProgress from '../../atoms/LinearProgress';
import { Card } from '../../atoms/Card';
import Container from '../../atoms/Container';
import { forwardRef, useState } from 'react';
import Button from '../../atoms/Button';
import { fromNow } from '@repo/utils/datetimeUtils';
import FeaturedImage from '../../atoms/FeaturedImage';
import RatingStars from '../../atoms/RatingStars';
import Box from '../../atoms/Box';
import Typography from '../../atoms/Typography';
import Grid from '../../organisms/Grid';

const RatingList = forwardRef(({ statistics = {}, reviews = [], Image }, ref) => {
    const [showAll, setShowAll] = useState(false);

    if (!statistics || !reviews?.length) {
        return null;
    }

    const rating = (+statistics.reviewsRatingAvg || 0)?.toFixed(1);
    const reviewsList = showAll ? reviews : reviews.slice(0, 4);

    return (
        <Box width="100%" padding="1rem 0 2.5rem 0">
            <Container>
                <Typography variant="h2" textAlign="center" fontWeight="bold" margin="1rem">
                    Don&apos;t just take our word for it
                </Typography>

                <div className={'rating-list-container'} id="rating-list" ref={ref}>
                    <div className={styles['rating-list-title']}>
                        <Card
                            style={{
                                padding: '1rem',
                                width: '100%',
                                maxWidth: 800,
                                marginBottom: '2rem',
                            }}
                        >
                            <Grid container alignItems="center">
                                <Grid md={2} xs={12} item>
                                    <div className={styles['rating-highlight-container']}>
                                        <Typography variant="h3" fontWeight="bold">
                                            {rating}+
                                        </Typography>
                                        <RatingStars count={5} value={rating} />
                                        <div className={styles['rating-review-text']}>
                                            from {statistics.reviewsCount} reviews
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={10} xs={12} alignItems="center" width="100%">
                                    <div>
                                        <Box display="flex" alignItems="center">
                                            <LinearProgress
                                                aria-label="5 star reviews"
                                                value={statistics.rating5percentage}
                                            />

                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                margin="0 0 0 0.5rem"
                                            >
                                                <RatingStars count={5} value={5} />
                                                <span
                                                    style={{
                                                        marginLeft: 8,
                                                        color: 'gray',
                                                        width: 30,
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {statistics.rating5percentage}%
                                                </span>
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <LinearProgress
                                                aria-label="4 star reviews"
                                                value={statistics.rating4percentage}
                                            />

                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                margin="0 0 0 0.5rem"
                                            >
                                                <RatingStars count={5} value={4} />
                                                <span
                                                    style={{
                                                        marginLeft: 8,
                                                        color: 'gray',
                                                        width: 30,
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {statistics.rating4percentage}%
                                                </span>
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <LinearProgress
                                                aria-label="3 star reviews"
                                                value={statistics.rating3percentage}
                                            />

                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                margin="0 0 0 0.5rem"
                                            >
                                                <RatingStars count={5} value={3} />
                                                <span
                                                    style={{
                                                        marginLeft: 8,
                                                        color: 'gray',
                                                        width: 30,
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {statistics.rating3percentage}%
                                                </span>
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <LinearProgress
                                                aria-label="2 star reviews"
                                                value={statistics.rating2percentage}
                                            />

                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                margin="0 0 0 0.5rem"
                                            >
                                                <RatingStars count={5} value={2} />
                                                <span
                                                    style={{
                                                        marginLeft: 8,
                                                        color: 'gray',
                                                        width: 30,
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {statistics.rating2percentage}%
                                                </span>
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <LinearProgress
                                                aria-label="1 star reviews"
                                                value={statistics.rating1percentage}
                                            />

                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                margin="0 0 0 0.5rem"
                                            >
                                                <RatingStars count={5} value={1} />
                                                <span
                                                    style={{
                                                        marginLeft: 8,
                                                        color: 'gray',
                                                        width: 30,
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {statistics.rating1percentage}%
                                                </span>
                                            </Box>
                                        </Box>
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </div>

                    <Grid container>
                        {reviewsList.map((review) => {
                            const description =
                                review.description.length > 250
                                    ? `${review.description.substring(0, 250)}...`
                                    : review.description;

                            return (
                                <Grid item xs={12} sm={6} key={review.id}>
                                    <Card
                                        style={{ padding: '1rem' }}
                                        className={styles['rating-item-container']}
                                    >
                                        <div className={styles['rating-item-profile']}>
                                            <Box className={styles['rating-profile-image']}>
                                                <FeaturedImage
                                                    Image={Image}
                                                    width={50}
                                                    height={50}
                                                    src={review.user.profile_picture}
                                                    alt={review.user.full_name}
                                                />
                                            </Box>

                                            <div>
                                                {review.user.full_name}
                                                <div className={styles['rating-profile-stars']}>
                                                    <RatingStars
                                                        count={5}
                                                        value={+(review.rating || 5)}
                                                        size={16}
                                                    />
                                                    <span style={{ marginLeft: 10 }}>
                                                        {fromNow(review.createdAt)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>{description}</div>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>

                    {!showAll && reviews?.length > 4 && (
                        <Box margin="2rem 0 0 0" display="flex" justifyContent="center">
                            <Button
                                onClick={() => {
                                    setShowAll(!showAll);
                                }}
                            >
                                See all reviews
                            </Button>
                        </Box>
                    )}
                </div>
            </Container>
        </Box>
    );
});

export default RatingList;
