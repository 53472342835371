import Skeleton from "../../atoms/Skeleton"
import classNames from "classnames"
import styles from "./Price.module.scss"
import Typography from "../../atoms/Typography"

const Price = ({
  pricing = {},
  courseList = false,
  flex = false,
  loading = false,
  payment = false,
  percentage = false,
  monthly = false,
  annual = false,
  showPrice = false,
  defaultDecimal = 2,
}) => {
  const oldPrice = (Math.round(pricing.price * 100) / 100).toFixed(defaultDecimal)
  const negativePrice = pricing.discountedPrice < 0
  const realPrice = negativePrice ? pricing.discountedPrice * -1 : pricing.discountedPrice
  const exactPrice = Math.round(realPrice * 100) / 100
  const price = exactPrice.toFixed(exactPrice == 0 && showPrice ? 0 : defaultDecimal)
  const currency = pricing.currency === "EUR" ? "€" : "$"

  const containerClass = classNames(styles["course-price-wrapper"], {
    [styles["course-price-wrapper-flex"]]: flex,
    [styles["course-price-wrapper-course-list"]]: courseList,
    [styles["course-price-wrapper-payment"]]: payment,
  })

  return (
    <div className={containerClass}>
      {loading ? (
        <Skeleton
          height={72}
          width="100%"
          animation="wave"
          variant="text"
          style={{ backgroundColor: "#242424" }}
        />
      ) : (
        <>
          <Typography
            fontSize="1.75rem"
            fontWeight="bold"
            className={styles["course-price"]}
          >
            {pricing.soon ? (
              "Coming soon"
            ) : +price === 0 && !showPrice ? (
              "FREE"
            ) : (
              <>
                {negativePrice && "-"} {currency}
                {price}
                {monthly && <span className={styles["course-price-monthly"]}>/mo</span>}
                {annual && <span className={styles["course-price-monthly"]}>/year</span>}
              </>
            )}
          </Typography>

          {!!pricing.discount && +oldPrice !== 0 && (
            <div className={styles["course-previous-price"]}>
              {!percentage && (
                <p className={styles["course-previous-price-old"]}>
                  {currency}
                  {oldPrice}
                </p>
              )}

              {(!flex || percentage) && (
                <p className={styles["course-price-discount"]}>{pricing.discount}% off</p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Price
