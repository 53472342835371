import classNames from "classnames"

import styles from "./Ribbon.module.scss"

export const Ribbon = ({ children, direction = "top-right", highlighted }) => {
  const containerClass = classNames(styles["ribbon"], {
    [styles[`ribbon-${direction}`]]: direction,
    [styles["ribbon-highlighted"]]: highlighted
  })

  return (
    <div className={containerClass}>
      <span>{children}</span>
    </div>
  )
}

export default Ribbon