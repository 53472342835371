'use client';

import { useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';

import styles from './Input.module.scss';

export interface InputProps {
    label?: string;
    name?: string;
    type?: string;
    defaultValue?: string;
    value?: string;
    // eslint-disable-next-line no-unused-vars
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    error?: string;
    required?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
    placeholder?: string;
    id?: string;
    multiline?: boolean;
    style?: React.CSSProperties;
    onClick?: any;
    containerStyle?: React.CSSProperties;
    fullwidth?: boolean;
    [key: string]: any;
}

const Input = forwardRef<any, InputProps>(
    (
        {
            label,
            name,
            type,
            defaultValue,
            value: propValue,
            onChange,
            error,
            required,
            autoFocus,
            autoComplete,
            placeholder,
            id,
            multiline,
            style,
            onClick,
            containerStyle,
            fullwidth,
            ...rest
        },
        ref,
    ) => {
        const [isFocused, setFocused] = useState(false);
        const [localValue, setLocalValue] = useState(defaultValue || '');

        // Synchronize internal state with prop value if it exists
        useEffect(() => {
            if (propValue !== undefined) {
                setLocalValue(propValue);
            }
        }, [propValue]);

        const isControlled = propValue !== undefined;
        const valueToUse = isControlled ? propValue : localValue;

        const handleInputChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            if (!isControlled) {
                // Only update the local state if the component is uncontrolled
                setLocalValue(event.target.value);
            }

            onChange && onChange(event);
        };

        const isFilled = valueToUse?.length > 0;
        const labelClasses = classNames(styles.label, {
            [styles.focused]: isFocused,
            [styles.filed]: isFilled,
            [styles.multiline]: multiline,
        });
        const inputClass = classNames(styles.input, { [styles.errorInput]: error });

        const mainProps = {
            placeholder,
            id,
            name,
            value: valueToUse,
            onChange: handleInputChange,
            className: inputClass,
            required,
            onFocus: () => setFocused(true),
            onBlur: () => setFocused(false),
            autoFocus,
            autoComplete,
            style,
            onClick,
            ...rest,
        };

        return (
            <div
                className={styles.inputContainer}
                style={{ width: fullwidth ? '100%' : undefined, ...containerStyle }}
            >
                <div className={styles.formControl}>
                    {multiline ? (
                        <textarea ref={ref} {...mainProps} rows={6} />
                    ) : (
                        <input ref={ref} {...mainProps} type={type} />
                    )}

                    {label && (
                        <label htmlFor={name} className={labelClasses}>
                            {label} {required && '*'}
                        </label>
                    )}
                </div>

                {error && (
                    <div className={styles.helperText} id={`${name}-helper-text`}>
                        {error}
                    </div>
                )}
            </div>
        );
    },
);

export default Input;
