import classNames from "classnames"
import styles from "./CatalogMenu.module.scss"
import Link from "../../atoms/Link"

const CatalogMenu = ({ categories }) => {
  const { subjects, languages } = categories
  const catalogMenuClass = classNames(styles["catalog-menu-container"])

  const subjectMapper = ({ subject, urlSlug }) => (
    <Link to={`/${urlSlug}`}>{subject}</Link>
  )
  const languageMapper = ({ language, urlSlug }) => (
    <Link to={`/${urlSlug}`}>{language}</Link>
  )

  return (
    <div className={catalogMenuClass}>
      <div className={styles["items"]}>
        <Link to={"/catalog"}>Explore all</Link>

        {languages && <div className={styles["items"]}>{languages.map(languageMapper)}</div>}
      </div>

      {subjects && <div className={styles["items"]}>{subjects.map(subjectMapper)}</div>}
    </div>
  )
}

export default CatalogMenu
