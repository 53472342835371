import { slugify } from '@repo/utils/stringUtils';
import styles from './FAQs.module.scss';
import Box from '../Box';
import Typography from '../Typography';
import Accordion from '../Accordion';
import { ReactNode } from 'react';

const DefaultTitleRendered = ({ children }: { children: ReactNode }) => (
    <Typography variant="h3" textAlign="center" margin="1.5rem 0">
        {children}
    </Typography>
);

const DefaultQuestionRenderer = ({ children }: { children: ReactNode }) => (
    <Typography variant="h4" fontSize="1.75rem !important" fontWeight="600">
        {children}
    </Typography>
);

const DefaultAnswerRenderer = ({ children }: { children: ReactNode }) => (
    <Typography>{children}</Typography>
);

export const FAQs = ({
    title = '',
    TitleRendered = DefaultTitleRendered,
    faqs = [],
    QuestionRenderer = DefaultQuestionRenderer,
    AnswerRenderer = DefaultAnswerRenderer,
}: {
    title?: string;
    TitleRendered?: any;
    faqs?: { question: string; answer: string }[];
    QuestionRenderer?: any;
    AnswerRenderer?: any;
}) => {
    if (!faqs?.length) {
        return null;
    }

    return (
        <Box className={styles['container']}>
            {!!title && <TitleRendered>{title}</TitleRendered>}

            {faqs.map(({ question, answer }) => {
                const slug = slugify(question, '');

                return (
                    <Accordion
                        key={slug}
                        question={<QuestionRenderer>{question}</QuestionRenderer>}
                        answer={<AnswerRenderer>{answer}</AnswerRenderer>}
                        slug={slug}
                    />
                );
            })}
        </Box>
    );
};

export default FAQs;
