"use client";

import { Box, Button, Input } from "@repo/ui";
import { redirect } from 'next/navigation'
import { useState } from "react";
import { useUserStore } from "@/store/mutable-data/user/UserStoreContext";
import { registerUser } from "@/store/mutable-data/user/actions";
import { getPromoCode } from "@repo/utils/localStorageUtils";

const RegisterForm = ({
  autoFocus = true,
  withoutReload = false,
  callback = undefined,
  buttonText = "Sign Up",
  nextRoute = "/",
}) => {
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const { user, setUserState } = useUserStore();
  const loggedIn = Boolean(user.id);

  const setErrors = (newState) => {
    setFormErrors((prevState) => ({ ...prevState, ...newState }));
  };

  const redirectRoute = nextRoute || "/";
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);

    const email = data.get("email");
    const password = data.get("password");
    const fullName = data.get("fullName");

    let hasError = false;
    if (
      !email ||
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      )
    ) {
      setErrors({ email: "You must enter a valid email address." });
      hasError = true;
    }
    if (!password) {
      setErrors({ password: "You must enter password." });
      hasError = true;
    }
    if (password.length < 8) {
      setErrors({ password: "Password must be at least 8 characters long." });
      hasError = true;
    }
    if (!fullName) {
      setErrors({ fullName: "You must enter your full name." });
      hasError = true;
    }
    if (hasError) {
      setLoading(false);
      return;
    }

    const affiliate = getPromoCode();
    const fake = process.env.NODE_ENV !== "production";
    await registerUser(
      {
        email,
        password,
        fullName,
        affiliate,
        fake,
      },
      (newUser) => {
        if (!withoutReload) {
          window.location.href = redirectRoute;
        }

        // if (newUser?.id) {
        //   if (socket && socket.emit) {
        //     socket.emit("AuthenticateUser", {
        //       userId: newUser.id,
        //     })
        //   }
        // }

        setUserState(newUser);

        if (callback) {
          callback();
        }
      }
    );

    setLoading(false);
  };

  const onFormChange = () => {
    setErrors({ email: "", password: "", fullName: "" });
  };

  if (loggedIn) {
    return redirect(redirectRoute);
  }

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      onChange={onFormChange}
      style={{
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Input
        autoComplete="name"
        name="fullName"
        required
        id="fullName"
        label="Full Name"
        autoFocus={autoFocus}
        error={formErrors.fullName}
      />
      <Input
        required
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        error={formErrors.email}
      />
      <Input
        required
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        error={formErrors.password}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        margin="1.5rem 0 1rem 0"
        loading={loading}
      >
        {buttonText}
      </Button>
    </Box>
  );
};
export default RegisterForm;
