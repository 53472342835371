import loggingService from '@repo/logger';

export const getLocalStorageItem = (key: string, defautValue = '') => {
    try {
        if (typeof localStorage !== 'undefined') {
            return localStorage.getItem(key) || defautValue;
        }
    } catch (error) {
        loggingService.sendErrors('getLocalStorageItem', error as any);
    }

    return defautValue;
};

export const setLocalStorageItem = (key: string, value: string) => {
    try {
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem(key, value);
        }
    } catch (error) {
        loggingService.sendErrors('setLocalStorageItem', error as any);
    }
};

export const getPromoCode = (initialAffiliate?: string) => {
    const affiliate = initialAffiliate || getLocalStorageItem('affiliate', 'new-gift');

    return affiliate;
};
