const matchSeoTextRegex = (keyword: string) => {
    const formattedKeyword = (keyword || '').replace(/\s+/g, '[-\\s]'); // Treats space and hyphen as equivalent
    const regExp = new RegExp(`(${formattedKeyword})`, 'gi');

    return regExp;
};

const wordCount = (text: string) => {
    const words = (text || '')?.trim().split(/\s+/);

    return words.length;
};

export default {
    matchSeoTextRegex,
    wordCount,
};
