import classNames from 'classnames';
import { ReactNode, CSSProperties } from 'react';
import styles from './Container.module.scss';

type ContainerProps = {
    children: ReactNode;
    maxWidth?: 'xl' | false;
    style?: CSSProperties;
    className?: string;
};

const maxWidthValues = {
    xl: '1440px',
};

export const Container = ({
    children,
    maxWidth = 'xl',
    style,
    className = '',
    ...otherProps
}: ContainerProps) => {
    const containerClass = classNames(styles.container, {
        [className]: className,
    });
    const resolvedMaxWidth = maxWidth ? maxWidthValues[maxWidth] : 'none';

    const combinedStyles: CSSProperties = {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: resolvedMaxWidth,
        ...style,
    };

    return (
        <div style={combinedStyles} className={containerClass} {...otherProps}>
            {children}
        </div>
    );
};

export default Container;
