import Box from '../../atoms/Box';
import DropdownSelect from '../../atoms/DropdownSelect';
import { useMemo } from 'react';
import SerpLocationPreview from '../SerpLocationPreview';

export interface ILanguagePicker<T> {
    contentLanguage: Record<string, any>;
    language?: T;
    selectLanguage: (language: T) => void;
}

const SerpLocationPicker = <T extends {}>({
    contentLanguage,
    language,
    selectLanguage,
}: ILanguagePicker<T>): JSX.Element => {
    const serpItem = useMemo(
        () =>
            Object.values(contentLanguage).map((serpLocation) => ({
                key: serpLocation.id,
                text: <SerpLocationPreview serpLocation={serpLocation} />,
            })),
        [],
    );

    return (
        <Box style={{ minWidth: 250, boxSizing: "" }}>
            <DropdownSelect
                maxHeight={250}
                items={serpItem}
                selected={language as unknown as string}
                setSelected={selectLanguage}
            />
        </Box>
    );
};

export default SerpLocationPicker;
