'use client';

import classNames from 'classnames';
import SidebarItem from '../../atoms/SidebarItem';

import styles from './SideMenu.module.scss';
import Box from '../../atoms/Box';
import { HTMLAttributeAnchorTarget } from 'react';

export type SideMenuItemType = {
    id: number | string;
    isActive?: boolean;
    linkTo?: HTMLAttributeAnchorTarget;
    linkTarget?: string;
    onClick?: () => void;
    name: string | JSX.Element;
    icon?: JSX.Element;
    disabled?: boolean;
};

interface ISideMenu {
    top?: JSX.Element;
    bottom?: JSX.Element;
    items: SideMenuItemType[];
    logo?: JSX.Element;
    containerStyle?: React.CSSProperties;
    collapsed?: boolean;
}

const SideMenu = ({
    items,
    containerStyle,
    collapsed = false,
    top,
    bottom,
}: ISideMenu): JSX.Element => {
    const containerClass = classNames(styles['side-menu-container'], {
        [styles['side-menu-container-collapsed']]: collapsed,
    });

    return (
        <div className={containerClass} style={containerStyle}>
            <div className={styles['inner']}>
                <div className={styles['items-container']}>
                    <Box>
                        {top && <div className={styles['top']}>{top}</div>}

                        {items.map((sidebarItem: SideMenuItemType) => (
                            <SidebarItem
                                key={sidebarItem.id}
                                collapsed={collapsed}
                                isActive={sidebarItem.isActive}
                                linkTo={sidebarItem.linkTo}
                                linkTarget={sidebarItem.linkTarget}
                                icon={sidebarItem.icon}
                                onClick={sidebarItem.onClick}
                                name={sidebarItem.name}
                                disabled={sidebarItem.disabled}
                            />
                        ))}
                    </Box>

                    {bottom && <div className={styles['bottom']}>{bottom}</div>}
                </div>
            </div>
        </div>
    );
};

export default SideMenu;
