export function calculateReadingTime(text: string, wpm = 200) {
    // Split the text into words using a regular expression
    const words = (text || '').trim().split(/\s+/);

    // Calculate the word count
    const wordCount = words.length || 0;

    // Calculate the reading time in minutes
    const readingTimeMinutes = Math.ceil(wordCount / wpm);

    return `${readingTimeMinutes} minutes`;
}

export enum ArticleType {
    // eslint-disable-next-line no-unused-vars
    BLOG = 'BLOG',
    // eslint-disable-next-line no-unused-vars
    TUTORIAL = 'TUTORIAL',
    // eslint-disable-next-line no-unused-vars
    CHEATSHEET = 'CHEATSHEET',
    // eslint-disable-next-line no-unused-vars
    DOC = 'DOC',
    // eslint-disable-next-line no-unused-vars
    CODE_EXAMPLE = 'CODE_EXAMPLE',
}

export const ArticlePrefixArray = [
    'tutorials',
    'blog',
    'catalog',
    'cheatsheet',
    'doc',
    'examples',
] as const;
export type ArticlePrefix = (typeof ArticlePrefixArray)[number];

export const getArticleType = (articlePrefix: ArticlePrefix): ArticleType => {
    switch (articlePrefix) {
        case 'tutorials':
            return ArticleType.TUTORIAL;
        case 'blog':
            return ArticleType.BLOG;
        case 'cheatsheet':
            return ArticleType.CHEATSHEET;
        case 'doc':
            return ArticleType.DOC;
        case 'examples':
            return ArticleType.CODE_EXAMPLE;
        default:
            return ArticleType.TUTORIAL;
    }
};

export const getArticlePrefix = (articleType: ArticleType = ArticleType.BLOG): ArticlePrefix => {
    switch (articleType) {
        case ArticleType.TUTORIAL:
            return 'tutorials';
        case ArticleType.BLOG:
            return 'blog';
        case ArticleType.CHEATSHEET:
            return 'cheatsheet';
        case ArticleType.DOC:
            return 'doc';
        case ArticleType.CODE_EXAMPLE:
            return 'examples';
        default:
            return 'blog';
    }
};

export const getFeaturedImage = (article: any) => {
    const featuredImage =
        article?.seoMetadata?.imageUrl ||
        'https://static.code-hl.com/resources/articles/main/tutorials-main.png';

    return featuredImage;
};

export const supportedCopilerLanguages = ['cpp', 'python', 'javascript', 'golang', 'java'];
