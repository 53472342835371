import { useEffect, useState, useRef } from 'react';

export default function useUserBehaviourEvent(eventCallback: any) {
    // const { initialized } = useAppSelector(state => state.appData)
    const initialized = true;

    useEffect(() => {
        async function loadData() {
            if (initialized && eventCallback) {
                eventCallback();
            }
        }

        loadData();
    }, [eventCallback, initialized]);
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const defualtSizes = {
        width: 1200,
        height: 900,
    };
    // if (typeof window !== "undefined") {
    //     defualtSizes.width = window.innerWidth
    //     defualtSizes.height = window.innerHeight
    // }

    const [windowSize, setWindowSize] = useState(defualtSizes);

    useEffect(() => {
        // only execute all the code below in client side
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export function useOuterClick(callback: any) {
    const callbackRef = useRef(); // initialize mutable ref, which stores callback
    const innerRef = useRef(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value
    useEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
        function handleClick(e: any) {
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target))
                callbackRef.current(e);
        }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself)
}
