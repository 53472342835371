import styles from './Skeleton.module.scss';
import cn from 'classnames';

interface SkeletonProps {
    height?: number; // Allow height customization
    className?: string; // Allow custom class names
    style?: React.CSSProperties; // Allow inline styles
}

export const Skeleton: React.FC<SkeletonProps> = ({ height = 34, className, style }) => {
    const skeletonStyle = {
        height: `${height}px`,
        ...style,
    };

    return <span className={cn(styles.skeleton, className)} style={skeletonStyle} />;
};

export default Skeleton;
