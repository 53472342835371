'use client';
import { useState } from 'react';
import { TabItemType } from '../../..';
import Tab from '../../atoms/Tab';

import styles from './Tabs.module.scss';
import classNames from 'classnames';

interface ITabs {
    items: TabItemType[];
    beforeTabs?: JSX.Element;
    initialTab?: number;
    // eslint-disable-next-line no-unused-vars
    onTabChange?: (tab: number) => void;
    noInnerState?: boolean;
    noBackground?: boolean;
}

const Tabs = ({
    items,
    beforeTabs = <></>,
    initialTab = 0,
    onTabChange, 
    noInnerState = false,
    noBackground,
}: ITabs): JSX.Element => {
    const [activeTab, setActiveTab] = useState(initialTab);

    const itemMapper = items.map((item) => {
        return (
            <Tab
                key={item.id}
                name={item.name}
                active={activeTab === item.id}
                onTabClick={() => {
                    if (onTabChange) {
                        onTabChange(item.id);
                    }

                    if (!noInnerState) {
                        setActiveTab(item.id);
                    }
                }}
            />
        );
    });

    const tabsClass = classNames(styles['tabs-wrapper'], {
        [styles['no-background']]: noBackground,
    });
    return (
        <div className={tabsClass}>
            {/* z-index less then modal (10000) */}
            <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                {beforeTabs}

                <div className={styles['tabs-container']}>{itemMapper}</div>
            </div>

            <div className={styles.content}>{items[activeTab]?.content}</div>
        </div>
    );
};

export default Tabs;
