import ReactMarkdown from 'react-markdown';
import { useMemo, Suspense } from 'react';
import { CodeBlockClient, Link } from '@repo/ui';
// import Image from "next/image";
import { slugify } from '@repo/utils/stringUtils';
import styles from './LectureDescription.module.scss';

function getChildrenText(children: any) {
    let label = '';

    if (typeof children !== 'string') {
        if (Array.isArray(children)) {
            children.forEach((child) => {
                label += getChildrenText(child);
            });
        } else {
            label += getChildrenText(children?.props?.children || '');
        }
    } else {
        label += children;
    }

    return label;
}

const Heading = ({ node: { tagName: Tag }, children, headerLinks }: any) => {
    const text = getChildrenText(children);
    const slug = slugify(text, '');

    const Header = () => <Tag id={slug}>{children}</Tag>;

    if (headerLinks) {
        return <Header />;
    }

    return <Header />;
};

const doFollowDomains = [
    'skylead.io',
    'code-hl.com',
    'yohdev.com',
    'codeinjs.net',
    'blogtube.in',
    'vioend.com',
    'https://www.instagram.com/code.highlights',
    'https://www.youtube.com/@codehighlights',
    'codeology.netlify.app',
    'codeology.net',
];

const CustomLink = ({ children, href }: any) => {
    const isInternal = href[0] === '/' || href.includes('code-hl.com');
    // extarnal link
    if (!isInternal) {
        const doFollow = doFollowDomains.find((domain) => href.includes(domain));
        const rel = doFollow ? 'noopener' : 'nofollow noopener noreferrer';

        return (
            <Link to={href} target="_blank" rel={rel}>
                {children}
            </Link>
        );
    }

    const internalLink = href.includes('?source') ? href : `${href}?source=blog`;

    // internal link
    return <Link to={internalLink}>{children}</Link>;
};

const CustomImage = ({ src, alt, title }: any) => {
    return (
        <img
            src={src}
            alt={alt}
            title={title}
            width={998}
            height={749}
            // quality={100}
            style={{ width: '100%', height: 'auto' }}
        />
    );
};

const DefaultContentRendered = ({ children }: any) => <div>{children}</div>;

const MarkdownCodeComponent = ({ className, children, ...props }: any) => {
    const match = useMemo(() => {
        return /language-(\w+)/.exec(className || '');
    }, [className]);

    if (match) {
        const code = String(children).replace(/\n$/, '');
        const language = match?.[1] || 'plaintext';

        return <CodeBlockClient {...props} code={code} language={language} className={className} />;
    }

    return <code className={className}>{children}</code>;
};

const PreComp = ({ children }: any) => {
    return children;
};

const MarkdownComponents = (headerLinks: any) => {
    const Headers = (props: any) => <Heading {...props} headerLinks={headerLinks} />;

    return {
        pre: PreComp,
        code: MarkdownCodeComponent,
        a: CustomLink,
        h1: Headers,
        h2: Headers,
        h3: Headers,
        h4: Headers,
        h5: Headers,
        h6: Headers,
        img: CustomImage,
    };
};

const MarkdownPreview = ({ text, headerLinks }: any) => {
    const allComponents = useMemo(() => {
        return MarkdownComponents(headerLinks);
    }, [headerLinks]);

    if (typeof text !== 'string') {
        return text;
    }

    return (
        <ReactMarkdown className={styles.markdown} components={allComponents}>
            {text}
        </ReactMarkdown>
    );
};

const LectureDescriptionClient = ({
    text,
    title = '',
    subtitle = '',
    headerLinks = false,
    ContentRendered = DefaultContentRendered,
}: any) => {
    return (
        <div className={`exercise-description-parsed ${styles['exercise-description-parsed']}`}>
            {(title || subtitle) && (
                <div className={styles['exercise-description-parsed-titles']}>
                    {subtitle && (
                        <p className={styles['exercise-description-parsed-titles-subtitle']}>
                            {subtitle}
                        </p>
                    )}
                    {title && <h2>{title}</h2>}
                </div>
            )}

            <ContentRendered>
                <Suspense>
                    <MarkdownPreview text={text} headerLinks={headerLinks} />
                </Suspense>
            </ContentRendered>
        </div>
    );
};

export default LectureDescriptionClient;
