import classNames from 'classnames';
import styles from './Stepper.module.scss';
import Box from '../../atoms/Box';

const Stepper = ({
    steps,
    activeStep = 0,
    loading,
    errored,
}: {
    steps: string[];
    activeStep?: number;
    loading?: boolean;
    errored?: boolean;
}) => {
    return (
        <Box className={styles.container}>
            <ul className={styles.stepContainer}>
                {steps.map((step, index) => {
                    const isActive = index <= activeStep;

                    const stepClass = classNames(styles.step, {
                        [styles.active]: isActive,
                        [styles.loading]: loading && index === activeStep,
                        [styles.errored]: errored && index === activeStep,
                    });

                    return (
                        <li key={index} className={stepClass}>
                            {step}
                        </li>
                    );
                })}
            </ul>
        </Box>
    );
};

export default Stepper;
