import { Box, CardCarousel } from '@repo/ui';
import type { ArticlePrefix } from '@repo/utils/articleUtils';

const RelatedArticles = ({
    title = 'Related articles',
    articles,
    prefix,
    margin = '3rem 0',
    Image,
}: {
    title?: string;
    articles: any;
    prefix: ArticlePrefix;
    margin?: string;
    Image: React.ElementType;
}) => {
    if (!Image || Image === 'img') {
        throw new Error('RelatedArticles: Image props is required');
    }

    if (!articles?.length) {
        return null;
    }

    return (
        <Box margin={margin}>
            <CardCarousel
                Image={Image}
                itemName="Article"
                itemProps={{ prefix, belowTheFold: true }}
                items={articles}
                title={title}
            />
        </Box>
    );
};

export default RelatedArticles;
