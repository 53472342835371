'use client';

import { useState, ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';

import styles from './Accordion.module.scss';

interface AccordionProps {
    question: ReactNode;
    answer: ReactNode;
    slug: string;
    defaultOpen?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({ question, answer, slug, defaultOpen }) => {
    const [expanded, setExpanded] = useState(defaultOpen);

    const toggleAccordion = () => {
        setExpanded(!expanded);
    };

    const toogleClass = classNames(styles.summary, {
        [`${styles.expanded}`]: expanded,
    });

    return (
        <div className={styles.accordion}>
            <div className={toogleClass} onClick={toggleAccordion} aria-controls={slug}>
                <div>{question}</div>
                <div
                    className={classNames(styles.expandIcon, {
                        [`${styles.expanded}`]: expanded,
                    })}
                >
                    <ExpandMoreIcon />
                </div>
            </div>
            <div
                className={classNames(styles.details, { [`${styles.expanded}`]: expanded })}
                id={slug}
            >
                {answer}
            </div>
        </div>
    );
};

export default Accordion;
