import styles from './ArticleMetaData.module.scss';
import { formatDateLL } from '@repo/utils/datetimeUtils';

import Box from '../../atoms/Box';
import Grid from '../Grid';

const ArticleMetaData = ({
    article,
    author,
    timeToRead,
    Image = 'img',
    companyName = 'Code Highlights',
}: {
    article: any;
    author?: { name: string; image: string };
    timeToRead?: string;
    Image: React.ElementType;
    companyName?: string;
}) => {
    if (!Image || Image === 'img') {
        throw new Error('ArticleMetaData: Image props is required');
    }

    const createdAtFormated = `${formatDateLL(article.updatedAt)}`;

    return (
        <Box style={{ marginTop: '1rem' }}>
            <Grid container className={styles['course-main-information']} gap={3} flex={3}>
                <Grid
                    item
                    gap={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Box className={styles.section}>Author</Box>
                    <Box display="flex" alignItems="center">
                        <Image
                            priority
                            loading="eager"
                            alt={`${companyName} team logo`}
                            title={`${companyName} team logo`}
                            src={author?.image}
                            width={30}
                            height={30}
                            className={styles['logo']}
                        />
                        <span
                            className="posted-by vcard author"
                            itemType="https://schema.org/Person"
                            itemScope="itemscope"
                            itemProp="author"
                        >
                            <span
                                className="author-name"
                                itemProp="name"
                                style={{ fontWeight: 'bold' }}
                            >
                                {author?.name}
                            </span>
                        </span>
                    </Box>
                </Grid>

                <Grid
                    item
                    gap={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Box className={styles.section}>Last updated</Box>
                    <Box fontWeight="bold">{createdAtFormated}</Box>
                </Grid>

                {/* <Grid gap={1} display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            <Box className={styles.section}>
              Views
            </Box>
            <Box fontWeight="bold">{article.seoMetadata.views}</Box>
          </Box>
        </Grid> */}

                {timeToRead && (
                    <Grid
                        item
                        gap={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Box className={styles.section}>Read Time</Box>
                        <Box className={styles['time-to-read']}>{timeToRead}</Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ArticleMetaData;
