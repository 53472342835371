import { CSSProperties, ElementType } from 'react';
import styles from './TopIconCard.module.scss';

import classNames from 'classnames';
import Box from '../../atoms/Box';
import Typography from '../../atoms/Typography';

interface ITopIconCard {
    Icon: ElementType;
    iconBackgroundColor?: string;
    title: string;
    value?: string;
    containerStyle?: CSSProperties;
    backgroundColor?: string;
    separatorBorderColor?: string;
    containerClassName?: string;
    highlighted?: boolean;
}

const TopIconCard = ({
    Icon,
    title,
    value,
    containerStyle,
    backgroundColor = 'var(--surface-color)',
    iconBackgroundColor = 'var(--secondary-color)',
    separatorBorderColor = 'var(--border-color)',
    highlighted,
}: ITopIconCard): JSX.Element => {
    const containerClassNames = classNames(styles['container'], {
        [styles['middle-container-card']]: highlighted,
    });
    return (
        <Box className={containerClassNames} style={{ ...containerStyle }}>
            {!!Icon && (
                <Box
                    className={styles['top-icon']}
                    style={{ backgroundColor: iconBackgroundColor }}
                >
                    <Icon style={{ width: 36, height: 36 }} />
                </Box>
            )}

            <Box className={styles['bottom-container']} style={{ backgroundColor }}>
                <Box
                    className={styles['above-child']}
                    style={{ borderColor: separatorBorderColor }}
                >
                    <Typography fontWeight={600} fontSize="1.5rem !important" textAlign="center">
                        {title}
                    </Typography>
                </Box>

                {value && (
                    <Box className={styles['below-child']}>
                        <Typography align="center" fontSize="1.3rem !important">
                            {value}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default TopIconCard;
