'use client';

import Box from '../Box';
import { useEffect, useState } from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share';
import Button from '../Button';
import ContentCopy from '@mui/icons-material/ContentCopy';

export const SocialShare = ({ url }: { url: string }) => {
    const [copy, setCopy] = useState(false);

    useEffect(() => {
        if (copy) {
            setTimeout(() => {
                setCopy(false);
            }, 2500);
        }
    }, [copy]);

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Button
                onClick={() => {
                    navigator.clipboard.writeText(url);
                    setCopy(true);
                }}
                margin="5px 5px 10px 5px"
            >
                <ContentCopy style={{ marginRight: '5px' }} /> {copy ? 'Copied!' : 'Copy Link'}
            </Button>

            <FacebookShareButton style={{ display: 'flex', marginRight: 5 }} url={url}>
                <FacebookIcon round size={40} />
            </FacebookShareButton>

            <LinkedinShareButton style={{ display: 'flex', marginRight: 5 }} url={url}>
                <LinkedinIcon round size={40} />
            </LinkedinShareButton>

            <TwitterShareButton style={{ display: 'flex' }} url={url}>
                <TwitterIcon round size={40} />
            </TwitterShareButton>
        </Box>
    );
};

export default SocialShare;
