import { getComponentProps, muiStyleProps } from '../../../utils/component-style.utils';
import { CSSProperties, ReactNode, ElementType } from 'react';
import styles from './Typography.module.scss';
import classNames from 'classnames';

type TypographyProps = {
    children: ReactNode;
    component?: ElementType;
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'caption' | 'overline';
    color?: CSSProperties['color'];
    align?: CSSProperties['textAlign'];
    fontSize?: CSSProperties['fontSize'];
    fontWeight?: CSSProperties['fontWeight'];
    className?: string;
    id?: string;
} & muiStyleProps;

const getColor = (color?: string) => {
    if (color === 'primary') return 'var(--primary-color)';
    if (color === 'secondary') return 'var(--secondary-color)';
    if (color === 'accent') return 'var(--accent-color)';
    if (color === 'error') return 'var(--red)';
    if (color === 'warning') return 'var(--yellow)';
    if (color === 'success') return 'var(--green)';
    if (color === 'GrayText') return 'var(--gray)';

    return color;
};

const Typography = ({ children, component, variant = 'body1', ...props }: TypographyProps) => {
    const Component = component || variantMapping[variant] || 'span';
    const color = getColor(props.color);
    const { style, rest } = getComponentProps({ ...props, color });

    const classes = classNames(styles.container, {
        [rest.className]: rest.className,
    });
    return (
        <Component style={style} {...rest} className={classes}>
            {children}
        </Component>
    );
};

const variantMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    body1: 'p',
    body2: 'p',
    caption: 'span',
    overline: 'span',
};

export default Typography;
