import Box from '../../atoms/Box';
import FeaturedImage from '../../atoms/FeaturedImage';
import Typography from '../../atoms/Typography';
import PageHighlightSection from '../../organisms/PageHighlightSection';
import DefaultFragmentChildrenRenderer from '../../organisms/PageHighlightSection/DefaultFragmentChildrenRenderer';

const HeaderChildrenRendered = ({
    title,
    descriptions,
    actionButton,
}: {
    title?: string;
    descriptions?: string[];
    actionButton?: React.ReactNode;
}) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            margin="auto"
            style={{ maxWidth: 900 }}
            alignItems="center"
        >
            {title && (
                <Typography margin="2rem 0 0 0" fontSize="4rem" variant="h1" textAlign="center">
                    {title}
                </Typography>
            )}

            {descriptions &&
                descriptions.map((description, index) => (
                    <Typography
                        key={index}
                        textAlign="center"
                        margin={index === 0 ? '1rem 0 0 0' : '0'}
                    >
                        {description}
                    </Typography>
                ))}

            {!!actionButton && actionButton}
        </Box>
    );
};

const HeaderSubtitleRenderer = ({
    Image,
    HeaderSeparator = () => null,
    imageData,
}: {
    Image: any;
    HeaderSeparator: any;
    imageData?: {
        src: string;
        width: number;
        height: number;
        alt: string;
    };
}) => {
    return (
        <Box>
            {imageData && (
                <PageHighlightSection
                    ChildrenRenderer={DefaultFragmentChildrenRenderer}
                    background="var(--gradient-background)"
                    padding="0 0 2rem 0"
                >
                    <FeaturedImage
                        Image={Image}
                        src={imageData.src}
                        width={imageData.width}
                        height={imageData.height}
                        lazyLoad={false}
                        quality={100}
                        alt={imageData.alt}
                        radius
                    />
                </PageHighlightSection>
            )}

            <HeaderSeparator />
        </Box>
    );
};

const CenteredHeaderLayout = ({
    title,
    descriptions,
    MainLayout,
    seoData,
    children,
    actionButton,
    HeaderSeparator,
    Image,
    imageData,
}: {
    title?: string;
    descriptions?: string[];
    MainLayout: any;
    seoData: any;
    children: React.ReactNode;
    actionButton?: React.ReactNode;
    HeaderSeparator: any;
    Image: any;
    imageData?: {
        src: string;
        width: number;
        height: number;
        alt: string;
    };
}) => {
    return (
        <MainLayout
            seoData={seoData}
            headerSection={{
                titleRendered: () => null,
                rendered: () => (
                    <HeaderChildrenRendered
                        title={title}
                        descriptions={descriptions}
                        actionButton={actionButton}
                    />
                ),
            }}
            noBreadcrumbs
            HeaderSeparator={
                <HeaderSubtitleRenderer
                    Image={Image}
                    imageData={imageData}
                    HeaderSeparator={HeaderSeparator}
                />
            }
        >
            {children}
        </MainLayout>
    );
};

export default CenteredHeaderLayout;
