'use client';

import { Button, Box, Typography, Grid } from '@repo/ui';
import { useEffect, useMemo, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CardCarouselItem from './CardCarouselItem';
import { useWindowSize } from '@repo/utils/hooksUtils';

const CardCarousel = ({
    items,
    itemProps = {},
    title,
    itemName,
    CustomRenderer,
    Image,
}: {
    items: any;
    itemProps?: any;
    title: string;
    itemName: string;
    CustomRenderer?: any;
    Image: React.ElementType;
}) => {
    if (!Image || Image === 'img') {
        throw new Error('CardCarousel: Image props is required');
    }

    const [offsetPage, setOffsetPage] = useState(0);

    const { width } = useWindowSize();
    useEffect(() => {
        setOffsetPage(0);
    }, [width]);

    const cardsPerPage = useMemo(() => {
        if (width >= 1200) {
            return 3;
        } else if (width >= 600) {
            return 2;
        }

        return 1;
    }, [width]);

    if (!items?.length) {
        return null;
    }

    const filteredItems = items.filter(
        (_item: any, index: number) =>
            index >= offsetPage * cardsPerPage && index < offsetPage * cardsPerPage + cardsPerPage,
    );
    return (
        <Box>
            <Grid container>
                <Grid item sm={6}>
                    <Typography margin="0 0 1.5rem 0" variant="h2">
                        {title}
                    </Typography>
                </Grid>

                <Grid item sm={6} display="flex" justifyContent="flex-end" alignItems="center">
                    <Typography>
                        {items.length} {`${itemName}${items.length > 1 ? 's' : ''}`}
                    </Typography>
                    <Box display="flex" margin="0 0 0 1rem">
                        <Button
                            aria-label="like"
                            variant="text"
                            disabled={offsetPage === 0}
                            onClick={() => {
                                setOffsetPage(offsetPage - 1);
                            }}
                        >
                            <ArrowBackIosIcon />
                        </Button>

                        <Button
                            aria-label="like"
                            variant="text"
                            disabled={(offsetPage + 1) * cardsPerPage >= items.length}
                            onClick={() => {
                                setOffsetPage(offsetPage + 1);
                            }}
                        >
                            <ArrowForwardIosIcon />
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={4}>
                {filteredItems.map((item: any, index: number) => (
                    <CardCarouselItem
                        Image={Image}
                        CustomRenderer={CustomRenderer}
                        key={item.id}
                        item={item}
                        index={index}
                        {...itemProps}
                    />
                ))}
            </Grid>
        </Box>
    );
};

export default CardCarousel;
