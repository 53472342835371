'use client';

import styles from './SERPSEOChecklist.module.scss';
import { Accordion, Box } from '../../..';
import { ISERPSEOChecklist } from './types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const SERPSEOChecklist = (props: ISERPSEOChecklist) => {
    const getStatusLabel = (items: any) => {
        const errorCount = items.filter((item: any) => !item.score).length;
        if (errorCount === 0) {
            return 'All Good';
        } else {
            return `${errorCount} Errors`;
        }
    };

    // if (loading) {
    //  return <Spinner />;
    // }

    if (!props.allSections) {
        return null;
    }

    return (
        <Box
            backgroundColor="var(--background-color)"
            width="100%"
            padding="0 1rem 1rem 1rem"
            className={styles['seo-dropdowns']}
        >
            {props.allSections?.map((section: any, index) => {
                const status = getStatusLabel(section.items);

                return (
                    <Accordion
                        defaultOpen={index === 0}
                        slug={section.title}
                        key={index}
                        question={
                            <Box display="flex">
                                {section.title}

                                <Box
                                    margin="0 0 0 1rem"
                                    style={{ borderRadius: '4px', padding: '0 5px' }}
                                    className={
                                        status === 'All Good' ? styles.success : styles.error
                                    }
                                >
                                    {status}
                                </Box>
                            </Box>
                        }
                        answer={
                            <Box>
                                {section.items.map(
                                    ({ score, maxScore, text }: any, idx: number) => {
                                        return (
                                            <Box
                                                key={idx}
                                                display="flex"
                                                alignItems="center"
                                                margin="0 0 0.5rem 0"
                                            >
                                                {score === maxScore ? (
                                                    <CheckCircleIcon
                                                        style={{ color: 'var(--green)' }}
                                                    />
                                                ) : score > 0 ? (
                                                    <CheckCircleOutlineIcon
                                                        style={{ color: 'var(--yellow)' }}
                                                    />
                                                ) : (
                                                    <CancelIcon style={{ color: 'var(--red)' }} />
                                                )}

                                                <Typography marginLeft="0.5rem">{text}</Typography>
                                            </Box>
                                        );
                                    },
                                )}
                            </Box>
                        }
                    />
                );
            })}
        </Box>
    );
};

export default SERPSEOChecklist;
