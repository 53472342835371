import fetchWrapper from '@repo/fetcher';

const env = process.env.NODE_ENV;

const sendErrors = async (message: string, additional = {}) => {
    try {
        if (env !== 'development') {
            await fetchWrapper.post(`/system/logs/errors`, {
                message,
                additional: JSON.stringify(additional),
            });
        }
    } catch (error) {
        //
    }
};

const sendWarnings = async (message: string, additional = {}) => {
    try {
        if (env !== 'development') {
            await fetchWrapper.post(`/system/logs/warnings`, {
                message,
                additional: JSON.stringify(additional),
            });
        }
    } catch (error) {
        //
    }
};

const sendInfos = async (message: string, additional = {}) => {
    try {
        if (env !== 'development') {
            await fetchWrapper.post(`/system/logs/infos`, {
                message,
                additional: JSON.stringify(additional),
            });
        }
    } catch (error) {
        //
    }
};

const loggingService = {
    sendErrors,
    sendWarnings,
    sendInfos,
};

export default loggingService;
