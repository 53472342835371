import styles from './Breadcrumbs.module.scss';
import classNames from 'classnames';
import Typography from '../Typography';
import Link from '../Link';
import Box from '../Box';

interface BreadcrumbItem {
    name: string;
    clickable: boolean;
    url?: string;
}

interface BreadcrumbsProps {
    breadcrumbs: BreadcrumbItem[];
    className?: string;
    separator?: React.ReactNode; // Allows customization of the separator
    title?: string;
}

function Crumb({
    title,
    name,
    url,
    clickable,
}: {
    title?: string;
    name: string;
    url: any;
    clickable: boolean;
}) {
    const crumbClass = classNames(styles['crumb'], {
        [`${styles['crumb-link']}`]: clickable,
        [`${styles['crumb-disabled']}`]: !clickable,
    });

    if (!clickable) {
        return <Typography className={crumbClass}>{title || name}</Typography>;
    }

    return (
        <Link className={crumbClass} to={url}>
            {name}
        </Link>
    );
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
    breadcrumbs,
    className,
    separator,
    title,
}) => {
    return (
        <nav className={classNames(styles.breadcrumbWrapper, className)} aria-label="breadcrumb">
            <ol>
                {breadcrumbs.map((crumb, index) => {
                    return (
                        <Box display="flex" alignItems="center" key={index}>
                            <li>
                                <Crumb
                                    key={index}
                                    title={title}
                                    name={crumb.name}
                                    clickable={crumb.clickable}
                                    url={crumb.url}
                                />
                            </li>

                            {index < breadcrumbs.length - 1 && <li>{separator}</li>}
                        </Box>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
