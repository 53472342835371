import { ArticlePrefix } from '@repo/utils/articleUtils';
import PlatformCategories from '../PlatformCategories';

const PlatformCategoriesArticle = ({
    languages,
    subjects,
    language = undefined,
    subject = undefined,
    children,
    prefix = 'tutorials',
}: {
    languages: any;
    subjects: any;
    language?: string | undefined;
    subject?: string | undefined;
    children: any;
    prefix?: ArticlePrefix;
}) => {
    return (
        <PlatformCategories
            languages={languages}
            subjects={subjects}
            language={language}
            subject={subject}
            prefix={prefix}
        >
            {children}
        </PlatformCategories>
    );
};

export default PlatformCategoriesArticle;
