import Box from "../../atoms/Box"
import Button from "../Button"

const BoxWrapper = ({ children }) => (
  <Box display="flex" margin="2rem 0 0 0" width="100%">
    {children}
  </Box>
)

const GuidingActionButtons = ({
  activeGuideStep,
  goBack,
  goNext,
  onClose,
  hasPrevious,
  hasNext,
}) => {
  if (activeGuideStep.type === "init") {
    return (
      <BoxWrapper>
        <Button color="secondary" margin="0 auto" onClick={goNext}>
          Start
        </Button>
      </BoxWrapper>
    )
  }

  if (activeGuideStep.type === "end") {
    return (
      <BoxWrapper>
        <Button color="secondary" margin="0 auto" onClick={onClose}>
          Done
        </Button>
      </BoxWrapper>
    )
  }

  if (hasPrevious || hasNext) {
    return (
      <BoxWrapper>
        {hasPrevious && (
          <Button variant="outlined" color="secondary" onClick={goBack}>
            Previous
          </Button>
        )}

        {hasNext && (
          <Button color="secondary" maring="0 0 0 auto" onClick={goNext}>
            Next
          </Button>
        )}
      </BoxWrapper>
    )
  }

  return null
}

export default GuidingActionButtons
