import styles from './Gauge.module.scss';

type GaugeSize = 'DEFAULT' | 'SMALL';

type GaugeProps = {
    noScore?: boolean;
    size?: GaugeSize; // Score is assumed to be a percentage
    score?: number; // Score is assumed to be a percentage
};

const Gauge: React.FC<GaugeProps> = ({ noScore = false, score = 0, size = 'DEFAULT' }) => {
    const rotationAngle = (score / 100) * 270 - 90;

    if (size === 'SMALL') {
        const greenCircleDashArray = `${(score / 100) * 180} ${360 - (score / 100) * 180}`;

        return (
            <div className={styles.gaugeContainer}>
                <svg
                    viewBox="0 25 100 50"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <circle
                        cx="50"
                        cy="70"
                        r="40"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="7"
                        pathLength="360"
                        strokeDasharray="180 180"
                        strokeDashoffset="180"
                    />

                    <circle
                        cx="50"
                        cy="70"
                        r="40"
                        fill="none"
                        stroke="var(--secondary-color)"
                        strokeWidth="7"
                        pathLength="360"
                        strokeDasharray={greenCircleDashArray}
                        strokeDashoffset="180"
                    />
                    <g transform="translate(50, 70)">
                        <text className={styles['small-text']}>{noScore ? 'N/A' : score}</text>
                    </g>
                </svg>
            </div>
        );
    }

    return (
        <div className={styles.gaugeContainer}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 276 217"
                preserveAspectRatio="xMidYMid meet"
            >
                <g transform="translate(34.31750000000001, 24.990000000000002)">
                    <g
                        className={`${styles.doughnut} doughnut`}
                        transform="translate(103.8075, 103.8075)"
                    >
                        <g className="subArc">
                            <path
                                d="M-74.414,61.145A7,7,0,0,1,-84.757,59.935A103.808,103.808,0,0,1,-80.437,-65.619A7,7,0,0,1,-70.035,-66.116L-65.252,-61.278A7,7,0,0,1,-64.77,-51.975A83.046,83.046,0,0,0,-68.19,47.4A7,7,0,0,1,-69.31,56.647Z"
                                className={styles.red}
                            ></path>
                        </g>
                        <g className="subArc">
                            <path
                                d="M-65.308,-70.788A7,7,0,0,1,-64.693,-81.184A103.808,103.808,0,0,1,60.903,-84.065A7,7,0,0,1,61.994,-73.708L57.438,-68.656A7,7,0,0,1,48.179,-67.642A83.046,83.046,0,0,0,-51.229,-65.362A7,7,0,0,1,-60.525,-65.95Z"
                                className={score > 33 ? styles.orange : styles.default}
                            ></path>
                        </g>
                        <g className="subArc">
                            <path
                                d="M66.93,-69.257A7,7,0,0,1,77.344,-69.238A103.808,103.808,0,0,1,84.757,59.935A7,7,0,0,1,74.414,61.145L69.31,56.647A7,7,0,0,1,68.19,47.4A83.046,83.046,0,0,0,62.319,-54.89A7,7,0,0,1,62.374,-64.205Z"
                                className={score > 66 ? styles.green : styles.default}
                            ></path>
                        </g>
                    </g>
                    <g
                        transform={`translate(103.8075, 103.8075) rotate(${rotationAngle})`}
                        className={`${styles.pointer}`}
                    >
                        <path
                            d="M -6.733694565708995 1.95404744191264 L -51.27436401698858 -56.241092659253454 L 6.733694565708995 -11.45704744191264"
                            className={styles.pointerPath}
                        />
                        <circle cx="0" cy="-4.7515" r="9.503" className={styles.pointerCircle} />
                    </g>
                    <g className="value-text" transform="translate(103.8075, 170.520875)">
                        <text transform="rotate(0)" className={styles.mainValue}>
                            {score}%
                        </text>
                    </g>

                    <g
                        className="tick-line"
                        transform="translate(23.953566090579045, 172.6598407099131)"
                    >
                        <text
                            transform="rotate(-42)"
                            style={{
                                fontSize: '18px',
                                fill: 'rgb(173, 172, 171)',
                                textAnchor: 'end',
                            }}
                        >
                            _
                        </text>
                    </g>
                    <g
                        className="tick-value"
                        transform="translate(10.36879364656916, 187.46890323174222)"
                    >
                        <text
                            transform="rotate(0)"
                            style={{
                                fontSize: '10px',
                                fill: 'rgb(173, 172, 171)',
                                textAnchor: 'end',
                            }}
                        >
                            0%
                        </text>
                    </g>
                    <g
                        className="tick-line"
                        transform="translate(183.66143390942096, 172.65984070991308)"
                    >
                        <text
                            transform="rotate(44)"
                            style={{
                                fontSize: '18px',
                                fill: 'rgb(173, 172, 171)',
                                textAnchor: 'start',
                            }}
                        >
                            _
                        </text>
                    </g>
                    <g
                        className="tick-value"
                        transform="translate(197.24620635343086, 187.46890323174222)"
                    >
                        <text
                            transform="rotate(0)"
                            style={{
                                fontSize: '10px',
                                fill: 'rgb(173, 172, 171)',
                                textAnchor: 'start',
                            }}
                        >
                            100%
                        </text>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default Gauge;
