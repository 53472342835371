'use client';

import { TabMenu, FeaturedImage, Box, Grid, Typography } from '@repo/ui';
// import Image from "next/image";
import { getArticlesCategorySeoData, type SeoObjectInputs } from '@repo/utils/seoUtils';
import { LectureDescription } from '@repo/ui';
import { ArticlePrefix, supportedCopilerLanguages } from '@repo/utils/articleUtils';
import styles from './ArticleListLayout.module.scss';

export default function ArticleListLayoutHeader({
    appCategories,
    seoData,
    subject,
    language,
    Image,
    pathname,
    availableTabs = ['tutorials', 'blog'],
}: {
    appCategories: any;
    seoData: Partial<SeoObjectInputs>;
    subject?: string;
    language?: string;
    Image: React.ElementType;
    pathname: string;
    availableTabs?: ArticlePrefix[];
}) {
    if (!Image || Image === 'img') {
        throw new Error('ArticleListLayoutHeader: Image props is required');
    }

    let path = '';
    if (language) {
        path = `/${language}`;
    } else if (subject) {
        path = `/${subject}`;
    }

    const categorySelector =
        getArticlesCategorySeoData(appCategories, { subject, language }).title || 'Full';
    const catalogLabel = `${categorySelector} Catalog`.trim();
    const tabs: any =
        availableTabs.length > 1 ? [{ label: catalogLabel, url: path ? path : '/catalog' }] : [];

    const showTutorials = availableTabs.find((tab: ArticlePrefix) => tab === 'tutorials');
    if (showTutorials) {
        tabs.push({ label: 'Tutorials', url: `${path}/tutorials` });
    }
    const showBlogs = availableTabs.find((tab: ArticlePrefix) => tab === 'blog');
    if (showBlogs) {
        tabs.push({ label: 'Blog', url: `${path}/blog` });
    }

    // { label: "Code examples", url: `${path}/examples` },
    // { label: "Cheat Sheets", url: `${path}/cheatsheets` },
    // { label: "Docs", url: `${path}/docs` },

    if (language && supportedCopilerLanguages.includes(language)) {
        tabs.push({
            label: 'Compiler',
            url: `/${language}/online-compiler`,
            target: '_blank',
        });
    }

    return (
        <Box className={styles['header-container']}>
            <Grid container margin="0 0 1rem 0">
                {seoData.imageUrl && (
                    <Grid item margin="0 1rem 0 0">
                        <Box className={styles['featured-image-wrapper']}>
                            <FeaturedImage
                                Image={Image}
                                alt={seoData.title}
                                src={seoData.imageUrl || '/logo192.png'}
                                width={seoData.imageWidth}
                                height={seoData.imageHeight}
                                lazyLoad={false}
                                radius
                            />
                        </Box>
                    </Grid>
                )}

                <Grid item display="flex" flexDirection="column">
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        height="100%"
                    >
                        <Box display="flex" alignItems="center" flex={1}>
                            <Typography variant="h1">{seoData.title}</Typography>
                        </Box>

                        <TabMenu tabs={tabs} pathname={pathname} />
                    </Box>
                </Grid>
            </Grid>

            <LectureDescription Image={Image} text={seoData.description} key="header-description" />
        </Box>
    );
}
