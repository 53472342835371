import { BreadcrumbObj } from './seo.utils';

function formatString(str: string): string {
    // Split the string on dashes
    let words = str.split('-');

    // Capitalize the first word and join the rest with spaces
    return words
        .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
        .join(' ');
}

const getDefaultTextGenerator = (path: string) => {
    return (
        {
            settings: 'User Settings',
        }[path] || formatString(path)
    );
};

/**
 * Replace placeholders in pathname with respective values from query.
 * If pathname is not provided, return asPath without query string.
 *
 * @param asPath - The actual path shown in the browser.
 * @param pathname - The route's pathname with placeholders.
 * @param query - Object containing query and dynamic route segment values.
 * @returns Updated pathname or asPath without query string.
 */
export function replaceWithObjectValues(pathname: string, query?: any): string {
    if (pathname && query) {
        return pathname.replace(/\[([^\]]+)\]/g, (match, key) => query.get(key) || match);
    }

    return pathname;
}

/**
 * Generate the parts of a path.
 *
 * @param asPath - The actual path shown in the browser.
 * @param pathname - The route's pathname with placeholders.
 * @param query - Object containing query and dynamic route segment values.
 * @returns Array of path segments.
 */
export const generatePathParts = (pathname: string, query?: any): string[] => {
    const pathWithoutQuery = replaceWithObjectValues(pathname, query);
    return pathWithoutQuery?.split('/').filter((v) => v.length > 0) || [];
};

export const generateBreadcrumbs = (
    defaultSeo: any,
    pathname: string,
    query?: any,
): BreadcrumbObj[] => {
    // Generate an array of path parts based on asPath, pathname, and query
    const asPathNestedRoutes = generatePathParts(pathname, query);

    // Map over each segment, producing a breadcrumb for each one
    const crumblist = asPathNestedRoutes?.map((subpath, index) => {
        // Convert the path segment to a more readable form using getDefaultTextGenerator function
        const name = getDefaultTextGenerator(subpath);

        // Filter out breadcrumbs with specific names
        const clickable =
            !['Subjects', 'Languages', 'Users', 'Lessons'].includes(name) &&
            index !== asPathNestedRoutes.length - 1;

        // Build the URL by concatenating all path segments up to the current one
        const url = `${defaultSeo.pageUrl}/${asPathNestedRoutes.slice(0, index + 1).join('/')}`;

        return {
            name,
            clickable,
            url,
        };
    });

    // Return the breadcrumb list, starting with a default "Home" breadcrumb
    return [
        {
            name: 'Home',
            clickable: true,
            url: `${defaultSeo.pageUrl}/`,
        },
        ...crumblist,
    ];
};
