import { FullWidthLayoutProps, NextBreadcrumbs } from '@repo/ui';
import { PageHighlightSection } from '@repo/ui';
import { DefaultTitleH1Renderer } from '@repo/ui';
import FullWidthLayoutContentClient from './FullWidthLayoutContentClient';

const FullWidthLayoutContent = ({
    defaultSeo,
    appCategories,
    seoData = {},
    Menu,
    children,
    loading = false,
    headerSection = undefined,
    HeaderSeparator,
    noHeader = false,
    noBreadcrumbs = false,
    footerLinks,
    noFooter = false,
    Image,
    pathname,
    searchParams,
}: FullWidthLayoutProps) => {
    return (
        <FullWidthLayoutContentClient
            companyName={defaultSeo.name}
            appCategories={appCategories}
            pathname={pathname}
            searchParams={searchParams}
            seoData={seoData}
            Image={Image}
            social={defaultSeo.social}
            footerLinks={footerLinks}
            pageUrl={defaultSeo?.pageUrl}
            Menu={Menu}
            loading={loading}
            HeaderSeparator={HeaderSeparator}
            noHeader={noHeader}
            noFooter={noFooter}
            headerChildren={
                <PageHighlightSection
                    childrenFlex={headerSection?.childrenFlex || 3}
                    Image={Image}
                    breadCrumbs={
                        noBreadcrumbs ? null : (
                            <NextBreadcrumbs
                                pathname={pathname}
                                searchParams={searchParams}
                                defaultSeo={defaultSeo}
                                title={seoData.title}
                            />
                        )
                    }
                    background={headerSection?.background || 'var(--gradient-background)'}
                    title={headerSection?.title || seoData.title}
                    TitleRenderer={({ children }) => {
                        const Component = headerSection?.titleRendered || DefaultTitleH1Renderer;
                        const props = headerSection?.titleAdditionalProps || {};

                        return (
                            <Component seoData={seoData} {...props}>
                                {children}
                            </Component>
                        );
                    }}
                    secondSide={headerSection?.right}
                    ChildrenRenderer={({ children }) => {
                        const Component = headerSection?.rendered || (({ children }) => children);

                        return <Component seoData={seoData}>{children}</Component>;
                    }}
                    innerButton={headerSection?.mainButton}
                >
                    {headerSection?.children || seoData.description}
                </PageHighlightSection>
            }
        >
            {children}
        </FullWidthLayoutContentClient>
    );
};

export default FullWidthLayoutContent;
