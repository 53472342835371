import Link from "../../atoms/Link"
import { Card } from "../../atoms/Card"
import Box from "../../atoms/Box"

const HorizontalList = ({
  items,
  getUrl = item => item.urlSlug,
  getTitle = item => item.title,
}) => {
  return (
    <Box style={{ display: "flex", overflow: "auto", paddingBottom: "1.5rem" }}>
      {items.map((item, index) => (
        <Link key={item.id || index} to={getUrl(item)}>
          <Card
            style={{
              display: "flex",
              alignItems: "center",
              padding: "1rem",
              marginRight: index !== items.length - 1 ? "1rem" : 0,
              width: 150,
              height: "100%",
            }}
          >
            {getTitle(item)}
          </Card>
        </Link>
      ))}
    </Box>
  )
}

export default HorizontalList
