'use client';

import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import classNames from 'classnames';
import Typography from '../Typography';
import styles from './Dropdown.module.scss';

export const Dropdown = ({
    title,
    index,
    items = [],
    initialOpen = false,
    onSelect,
    titleRenderer = (title: any, index: any, items: any, onClick: any, open: any) => (
        <div
            className={styles['dropdown-title']}
            onClick={onClick}
            role="button"
            aria-label={title}
        >
            <Typography
                variant="h3"
                fontSize="1.75rem"
                fontWeight="bold"
                className={styles['dropdown-title-content']}
            >
                {title}
            </Typography>

            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
    ),
    itemRenderer: ItemRenderer = ({ title }: any) => title,
    showMax,
    absolute,
}: any) => {
    const [open, setOpen] = useState(initialOpen);

    const containerClassname = classNames(styles['dropdown'], {
        [styles['dropdown-absolute']]: absolute,
    });

    const contentClassname = classNames(styles['dropdown-content'], {
        [styles['dropdown-content-hidden']]: !open,
    });

    return (
        <div className={containerClassname}>
            {titleRenderer(
                title,
                index,
                items,
                (e: any) => {
                    e.stopPropagation();

                    setOpen(!open);
                },
                open,
            )}

            <div className={contentClassname}>
                {items.map((item: any, contentIndex: any) => {
                    if (showMax && contentIndex > showMax - 1) {
                        return null;
                    }
                    return (
                        <div
                            key={item.id}
                            className={styles['dropdown-content-item']}
                            onClick={(e) => {
                                e.stopPropagation();

                                if (onSelect) {
                                    onSelect(item, contentIndex);
                                }
                            }}
                            role="button"
                            aria-label={item?.title}
                        >
                            <ItemRenderer {...item} contentIndex={contentIndex} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default Dropdown;
