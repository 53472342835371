import { Box, Container } from '@repo/ui';
import styles from './SERPPreview.module.scss';
import seoUtils from '../../../utils/seo-checker-utils';

function SERPPreview({ title = '', url = '', description = '', keyword = '' }) {
    const boldKeyword = (text: string, key: string) => {
        const regExp = seoUtils.matchSeoTextRegex(key);

        return text.replace(regExp, '<strong>$1</strong>');
    };

    const truncate = (text: string, limit: number) => {
        if (text.length > limit) {
            return text.substring(0, limit) + '...';
        }

        return text;
    };

    return (
        <Container className={styles['seo-preview']}>
            <p>Preview</p>

            <Box className={styles['url']}>
                <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    dangerouslySetInnerHTML={{
                        __html: boldKeyword(truncate(url || '', 72), keyword),
                    }}
                ></a>
            </Box>
            <Box>
                <a href={url} target="_blank" rel="noreferrer">
                    <span
                        className={`${styles['title']} ${styles.ellipsis}`}
                        dangerouslySetInnerHTML={{
                            __html: boldKeyword(truncate(title || '', 50), keyword),
                        }}
                    />
                </a>
            </Box>
            <Box>
                <span
                    className={`${styles['description']} ${styles.ellipsis}`}
                    dangerouslySetInnerHTML={{
                        __html: boldKeyword(truncate(description || '', 155), keyword),
                    }}
                />
            </Box>
        </Container>
    );
}

export default SERPPreview;
