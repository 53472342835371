import Price from "../Price"
import { Ribbon } from "../Ribbon"

import Box from "../../atoms/Box"
import Typography from "../../atoms/Typography"
import Divider from "../../atoms/Divider"
import Card  from "../../atoms/Card"

const PricingCard = ({
  planName,
  priceDescription,
  planDescription,
  pricing,
  recommended = false,
  monthly = false,
}) => {
  const topMenuColor = recommended ? "var(--secondary-color)" : "var(--surface-color)"
  return (
    <Box style={{ position: "relative", height: "100%" }}>
      <Card style={{ height: "100%" }}>
        {recommended && <Ribbon>Popular</Ribbon>}
        <Box style={{ background: topMenuColor, borderBottom: "var(--border)" }} padding="1rem">
          <Typography variant="h3" fontSize="2rem !important">
            {planName}
          </Typography>
        </Box>

        <Box style={{ padding: "2rem" }}>
          <Box display="flex" flexDirection="column" alignItems="center" style={{ paddingTop: 3 }}>
            <Price pricing={pricing} monthly={monthly} showPrice />
            {priceDescription}
          </Box>

          <Divider />
          <Box display="flex" flexDirection="column" alignItems="center" style={{ paddingTop: 3 }}>
            {planDescription}
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default PricingCard
