import Person3Icon from '@mui/icons-material/Person3';
// import SchoolIcon from "@mui/icons-material/School"
// import TerminalIcon from "@mui/icons-material/Terminal"
// import StarIcon from "@mui/icons-material/Star"
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Link } from '@repo/ui';
import { logoutUser } from '@/store/mutable-data/user/actions';

export const userMenuItems: {
    label: string;
    route?: any;
    icon: JSX.Element;
    component?: any;
    onClick?: any;
    divider: boolean;
}[] = [
    {
        label: 'Profile',
        route: (username: string) => `/users/${username}`,
        icon: <Person3Icon fontSize="medium" style={{ color: '#fff', margin: 0 }} />,
        component: Link,
        onClick: null,
        divider: false,
    },
    // {
    //   label: "My Learning",
    //   route: () => "/courses/my",
    //   icon: <SchoolIcon fontSize="medium" style={{ color: "#fff", margin: 0 }} />,
    //   component: Link,
    //   onClick: null,
    //   divider: false,
    // },
    // {
    //   label: "My Highlights",
    //   route: () => "/highlights/my",
    //   icon: <StarIcon fontSize="medium" style={{ color: "#fff", margin: 0 }} />,
    //   component: Link,
    //   onClick: null,
    //   divider: true,
    // },
    // {
    //   label: "New Highlight",
    //   route: () => "/highlights/new",
    //   icon: <TerminalIcon fontSize="medium" style={{ color: "#fff", margin: 0 }} />,
    //   component: Link,
    //   onClick: null,
    //   divider: true,
    // },
    {
        label: 'Support',
        icon: <ContactSupportIcon fontSize="medium" style={{ color: '#fff', margin: 0 }} />,
        onClick: () => {
            // @ts-expect-error
            if (window.$crisp) {
                // @ts-expect-error
                window.$crisp.push(['do', 'chat:show']);
                // @ts-expect-error
                window.$crisp.push(['do', 'chat:open']);
            }
        },
        divider: true,
    },
    {
        label: 'Settings',
        route: () => '/settings',
        icon: <Settings fontSize="medium" style={{ color: '#fff' }} />,
        component: Link,
        onClick: null,
        divider: false,
    },
    {
        label: 'Logout',
        icon: <Logout fontSize="medium" style={{ color: '#fff' }} />,
        onClick: async (userId: number, callback: any) => {
            await logoutUser(userId, callback);
        },
        divider: false,
    },
];

export const resourceMenuItems = [
    { name: 'Tutorials', route: '/tutorials' },
    { name: 'Blogs', route: '/blog' },
    //   { name: "Code Challenges", route: "/code-challenges" },
    {
        name: 'Videos',
        route: 'https://www.youtube.com/@codehighlights?sub_confirmation=1',
        target: '_blank',
    },
    // { name: "Code Challenges", route: "/code-challenges" },
    // { name: "Highlights", route: "/highlights" },
];
