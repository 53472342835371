import classNames from "classnames"
import styles from "./TopMenuDropdownLayout.module.scss"

const TopMenuDropdownLayout = ({ title, children }) => {
  const topMenuDropdownLayoutclass = classNames(styles["top-menu-dropdown-layout"])

  return (
    <div className={topMenuDropdownLayoutclass}>
      {title && <div className={styles["title"]}>{title}</div>}

      <div className={styles["content"]}>{children}</div>
    </div>
  )
}

export default TopMenuDropdownLayout
