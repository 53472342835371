"use client"

import classNames from "classnames"
import styles from "./TopMenuDropdown.module.scss"
import { useState } from "react"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from "../../atoms/Button"
import DropdownMenu from "../DropdownMenu"

const TopMenuDropdown = ({ placeholder, dropdownMenuContent, onHover }) => {
  const topMenuDropdownclass = classNames(styles["top-menu-container"])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      className={topMenuDropdownclass}
      onMouseEnter={onHover ? handleClick : undefined}
      onMouseLeave={onHover ? handleClose : undefined}
    >
      <Button
        // color="accent"
        active={open}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="text"
        endIcon={<KeyboardArrowDownIcon style={{ opacity: 0.7 }} />}
        padding='1rem .75rem'
      >
        {placeholder}
      </Button>

      <DropdownMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {dropdownMenuContent}
      </DropdownMenu>
    </div>
  )
}

export default TopMenuDropdown
