import { Container, Grid, Typography, Card, Box, FullWidthLayoutProps } from '@repo/ui';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Suspense } from 'react';
import RegularLayout from '../RegularLayout';
import AuthRedirect from './AuthRedirect';
import styles from './AuthLayout.module.scss';

export type AuthType = 'login' | 'register';

export type AuthLayoutProps = {
    type: AuthType;
} & Partial<FullWidthLayoutProps>;

const AuthLayout = ({
    Menu,
    defaultSeo,
    appCategories,
    footerLinks,
    seoData = {},
    children,
    type,
    Image,
}: AuthLayoutProps) => {
    const title = type === 'login' ? 'Welcome back!' : 'Welcome!';

    return (
        <div className={styles['auth-layout-wrapper']}>
            <RegularLayout
                Image={Image}
                Menu={Menu}
                defaultSeo={defaultSeo}
                appCategories={appCategories}
                footerLinks={footerLinks}
                seoData={seoData}
                noHeader
            >
                <Container
                    maxWidth={false}
                    style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        style={{
                            padding: '1.5rem',
                            height: '100%',
                            width: '100%',
                            maxWidth: 400,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            backgroundColor="var(--secondary-color)"
                            style={{ borderRadius: '50%' }}
                            display="flex"
                            alignItems="center"
                            padding="0.5rem"
                            margin="0 0 0.5rem 0"
                        >
                            <LockOutlinedIcon />
                        </Box>

                        <Typography variant="h4" color="white">
                            {title}
                        </Typography>

                        {children}
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Suspense>
                                    <AuthRedirect type={type} title={title} />
                                </Suspense>
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </RegularLayout>
        </div>
    );
};

export default AuthLayout;
