import Link from '../Link';
import FeaturedImage from '../FeaturedImage';
import classNames from 'classnames';
import { ArticlePrefix, getFeaturedImage } from '@repo/utils/articleUtils';
import CardWrapper from '../CardWrapper';
import Grid from '../../organisms/Grid';
import Typography from '../Typography';
import Box from '../Box';
import AllArticleCategoryTags from '../../organisms/AllArticleCategoryTags';
import { getArticleUrl } from '@repo/utils/seoUtils';

export const ArticleCard = ({
    article,
    gridSize = { xs: 12, sm: 6, md: 6, lg: 4 },
    horizontal = false,
    imageFlex = 2,
    contentFlex = 3,
    prefix = 'tutorials',
    featured = false,
    hideDescription = false,
    belowTheFold = false,
    Image = 'img',
}: {
    article: any;
    gridSize?: { xs?: number; sm?: number; md?: number; lg?: number };
    horizontal?: boolean;
    imageFlex?: number;
    contentFlex?: number;
    description?: boolean;
    prefix: ArticlePrefix;
    featured?: boolean;
    hideDescription?: boolean;
    belowTheFold?: boolean;
    Image: React.ElementType;
}) => {
    if (!Image || Image === 'img') {
        throw new Error('ArticleCard: Image props is required');
    }

    const imageClass = classNames({
        'article-horizontal-image': horizontal,
    });

    const url = getArticleUrl(article);
    const featuredImage = getFeaturedImage(article);
    return (
        <Grid item {...gridSize}>
            <Link to={url} style={{ position: 'relative', display: 'flex', height: '100%' }}>
                <CardWrapper
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: horizontal ? 'row' : 'column',
                    }}
                >
                    {((article.seoMetadata?.imageUrl && prefix !== 'tutorials') ||
                        (featured && featuredImage)) && (
                        <Box flex={imageFlex} className={imageClass}>
                            <FeaturedImage
                                Image={Image}
                                width={featured ? 560 : undefined}
                                height={featured ? 420 : undefined}
                                alt={`${article.title} Article`}
                                lazyLoad={belowTheFold}
                                src={featuredImage}
                            />
                        </Box>
                    )}

                    <Box
                        style={{
                            flex: contentFlex,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        padding="1rem 1rem 1.5rem 1rem"
                    >
                        <AllArticleCategoryTags article={article} prefix={prefix} noLink />

                        <Typography
                            variant={featured ? 'h2' : 'h3'}
                            fontSize={featured ? '2rem !important' : '1.5rem !important'}
                            margin="1rem 0 0 0"
                        >
                            {article.title}
                        </Typography>

                        {!hideDescription && article.seoMetadata?.description && (
                            <Typography margin="1rem 0 0 0">
                                {article.seoMetadata?.description}
                            </Typography>
                        )}
                    </Box>
                </CardWrapper>
            </Link>
        </Grid>
    );
};

export default ArticleCard;
