import type { ReactNode } from 'react';
import { Typography } from '@repo/ui';

export const DefaultTitleH1Renderer = ({ children }: { children: ReactNode }) => (
    <Typography variant="h1" margin="0 0 0.5rem 0">
        {children}
    </Typography>
);

export default DefaultTitleH1Renderer;
