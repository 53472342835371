import Card from '../Card';
import styles from './CardWrapper.module.scss';

export default function CardWrapper({ children, ...props }: any) {
    return (
        <Card
            {...props}
            className={
                props.className
                    ? `${props.className} ${styles['card-wrapper']}`
                    : `${styles['card-wrapper']}`
            }
        >
            {children}
        </Card>
    );
}
