"use client"

import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import LinearProgress from "../../atoms/LinearProgress"
// import { Router } from "next/navigation"
import GuidingActionButtons from "../UserGuidingActionButtons"
import { getLocalStorageItem, setLocalStorageItem } from "@repo/utils/localStorageUtils"
// import LectureDescription from "../../../atoms/LectureDescription"
import Box from "../../atoms/Box"

const UserGuiding = ({ userGuidings = [] }) => {
  const [showGuide, setShowGuide] = useState(false)
  const [activeGuideStepIndex, setActiveGuideStepIndex] = useState(0)

  const [activeGuide, setActiveGuide] = useState({ steps: [] })
  const steps = activeGuide.steps
  const activeGuideStep = useMemo(
    () => activeGuide.steps[activeGuideStepIndex] || {},
    [activeGuide.steps, activeGuideStepIndex],
  )

  const [rect, setRect] = useState({})
  const [tooltipPosition, setTooltipPosition] = useState("middle")

  const tooltipRef = useRef()
  const tooltipRect = tooltipRef.current?.getBoundingClientRect() || { height: 0, width: 0 }

  const finishGuide = () => {
    setShowGuide(false)

    const finishedGuides = JSON.parse(getLocalStorageItem("finishedGuides", "[]"))
    finishedGuides.push(activeGuide.id)

    setLocalStorageItem("finishedGuides", JSON.stringify(finishedGuides))
  }

  const getAvailableSpaces = useCallback(rect => {
    const newTooltipRect = tooltipRef.current?.getBoundingClientRect() || { height: 0, width: 0 }

    const availableSpace = {}
    if (rect.top - newTooltipRect.height > 0) {
      availableSpace.top = rect.top
    }

    if (rect.top + newTooltipRect.height < window.innerHeight) {
      availableSpace.right = window.innerWidth - rect.right
    }

    if (rect.top + newTooltipRect.height < window.innerHeight) {
      availableSpace.bottom = window.innerHeight - rect.bottom
    }

    if (rect.top + newTooltipRect.height < window.innerHeight) {
      availableSpace.left = rect.left
    }

    return availableSpace
  }, [])

  const determineTooltipPosition = useCallback(
    rect => {
      const availableSpace = getAvailableSpaces(rect)

      const maxSpaceDirection = Object.keys(availableSpace).reduce((prev, curr) =>
        availableSpace[curr] > availableSpace[prev] ? curr : prev,
      )

      return maxSpaceDirection
    },
    [getAvailableSpaces],
  )

  const isInitialStep = activeGuideStep.type === "init"
  const isLastStep = activeGuideStep.type === "end"
  const updateRect = useCallback(() => {
    if (isInitialStep || isLastStep) {
      setRect({ top: 0, right: 0, bottom: 0, left: 0, width: "100vw", height: "100vh" })

      setTooltipPosition("middle")
    } else if (activeGuideStep.selector) {
      const element = document.querySelector(activeGuideStep.selector)
      if (element) {
        const newRect = element.getBoundingClientRect()

        setRect(newRect)

        setTooltipPosition(determineTooltipPosition(newRect))
      }
    }
  }, [activeGuideStep.selector, determineTooltipPosition, isInitialStep, isLastStep])

  useEffect(() => {
    if (showGuide) {
      const elem = document.querySelector(activeGuideStep.selector)
      if (elem) {
        elem.scrollIntoView()
      }

      updateRect()

      // Add event listeners for scroll and resize
      window.addEventListener("scroll", updateRect)
      window.addEventListener("resize", updateRect)

      // Cleanup event listeners
      return () => {
        window.removeEventListener("scroll", updateRect)
        window.removeEventListener("resize", updateRect)
      }
    }
  }, [activeGuideStep, showGuide, updateRect])

  useEffect(() => {
    // Function to handle route change
    const handleRouteChange = url => {
      const finishedGuides = JSON.parse(getLocalStorageItem("finishedGuides", "[]"))
      const newActiveGuide = userGuidings.find(guide => {
        const element = document.querySelector(guide.selector)

        return !finishedGuides.includes(guide.id) && element && url.includes(guide.url)
      })

      if (newActiveGuide) {
        setActiveGuide(newActiveGuide)
        setShowGuide(true)
      } else {
        setShowGuide(false)
      }
    }

    handleRouteChange(window.location.href)

    // Add event listener for route change
    // Router.events.on("routeChangeComplete", handleRouteChange)

    // // Cleanup the listener when the component is unmounted
    // return () => {
    //   Router.events.off("routeChangeComplete", handleRouteChange)
    // }
  }, [])

  const tooltipPositions = useMemo(() => {
    return {
      top: {
        top: `${rect.top - tooltipRect?.height - 10}px`,
        left: `${rect.left + rect.width / 2}px`,
        transform: "translateX(-50%)",
      },
      bottom: {
        top: `${rect.bottom + 10}px`,
        left: `${rect.left + rect.width / 2}px`,
        transform: "translateX(-50%)",
      },
      left: {
        top: `${rect.top + rect.height / 2}px`,
        left: `${rect.left - tooltipRect?.width - 10}px`,
        transform: "translateY(-50%)",
      },
      right: {
        top: `${rect.top + rect.height / 2}px`,
        left: `${rect.right + 10}px`,
        transform: "translateY(-50%)",
      },
      middle: {
        top: `calc(50vh - ${tooltipRect?.height / 2}px)`,
        left: `calc(50vw - ${tooltipRect?.width / 2}px)`,
        transform: "translateY(-50%)",
      },
    }
  }, [
    rect.bottom,
    rect.height,
    rect.left,
    rect.right,
    rect.top,
    rect.width,
    tooltipRect?.height,
    tooltipRect?.width,
  ])

  const frameThickness = 2
  const styles = {
    backdrop: {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 99999,
    },
    top: {
      top: 0,
      left: `${rect.left}px`,
      right: `calc(100% - ${rect.right}px)`,
      height: `${rect.top - 2}px`,
    },
    right: {
      top: 0,
      left: `${rect.right}px`,
      bottom: 0,
      right: 0,
    },
    bottom: {
      bottom: 0,
      left: `${rect.left}px`,
      right: `calc(100% - ${rect.right}px)`,
      height: `calc(100% - ${rect.bottom + 4}px)`,
    },
    left: {
      top: 0,
      bottom: 0,
      left: 0,
      width: `${rect.left}px`,
    },
    tooltip: {
      position: "fixed",
      transform: "translateX(-50%)",
      backgroundColor: "var(--surface-color)",
      borderRadius: "5px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
      zIndex: 100001,
      minWidth: 360,
      maxWidth: 400,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      ...tooltipPositions[tooltipPosition],
    },
    closeButton: {
      color: "#fff",
      position: "absolute",
      top: 15,
      right: 10,
      alignSelf: "flex-end",
      background: "none",
      border: "none",
      fontSize: "1.5rem",
      cursor: "pointer",
    },
    frame: {
      position: "fixed",
      top: `${rect.top - frameThickness}px`,
      left: `${rect.left}px`,
      width: `${rect.width}px`,
      height: `${rect.height + frameThickness + frameThickness}px`,
      boxSizing: "border-box",
      zIndex: 100001,
      border: `${frameThickness}px solid var(--secondary-color)`,
      borderRadius: 5,
      transition: "all 0.5s ease-out 0s",
    },
  }

  const hasPrevious = false || activeGuideStepIndex > 0
  const hasNext = activeGuideStepIndex < steps.length - 1

  if (!showGuide) {
    return null
  }

  return (
    <Box className="user-guiding-wrapper">
      <Box>
        <div style={{ ...styles.backdrop, ...styles.top }} />
        <div style={{ ...styles.backdrop, ...styles.right }} />
        <div style={{ ...styles.backdrop, ...styles.bottom }} />
        <div style={{ ...styles.backdrop, ...styles.left }} />
      </Box>

      {!isInitialStep && !isLastStep && <div style={styles.frame} />}

      <Box style={styles.tooltip} ref={tooltipRef}>
        <button style={styles.closeButton} onClick={finishGuide}>
          &times;
        </button>

        <Box width="100%">
          {activeGuideStep.title && (
            <Box
              style={{
                borderBottom: "2px solid var(--border-color)",
                width: "100%",
                margin: "0 1rem 0 0",
              }}
              padding="0 1rem"
            >
              <h3>{activeGuideStep.title}</h3>
            </Box>
          )}

          <Box padding="1rem 40px 1rem 1rem">
            {/* <LectureDescription Image={Image} text={activeGuideStep.body} /> */}

            <GuidingActionButtons
              activeGuideStep={activeGuideStep}
              goBack={() => {
                setActiveGuideStepIndex(activeGuideStepIndex - 1)
              }}
              goNext={() => {
                setActiveGuideStepIndex(activeGuideStepIndex + 1)
              }}
              onClose={finishGuide}
              hasPrevious={hasPrevious}
              hasNext={hasNext}
            />
          </Box>
        </Box>

        <Box style={{ background: "var(--background-color)", width: "100%", marginTop: "0.5rem" }}>
          <LinearProgress
            value={((activeGuideStepIndex + 1) / steps.length) * 100}
          />
          <Box padding="0.5rem" color="var(--primary-color)" textAlign="center">
            {activeGuideStepIndex + 1} / {steps.length}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UserGuiding
