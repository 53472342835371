'use client';

import styles from './StyledTableRow.module.scss';
import cn from 'classnames';

type StyledTableRowProps = {
    children: React.ReactNode;
    // ... define other props as needed
};

const StyledTableRow: React.FC<StyledTableRowProps> = ({ children, ...props }) => {
    return (
        <tr className={cn(styles.row, styles.tableRow)} {...props}>
            {children}
        </tr>
    );
};

export default StyledTableRow;
