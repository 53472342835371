import PlatformCategoriesSidebar from "../PlatformCategoriesSidebar"

import Box from "../../atoms/Box"
import Typography from "../../atoms/Typography"
import Grid from "../../organisms/Grid"

const PlatformCategories = ({
  languages,
  subjects,
  language,
  subject,
  title = undefined,
  prefix,
  children,
}) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <PlatformCategoriesSidebar
            languages={languages}
            subjects={subjects}
            language={language}
            subject={subject}
            prefix={prefix}
            sticky
          />
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={10}>
          {title ? (
            <Box margin="0 0 1.5rem 0">{<Typography variant="h4">{title}</Typography>}</Box>
          ) : null}

          {children}
        </Grid>
      </Grid>
    </div>
  )
}

export default PlatformCategories
