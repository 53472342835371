'use client';

import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { UserStoreContextState, UserStoreProps } from './userStore';
import { defaultUserStoreState } from './userStore';
import { getInitialLoggedUser } from './actions';

const StoreContext = createContext(defaultUserStoreState);

export const useUserStore = <T = UserStoreContextState,>(
    selector: (state: UserStoreContextState) => T = (state) => state as unknown as T,
): T => {
    const state = useContext(StoreContext);

    return selector(state);
};

export default function UserStoreContext({
    children,
    user = defaultUserStoreState.user,
}: {
    user?: UserStoreProps;
    userVisit?: UserStoreProps;
    children: ReactNode;
}) {
    const [userState, setUserState] = useState(user);

    const state = {
        user: userState,
        setUserState,
    };

    useEffect(() => {
        const callback = async () => {
            const user = await getInitialLoggedUser();

            setUserState(user);
        }

        callback();
    }, []);

    return <StoreContext.Provider value={state}>{children}</StoreContext.Provider>;
}
