// import LinkRouter from "next/link"
import { CSSProperties, HTMLAttributeAnchorTarget } from 'react';
import { ReactNode } from 'react';

interface ILink {
    children: ReactNode;
    to: string;
    style?: CSSProperties;
    className?: string;
    target?: HTMLAttributeAnchorTarget;
    rel?: string;
    ariaLabel?: string;
}

export const Link = ({
    children,
    to,
    style = {},
    className,
    target,
    ariaLabel,
    rel,
    ...rest
}: ILink): JSX.Element => {
    return (
        <a
            className={className}
            href={to}
            style={{ textDecoration: 'none', ...style }}
            target={target}
            aria-label={ariaLabel}
            rel={rel}
            {...rest}
        >
            {children}
        </a>
    );
};

export default Link;
