'use client';

import { ReactNode, useState } from 'react';
import classNames from 'classnames';
import styles from './Tooltip.module.scss';

interface TooltipProps {
    title?: string;
    placement?: 'top' | 'right' | 'bottom' | 'left';
    children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ title, placement = 'top', children }) => {
    const [visible, setVisible] = useState(false);

    // Use classnames library to dynamically assign classes based on placement
    const tooltipClasses = classNames(styles.tooltip, {
        [styles.top]: placement === 'top',
        [styles.right]: placement === 'right',
        [styles.bottom]: placement === 'bottom',
        [styles.left]: placement === 'left',
        [styles.visible]: visible, // Assuming you have a .visible class for when the tooltip is shown
    });

    if (!title) {
        return children;
    }

    return (
        <div
            className={styles.tooltipWrapper}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            {visible && <div className={tooltipClasses}>{title}</div>}

            {children}
        </div>
    );
};

export default Tooltip;
