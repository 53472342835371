'use client';

import Button from '../../atoms/Button';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { PercentCrop, PixelCrop } from 'react-image-crop';
import { FileUploader } from 'react-drag-drop-files';
import { useState, useRef } from 'react';
import styles from './ImageUploader.module.scss';
import { canvasPreview, centerAspectCrop } from '../../../utils/image.utils';

interface IImageUploader {
    aspect?: number;
    // eslint-disable-next-line no-unused-vars
    uploadImage: (_: Blob | null) => void;
}
const fileTypes = ['JPEG', 'PNG', 'GIF'];

const ImageUploader: React.FC<IImageUploader> = ({ aspect, uploadImage }) => {
    const [imgSrc, setImgSrc] = useState('');
    const imgRef = useRef(null);
    const [crop, setCrop] = useState<PercentCrop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [file, setFile] = useState(null);

    function onImageLoad(e: any) {
        if (aspect) {
            const { width, height } = e.currentTarget;

            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    const handleChange = (file: any) => {
        setFile(file);

        setCrop(undefined); // Makes crop preview update between images.
        const reader = new FileReader();
        reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
        reader.readAsDataURL(file);
    };

    const onReactCropChange = (_: PixelCrop, percentCrop: PercentCrop) => setCrop(percentCrop);
    const onReactCropComplete = (c: PixelCrop) => setCompletedCrop(c);

    const uploadImageHandler = async () => {
        // Create a new HTML canvas element
        const canvas = document.createElement('canvas');

        if (completedCrop) {
            canvas.width = completedCrop.width;
            canvas.height = completedCrop.height;
        }

        canvasPreview(imgRef.current, canvas, completedCrop);

        // Convert the canvas image to a blob and create a new FormData object
        canvas.toBlob(async (blob) => {
            uploadImage(blob);
        }, 'image/jpeg');
    };

    return (
        <>
            {file ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ReactCrop
                        crop={crop}
                        onChange={onReactCropChange}
                        onComplete={onReactCropComplete}
                        aspect={aspect}
                    >
                        <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
                    </ReactCrop>
                    <Button style={{ marginTop: 20 }} onClick={uploadImageHandler}>
                        Save
                    </Button>
                </div>
            ) : (
                <div className={styles['upload-dropzone']}>
                    <FileUploader
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                    />
                </div>
            )}
        </>
    );
};

export default ImageUploader;
