import classNames from 'classnames';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import styles from './FeaturedImage.module.scss';

export const FeaturedImage = ({
    src,
    radius = false,
    lazyLoad = true,
    alt = 'Course',
    quality = 75,
    width = 430,
    height = 322.5,
    onClick = undefined,
    className = '',
    Image = 'img',
    noFullWidth = false,
}: {
    src: string;
    radius?: boolean;
    lazyLoad?: boolean;
    alt?: string;
    quality?: number;
    width?: number;
    height?: number;
    onClick?: any;
    className?: string;
    Image: React.ElementType;
    noFullWidth?: boolean;
}) => {
    if (!Image || Image === 'img') {
        throw new Error('FeaturedImage: Image props is required');
    }

    const containerClass = classNames(
        `${styles['preview-banner-wrapper']} preview-banner-wrapper`,
        {
            [styles['preview-banner-wrapper-radius']]: !!radius,
        },
    );

    const imageClass = classNames(
        `${styles['course-preview-side-card-main-image']} course-preview-side-card-main-image`,
        {
            [className]: true,
            [styles.noFullWidth]: noFullWidth,
        },
    );

    return (
        <div onClick={onClick} role="button" className={containerClass} aria-label={alt}>
            <Image
                loading={lazyLoad ? 'lazy' : 'eager'}
                priority={!lazyLoad}
                className={imageClass}
                src={src || 'https://static.code-hl.com/resources/highlights-default.png'}
                alt={alt}
                title={alt}
                quality={quality}
                width={width}
                height={height}
            />

            {!!onClick && (
                <div className={styles['course-featured-image-play-button']}>
                    <div className={styles['course-featured-image-play-button-content']}>
                        <PlayCircleIcon
                            className={styles['course-featured-image-play-button-icon']}
                        />
                        <p>Preview this course</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeaturedImage;
