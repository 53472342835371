'use client';
import styles from './NumberButtons.module.scss';
import { memo } from 'react';
import NumberButton from '../../atoms/NumberButton';
import Box from '../../atoms/Box';

interface INumberButtons {
    numberOfButtons: number;
    // eslint-disable-next-line no-unused-vars
    onClick: (buttonClicked: number) => void;
    selectedButton?: number;
}

const NumberButtons: React.FC<INumberButtons> = ({ numberOfButtons, onClick, selectedButton }) => {
    const buttons = Array.from({ length: numberOfButtons }, (_, i: number) => ({
        name: i + 1,
        onClick: () => {
            onClick(i + 1);
        },
    }));

    const buttonsMapper = buttons.map((b) => (
        <NumberButton key={b.name} onClick={b.onClick} active={selectedButton === b.name}>
            {b.name}
        </NumberButton>
    ));

    return <Box className={styles['container']}>{buttonsMapper}</Box>;
};

export default memo(NumberButtons);
