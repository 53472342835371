import type {
    Course as SchemaCourse,
    FAQPage,
    Graph,
    Thing,
    BreadcrumbList,
    WebSite,
    WebPage,
    Person,
    ImageObject,
    ItemList,
    VideoObject,
    Article,
} from 'schema-dts';
import { countWords } from '@repo/utils/stringUtils';
import type { SeoObjectOutput } from '@repo/utils/seoUtils';
import { getArticlePrefix } from './article.utils';

const getWebsiteMainData = (defaultSeo: any) => {
    const organizationLogo = {
        '@type': 'ImageObject',
        '@id': `${defaultSeo.pageUrl}/#/schema/logo/image/`,
        url: defaultSeo.logoUrl,
        contentUrl: defaultSeo.logoUrl,
        width: defaultSeo.logoWidth,
        height: defaultSeo.logoHeight,
        caption: defaultSeo.name,
    };

    const organizationScheme = {
        '@type': ['EducationalOrganization', 'Organization'],
        '@id': `${defaultSeo.pageUrl}/#organization`,
        name: defaultSeo.name,
        url: defaultSeo.pageUrl,
        sameAs: defaultSeo.social,
        logo: organizationLogo,
        image: { '@id': organizationLogo['@id'] },
        brand: {
            '@type': 'Brand',
            name: defaultSeo.name,
        },
    };

    const websiteScheme: WebSite = {
        '@type': 'WebSite',
        '@id': `${organizationScheme.url}/#website`,
        url: organizationScheme.url,
        name: organizationScheme.name,
        alternateName: defaultSeo.alternateName,
        publisher: { '@id': organizationScheme['@id'] },
        inLanguage: 'en-US',
        // potentialAction: {
        //   "@type": "SearchAction",
        //   target: "https://digital-marketing.rs/?s={search_term_string}",
        //   "query-input": "required name=search_term_string",
        // },
    };

    return { organizationScheme, websiteScheme };
};

const generateFAQJsonLD = (pageUrl = '', faqs: any): FAQPage => {
    return {
        '@type': 'FAQPage',
        '@id': `${pageUrl}/#faq`,
        mainEntity: faqs.map((faq: any) => ({
            '@type': 'Question',
            name: faq.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: faq.answer,
            },
        })),
    };
};

// Define a custom Syllabus type
interface Syllabus {
    '@type': 'Syllabus';
    description: string;
    name: string;
}

// Define a custom Course type by extending the Schema.org Course type
interface Course extends SchemaCourse {
    availableLanguage: string[]; // Include the availableLanguage property
    syllabusSections: Syllabus[]; // Include the syllabusSections property
    totalHistoricalEnrollment: number;
}

const generateVideoJsonLD = (coursePreviewVideo: any): VideoObject | undefined => {
    if (!coursePreviewVideo?.originVideo) {
        return undefined;
    }

    return {
        '@type': 'VideoObject',
        '@id': `${coursePreviewVideo.originVideo}/#video`,
        name: coursePreviewVideo.title || 'Welcome',
        description: coursePreviewVideo.description,
        uploadDate: coursePreviewVideo.createdAt,
        contentUrl: coursePreviewVideo.originVideo || '',
        thumbnailUrl: coursePreviewVideo.previewImage,
    };
};

export const generateCourseJsonLD = (
    defaultSeo: any,
    webpageId = '',
    courseData: any,
    faqId = '',
) => {
    const timeToComplete = courseData.metadata?.timeToComplete?.replace('hours', '').trim();
    const courseWorkload = `PT${timeToComplete || 10}H`;

    let aggregateRating: any;
    if (courseData.statistics?.reviewsCount) {
        aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: courseData.statistics?.reviewsRatingAvg,
            bestRating: 5,
            ratingCount: courseData.statistics?.reviewsCount,
            reviewCount: courseData.statistics?.reviewsCount,
        };
    }

    const about = []; // TODO: courseData.categoryLanguageId,    courseData.categorySubjectId
    if (faqId) {
        about.push({ '@id': faqId });
    }

    const { organizationScheme } = getWebsiteMainData(defaultSeo);

    const course: Course = {
        '@id': `${organizationScheme.url}/courses/${courseData.urlSlug}/#course`,
        '@type': 'Course',
        name: courseData.title,
        isPartOf: {
            '@id': webpageId,
        },
        url: `${organizationScheme.url}/courses/${courseData.urlSlug}`,
        description: courseData.description || `${courseData.title}`,
        publisher: { '@id': organizationScheme['@id'] },
        provider: { '@id': organizationScheme['@id'] },
        image: courseData.coursePreviewVideo?.previewImage,
        aggregateRating,
        offers: [{ '@type': 'Offer', category: 'Subscription' }],
        educationalLevel: courseData.metadata?.skillLevel,
        totalHistoricalEnrollment: courseData.statistics?.studentsCount,
        about,
        teaches: courseData.courseLearningHighlight?.items?.map(
            (highlight: any) => highlight.title,
        ),
        inLanguage: 'en',
        availableLanguage: ['en'],
        review: courseData.courseReviews
            ?.filter((_: any, index: number) => index < 3)
            .map((review: any) => ({
                '@type': 'Review',
                '@id': `${organizationScheme.url}/courses/${courseData.urlSlug}?review=${review.id}`,
                author: {
                    '@type': 'Person',
                    name: review.user.full_name,
                },
                datePublished: review.createdAt,
                reviewBody: review.description,
                reviewRating: {
                    '@type': 'Rating',
                    ratingValue: review.rating,
                },
            })),
        syllabusSections: courseData.sectionGroups?.map((section: any) => ({
            '@type': 'Syllabus',
            name: section.title,
            description: section.description,
        })),
        coursePrerequisites: courseData.courseRequirements?.map(
            ({ requirement }: any) => requirement,
        ),
        educationalCredentialAwarded: {
            '@type': 'EducationalOccupationalCredential',
            name: `${organizationScheme.name} Certificate`,
            credentialCategory: 'Certificate',
            offers: [{ '@type': 'Offer', category: 'Subscription' }],
        },
        audience: {
            '@type': 'Audience',
            audienceType: courseData.courseAudienceTargetGroups?.map(
                (audience: any) => audience.description,
            ),
        },
        video: generateVideoJsonLD({
            ...courseData.coursePreviewVideo,
            title: courseData.title,
            description: courseData.description,
        }),
        hasCourseInstance: {
            '@type': 'CourseInstance',
            courseMode: 'Online',
            courseWorkload,
        },
    };

    return course;
};

const generateWebPageScheme = (
    defaultSeo: any,
    seoData: SeoObjectOutput,
    itemList: any,
    faqId = '',
): [BreadcrumbList, ImageObject, WebPage] => {
    const { websiteScheme } = getWebsiteMainData(defaultSeo);

    const parsedBreadcrumbs: Thing[] =
        seoData.breadcrumbList
            ?.filter((item) => item.clickable)
            .map((breadcrumb, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                item: { '@id': breadcrumb.url, name: breadcrumb.name },
            })) || [];

    const breadcrumbList: BreadcrumbList = {
        '@type': 'BreadcrumbList',
        '@id': `${seoData.pageUrl}#breadcrumb`,
        itemListElement: parsedBreadcrumbs,
    };

    const imageScheme = {
        '@type': 'ImageObject',
        '@id': seoData.imageUrl,
        url: seoData.imageUrl,
        contentUrl: seoData.imageUrl,
        width: seoData.imageWidth,
        height: seoData.imageHeight,
        caption: seoData.title,
    };

    const about = [];
    if (faqId) {
        about.push({ '@id': faqId });
    }
    const webpageScheme = {
        '@type': itemList ? ['WebPage', 'CollectionPage'] : 'WebPage',
        '@id': `${seoData.pageUrl}#webpage`,
        url: seoData.pageUrl,
        name: seoData.title,
        isPartOf: {
            '@id': websiteScheme['@id'],
        },
        primaryImageOfPage: {
            '@id': imageScheme['@id'],
        },
        datePublished: seoData.createdAt,
        dateModified: seoData.updatedAt,
        description: seoData.description,
        inLanguage: 'en-US',
        breadcrumb: {
            '@id': breadcrumbList['@id'],
        },
        about,
    };

    return [breadcrumbList, imageScheme as ImageObject, webpageScheme as WebPage];
};

const generatePersonScheme = (defaultSeo: any, user: any): Person => {
    const { organizationScheme } = getWebsiteMainData(defaultSeo);

    return {
        '@type': 'Person',
        '@id': `${organizationScheme.url}/users/${user.username}`,
        name: user.fullName,
        description: user.bio,
        email: user.email,
        image: {
            '@type': 'ImageObject',
            url: user.profilePicture,
            contentUrl: user.profilePicture,
            caption: user.fullName,
        },
    };
};

export const generateArticleScheme = (
    defaultSeo: any,
    webpageId = '',
    imageId = '',
    article: any,
    carousel = false,
    faqId = '',
): Article => {
    const { organizationScheme } = getWebsiteMainData(defaultSeo);

    const articlePrefix = getArticlePrefix(article.type);
    const articleUrl = `${organizationScheme.url}/${articlePrefix}/${article.urlSlug}`;
    let mainEntityOfPage;
    let image;
    if (!carousel) {
        mainEntityOfPage = {
            '@id': articleUrl,
        };

        if (imageId) {
            image = {
                '@id': imageId,
            };
        }
    }

    const about = [];
    if (faqId) {
        about.push({ '@id': faqId });
    }

    return {
        '@type': 'Article',
        '@id': `${articleUrl}/#article`,
        isPartOf: {
            '@id': webpageId,
        },
        author: {
            '@id': organizationScheme['@id'],
        },
        headline: article.title,
        datePublished: article.createdAt,
        dateModified: article.updatedAt,
        mainEntityOfPage,
        wordCount: countWords(article.content),
        commentCount: 0,
        publisher: {
            '@id': organizationScheme['@id'],
        },
        image,
        thumbnailUrl: defaultSeo.imageUrl,
        about,
        // TODO: Language, categories
        // keywords: [
        //   "Email Outreach",
        //   "Email Templates",
        //   "Outbound Email Marketing",
        //   "Outbound Prospecting",
        // ],
        // articleSection: ["Email outreach"],
        inLanguage: 'en-US',
        // potentialAction
        // "citation": [] // article sources (statistics references)
    };
};

type ListType = 'COURSE' | 'ARTICLE';

// eslint-disable-next-line no-unused-vars
type ItemListGenerator = (_webId?: string) => ItemList;

export const generateItemList = (
    defaultSeo: any,
    type: ListType,
    name: string,
    items: any,
): ItemListGenerator => {
    return (webId = '') => ({
        '@type': 'ItemList',
        name,
        itemListElement: items.map((item: any, index: number) => ({
            '@type': 'ListItem',
            position: `${index + 1}`,
            item:
                type === 'COURSE'
                    ? generateCourseJsonLD(defaultSeo, webId, item)
                    : generateArticleScheme(defaultSeo, webId, undefined, item, true),
        })),
    });
};

export interface JsonLdProps {
    defaultSeo?: any;
    seoData?: SeoObjectOutput;
    courseData?: any;
    faqData?: any;
    user?: any;
    article?: any;
    itemList?: ItemListGenerator;
}

export const generateJsonLD = ({
    defaultSeo,
    seoData,
    courseData,
    faqData,
    user,
    article,
    itemList,
}: JsonLdProps) => {
    const { organizationScheme, websiteScheme } = getWebsiteMainData(defaultSeo);

    // start using potentialAction in Things
    const graphs: Thing[] = [organizationScheme as any, websiteScheme];

    if (seoData) {
        let faq;
        if (faqData?.length) {
            faq = generateFAQJsonLD(seoData.pageUrl, faqData);
            graphs.push(faq);
        }

        const [breadcrumbList, imageObject, webPage] = generateWebPageScheme(
            defaultSeo,
            seoData,
            itemList,
            faq?.['@id'],
        );
        graphs.push(breadcrumbList, imageObject, webPage);

        if (article) {
            const articleScheme = generateArticleScheme(
                defaultSeo,
                webPage['@id'],
                imageObject['@id'],
                article,
                false,
                faq?.['@id'],
            );
            graphs.push(articleScheme);
        }

        if (courseData) {
            const course = generateCourseJsonLD(
                defaultSeo,
                webPage['@id'],
                courseData,
                faq?.['@id'],
            );
            graphs.push(course);
        }

        if (itemList) {
            graphs.push(itemList(webPage['@id']));
        }
    }

    if (user) {
        // TODO: user pripada organizaciji?
        const userScheme = generatePersonScheme(defaultSeo, user);
        graphs.push(userScheme);
    }

    const graphObj: Graph = {
        '@context': 'https://schema.org',
        '@graph': graphs,
    };

    return graphObj;
};
