'use client';

import { useEffect } from 'react';
import styles from './Drawer.module.scss';
import cn from 'classnames';

interface DrawerProps {
    open: boolean;
    onClose: () => void;
    onOpen?: () => void;
    style?: React.CSSProperties;
    children: React.ReactNode;
    header?: React.ReactNode;
}

export const Drawer: React.FC<DrawerProps> = ({
    open,
    onClose,
    onOpen,
    style,
    children,
    header,
}) => {
    useEffect(() => {
        if (open && onOpen) {
            onOpen();
        }
    }, [open, onOpen]);

    return (
        <div
            className={cn(styles.drawerBackdrop, { [styles.open]: open })}
            onClick={onClose}
            aria-label="hamburger menu"
        >
            <div
                className={cn(styles.drawerContent, { [styles.open]: open })}
                style={style}
                onClick={(e) => e.stopPropagation()} // Prevent click inside drawer from closing it
            >
                {!!header && header}

                <div className={styles.drawerBody}>{children}</div>
            </div>
        </div>
    );
};

export default Drawer;
