import styles from './TopMenuDropdownContent.module.scss';
import Link from '../../atoms/Link';
import Typography from '../../atoms/Typography';
import Box from '../../atoms/Box';
import Badge from '../../atoms/Badge';
import Grid from '../Grid';

const TopMenuDropdownContent = ({
    columns = [],
}: {
    columns: {
        title?: string;
        items?: {
            title: string;
            description?: string;
            icon?: React.ReactNode;
            url: string;
            disabled?: boolean;
            highlightText?: string;
        }[];
    }[];
}) => {
    return (
        <Grid container className={styles['menu-container']}>
            {columns.map((column, index) => (
                <Grid item md={12 / columns.length} key={index} className={styles['items']}>
                    {column.title && (
                        <Box
                            style={{ borderBottom: '1px solid var(--secondary-color)' }}
                            padding=".5rem"
                            margin="0 0 1rem 0"
                        >
                            <Typography fontWeight="bold" style={{ textTransform: 'uppercase' }}>
                                {column.title}
                            </Typography>
                        </Box>
                    )}

                    {column.items?.map((item) => (
                        <Box key={item.title} className={styles['item']}>
                            <Link to={item.disabled ? '#' : item.url}>
                                <Box display="flex">
                                    {!!item.icon && <Box margin="0 1rem 0 0">{item.icon}</Box>}

                                    <Box>
                                        <Box display="flex" style={{ gap: '.5rem' }}>
                                            <Typography fontWeight="bold">{item.title}</Typography>

                                            <Box>
                                                {item.highlightText ? (
                                                    <Badge small color="secondary">
                                                        {item.highlightText}
                                                    </Badge>
                                                ) : (
                                                    item.disabled && (
                                                        <Badge small color="yellow">
                                                            Coming soon
                                                        </Badge>
                                                    )
                                                )}
                                            </Box>
                                        </Box>

                                        {!!item.description && (
                                            <Typography color="#d9d9d9">
                                                {item.description}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </Link>
                        </Box>
                    ))}
                </Grid>
            ))}
        </Grid>
    );
};

export default TopMenuDropdownContent;
