export const slugify = (text = '', prefix = '#') => {
    let slug = text
        .replace(/`/g, '')
        .replace(/^\d+\.\s*/, '')
        .trim()
        .replace(/[^\w\s]/gi, '')
        .toLowerCase()
        .replace(/\s+/g, '-');

    // if slug starts with a number, prefix it with 'id-'
    if (slug.match(/^\d/)) {
        slug = `id-${slug}`;
    }

    return `${prefix}${slug}`;
};

export const countWords = (str?: string) => {
    return str?.trim().split(/\s+/).length;
};

export const toKebabCase = (str: string = '') => {
    return (
        str
            // Convert spaces, underscores, and dashes to a single space
            .replace(/[\s_-]+/g, ' ')
            // Convert to lowercase and replace spaces with dashes
            .replace(/\s+/g, '-')
            // Remove any non-alphanumeric characters except for dashes
            .replace(/[^a-z0-9-]/gi, '')
            // Convert to lowercase
            .toLowerCase()
    );
};

export const getPropByString = (o = {}, s = '') => {
    let newString = s;
    let newObject: any = o;
    newString = newString.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    newString = newString.replace(/^\./, ''); // strip a leading dot
    const a = newString.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (typeof k !== 'undefined' && k in newObject) {
            newObject = newObject[k];
        } else {
            return;
        }
    }

    return newObject;
};

export const toCapitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
