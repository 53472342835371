"use client"

import Button from "../../atoms/Button"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
import Tooltip from "../../atoms/Tooltip"
import DropdownMenu from "../DropdownMenu"
import Box from "../../atoms/Box"
import { useState } from "react"

const NotificationsTopMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Tooltip
        placement='bottom'
        title="Notifications"
      >
        <Button
          variant="text"
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <NotificationsNoneIcon style={{ color: "#fff" }} />
        </Button>
      </Tooltip>
      <DropdownMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box padding="0.5rem">No notifications yet!</Box>
      </DropdownMenu>
    </Box>
  )
}

export default NotificationsTopMenu
