import Typography from '../Typography';
import StyledTableCell from '../StyledTableCell';
import StyledTableRow from '../StyledTableRow';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const TableFeatureCheck = ({ checked }: any) => {
    if (checked === true) {
        return <CheckCircleOutlineIcon />;
    }

    if (checked === 'limited') {
        return <Typography>limited</Typography>;
    }

    return <Typography>-</Typography>;
};

const TableRowData = ({ data, title }: any) => {
    return (
        <>
            <StyledTableRow>
                <StyledTableCell colSpan={3} primary>
                    <Typography variant="h4" fontSize="1.75rem !important">
                        {title}
                    </Typography>
                </StyledTableCell>
            </StyledTableRow>

            {data.map(({ id, title: rowTitle, basicPlan, proPlan }: any) => (
                <StyledTableRow key={id}>
                    <StyledTableCell>
                        <Typography>{rowTitle}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        <TableFeatureCheck checked={basicPlan} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        <TableFeatureCheck checked={proPlan} />
                    </StyledTableCell>
                </StyledTableRow>
            ))}
        </>
    );
};

export default TableRowData;
