export const humanizeDuration = (duration: number) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor(duration / (1000 * 60 * 60));

    // Round up minutes if seconds are more than 30
    if (seconds >= 30) {
        minutes += 1;
    }

    // Adjust hours and minutes for overflows
    if (minutes >= 60) {
        hours += 1;
        minutes -= 60;
    }

    let result = [];
    if (hours > 0) result.push(hours + (hours === 1 ? ' hour' : ' hours'));
    if (minutes > 0) result.push(minutes + (minutes === 1 ? ' minute' : ' minutes'));

    return result.join(', ');
};

export const calculateDuration = (startTime: Date, endTime: Date) => {
    return endTime.getTime() - startTime.getTime();
};

export const formatDuration = (duration: number) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor(duration / (1000 * 60 * 60));

    const pad = (num: number) => (num < 10 ? '0' + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

// format duration like "1h 14m " or "14m 30s"
export const formatDurationShort = (duration: number) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor(duration / (1000 * 60 * 60));

    const result = [];
    if (hours > 0) result.push(`${hours}h`);
    if (minutes > 0) result.push(`${minutes}m`);
    if (seconds > 0) result.push(`${seconds}s`);

    return result.join(' ') || '0s';
};

export const fromNow = (date: Date) => {
    const now = new Date();
    const past = new Date(date);
    const diffInSeconds = Math.round((now.getTime() - past.getTime()) / 1000);
    const diffInMinutes = Math.round(diffInSeconds / 60);
    const diffInHours = Math.round(diffInMinutes / 60);
    const diffInDays = Math.round(diffInHours / 24);
    const diffInMonths = Math.round(diffInDays / 30);
    const diffInYears = Math.round(diffInDays / 365);

    if (diffInSeconds < 45) return 'a few seconds ago';
    if (diffInSeconds < 90) return 'a minute ago';
    if (diffInMinutes < 45) return `${diffInMinutes} minutes ago`;
    if (diffInMinutes < 90) return 'an hour ago';
    if (diffInHours < 24) return `${diffInHours} hours ago`;
    if (diffInHours < 48) return 'yesterday';
    if (diffInDays < 30) return `${diffInDays} days ago`;
    if (diffInDays < 60) return 'a month ago';
    if (diffInMonths < 12) return `${diffInMonths} months ago`;
    if (diffInMonths < 24) return 'a year ago';
    return `${diffInYears} years ago`;
};

export const formatDateLL = (dateInput: string | number | Date) => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const date = new Date(dateInput);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${months[monthIndex]} ${day}, ${year}`;
};

export const addTime = (date: string | number | Date, amount: number, unit: 'year' | 'month') => {
    const newDate = new Date(date);
    if (unit === 'year') {
        newDate.setFullYear(newDate.getFullYear() + amount);
    } else if (unit === 'month') {
        newDate.setMonth(newDate.getMonth() + amount);
    }

    return newDate;
};
