"use client";

import { Box, Button, Input } from "@repo/ui";
import { redirect } from 'next/navigation'
import { useState } from "react";
import { loginUser } from "@/store/mutable-data/user/actions";
import { useUserStore } from "@/store/mutable-data/user/UserStoreContext";

const LoginForm = ({
  autoFocus = true,
  withoutReload = false,
  callback = undefined,
  nextRoute = "/",
}) => {
  const { user, setUserState } = useUserStore();
  const loggedIn = Boolean(user.id);

  const [loading, setLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
    globalError: "",
  });

  const setErrors = (newState) => {
    setFormErrors((prevState) => ({ ...prevState, ...newState }));
  };

  const redirectRoute = nextRoute || "/";
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    let hasError = false;
    if (
      !email ||
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      )
    ) {
      setErrors({ email: "You must enter a valid email address." });
      hasError = true;
    }

    if (!password) {
      setErrors({ password: "You must enter password." });
      hasError = true;
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    await loginUser({ email, password }, async (newUser) => {
      if (!withoutReload) {
        window.location.href = redirectRoute;
      }

      // if (newUser?.id) {
      //   if (socket && socket.emit) {
      //     socket.emit("AuthenticateUser", {
      //       userId: newUser.id,
      //     })
      //   }
      // }

      await setUserState(newUser);

      if (callback) {
        await callback();
      }
    });
    setLoading(false);
  };

  const onFormChange = () => {
    setErrors({ email: "", password: "" });
  };

  if (loggedIn) {
    return redirect(redirectRoute);
  }

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      onChange={onFormChange}
      style={{
        margintop: "1rem",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Input
        required
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus={autoFocus}
        error={formErrors.email}
      />

      <Input
        required
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        error={formErrors.password}
      />

      <Button type="submit" margin="1.5rem 0 1rem 0" loading={loading}>
        Sign In
      </Button>
    </Box>
  );
};
export default LoginForm;
