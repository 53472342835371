'use client';

import { useRef, useEffect } from 'react';
import styles from './DropdownMenu.module.scss';
import classNames from 'classnames';

type DropdownMenuProps = {
    anchorEl: HTMLElement | null;
    open: boolean;
    onClose?: () => void;
    children: React.ReactNode;
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ anchorEl, open, onClose, children }) => {
    const menuRef = useRef<HTMLDivElement>(null);

    // Close menu when clicking outside
    useEffect(() => {
        const checkIfClickedOutside = (e: MouseEvent) => {
            if (open && menuRef.current && !menuRef.current.contains(e.target as Node)) {
                if (onClose) {
                    onClose();
                }
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [open, onClose]);

    const menuStyles = {
        top: anchorEl ? anchorEl.getBoundingClientRect().bottom + window.scrollY : 0,
    };

    return (
        <div
            className={classNames(styles.dropdownMenu, { [styles.hidden]: !open })}
            style={menuStyles}
            ref={menuRef}
        >
            <ul className={styles.menuList}>{children}</ul>
        </div>
    );
};

export default DropdownMenu;
