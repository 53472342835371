import classNames from 'classnames';
import styles from './Checkbox.module.scss';

type CheckboxProps = {
    checked: boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (_event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large'; // Assuming you want to manage different sizes
    label?: string;
};

export const Checkbox: React.FC<CheckboxProps> = ({
    checked,
    onChange,
    disabled = false,
    label,
}) => {
    const container = classNames(styles.checkboxContainer, {
        [styles.withLabel]: !!label,
    });

    return (
        <label className={container}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                className={styles.checkboxInput}
            />

            <span className={styles.checkboxLabel}>{label}</span>
        </label>
    );
};

export default Checkbox;
