import { Typography, PageHighlightSection } from '@repo/ui';

const LetsConnectSection = ({ company, Image }: { company: string; Image: React.ElementType }) => {
    return (
        <PageHighlightSection
            Image={Image}
            title="Let's connect and shape the future together"
            imageSrc="https://static.code-hl.com/resources/contact_us.png"
            imageAlt="3D Letter"
            imageWidth={350}
            imageHeight={350}
            ChildrenRenderer={({ children }) => <>{children}</>}
        >
            <>
                <Typography margin="0 0 2rem 0">
                    Have a question, feedback, or a business inquiry? Noticed something on our site
                    that needs attention? We&apos;re here to listen and assist. At {company}, your
                    input is invaluable in helping us enhance our platform and services.{' '}
                </Typography>

                <Typography>
                    Whether you&apos;re encountering an issue, have suggestions for improvement, or
                    are interested in collaboration, your perspective is what helps us grow and
                    improve. Reach out to us for any reason - we&apos;re eager to hear from you and
                    committed to making your experience with {company} the best it can be.
                </Typography>
            </>
        </PageHighlightSection>
    );
};

export default LetsConnectSection;
