import Container from '../../atoms/Container';
import Copyright from '../../atoms/Copyright';
import Link from '../../atoms/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

import Box from '../../atoms/Box';
import Typography from '../../atoms/Typography';
import Grid from '../Grid';

import styles from './Footer.module.scss';
import Button from '../../atoms/Button';
import Logo from '../../atoms/Logo';
import { FooterLink } from '../../layouts/FullWidthLayout';

const socialFormats = [
    {
        title: 'Instagram',
        icon: <InstagramIcon fontSize="large" />,
        format: 'instagram.com',
    },
    {
        title: 'LinkedIn',
        icon: <LinkedinIcon fontSize="large" />,
        format: 'linkedin.com',
    },
    {
        title: 'Facebook',
        icon: <FacebookIcon fontSize="large" />,
        format: 'facebook.com',
    },
    {
        title: 'YouTube',
        icon: <YouTubeIcon fontSize="large" />,
        format: 'youtube.com',
    },
];

const SocialMedia = ({ social }: { social: string[] }) => {
    const filteredSocial = social.filter((link) =>
        socialFormats.some(({ format }) => link.includes(format)),
    );

    return (
        <Grid container style={{ marginTop: '1rem' }} gap={1}>
            {filteredSocial.map((link) => {
                const { title, icon } =
                    socialFormats.find(({ format }) => link.includes(format)) || {};

                return (
                    <Grid item key={title}>
                        <a
                            aria-label={`${title} Community`}
                            href={link}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {icon}
                        </a>
                    </Grid>
                );
            })}
        </Grid>
    );
};

const Footer = ({
    pageUrl,
    items = [],
    social = [],
    Image,
    companyName = 'Code Highlights',
}: {
    pageUrl: string;
    items: FooterLink[];
    social: string[];
    Image: React.ElementType;
    companyName: string;
}) => {
    if (!Image || Image === 'img') {
        throw new Error('Footer: Image props is required');
    }

    return (
        <div className={styles['main-footer']}>
            <Container>
                <Box margin="0 0 2rem 0">
                    <Grid container spacing={4}>
                        {items.map(({ title, links }) => (
                            <Grid item lg={3} md={3} sm={6} xs={6} key={title}>
                                <Typography
                                    fontSize="1.5rem !important"
                                    variant="h2"
                                    margin="0 0 1rem 0"
                                >
                                    {title}
                                </Typography>
                                <ul>
                                    {links.map(({ title, url }) => (
                                        <li key={title}>
                                            <Link to={url}>
                                                <Typography>{title}</Typography>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        ))}
                    </Grid>

                    <SocialMedia social={social} />

                    <Box width={320}>
                        <Logo Image={Image} companyName={companyName} />
                    </Box>
                </Box>

                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    padding="1rem 0 0 0"
                    style={{ borderTop: 'var(--border)' }}
                >
                    <Grid item>
                        <Button variant="text">
                            <Link to="/privacy">
                                <Typography>Privacy Policy</Typography>
                            </Link>
                        </Button>

                        <Button variant="text">
                            <Link to="/terms">
                                <Typography>Terms of Use</Typography>
                            </Link>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Copyright pageUrl={pageUrl} companyName={companyName} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;
