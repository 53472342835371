import classNames from 'classnames';
import Box from '../Box';
import styles from './Badge.module.scss';

const getColor = (id: number): { backgroundColor: string; color: string } => {
    const mod = (id % 7) % 5;

    return (
        {
            0: {
                backgroundColor: 'var(--accent-color)',
                color: 'var(--surface-color)',
            },
            1: {
                backgroundColor: 'var(--primary-color)',
                color: 'var(--text-color)',
            },
            2: {
                backgroundColor: 'var(--red)',
                color: 'var(--text-color)',
            },
            3: {
                backgroundColor: 'var(--secondary-color)',
                color: 'var(--text-color)',
            },
            4: {
                backgroundColor: 'var(--green)',
                color: 'var(--text-color)',
            },
        }[mod] || {
            backgroundColor: 'var(--red)',
            color: 'var(--text-color)',
        }
    );
};

const Badge = ({
    id,
    children,
    color,
    randomColor,
    animate,
    small,
}: {
    id?: number;
    children: React.ReactNode;
    color?: 'primary' | 'secondary' | 'default' | 'green' | 'red' | 'yellow' | 'accent';
    randomColor?: boolean;
    animate?: boolean;
    small?: boolean;
}) => {
    const { backgroundColor, color: textColor } = randomColor
        ? getColor(id || 0)
        : {
              primary: {
                  backgroundColor: 'var(--primary-color)',
                  color: 'var(--text-color)',
              },
              secondary: {
                  backgroundColor: 'var(--secondary-color)',
                  color: 'var(--text-color)',
              },
              default: {
                  backgroundColor: 'var(--surface-color)',
                  color: 'var(--text-color)',
              },
              green: {
                  backgroundColor: 'var(--green)',
                  color: 'var(--text-color)',
              },
              red: {
                  backgroundColor: 'var(--red)',
                  color: 'var(--text-color)',
              },
              yellow: {
                  backgroundColor: 'var(--yellow)',
                  color: 'var(--background-color)',
              },
              accent: {
                  backgroundColor: 'var(--accent-color)',
                  color: 'var(--background-color)',
              },
          }[color || 'default'];

    const badgeClasses = classNames(styles.badge, {
        [`${styles.animate}`]: animate,
        [`${styles.small}`]: small,
    });

    return (
        <Box backgroundColor={backgroundColor} color={textColor} className={badgeClasses}>
            {children}
        </Box>
    );
};

export default Badge;
