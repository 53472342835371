import styles from './StyledTableCell.module.scss';
import classnames from 'classnames';

type StyledTableCellProps = {
    children: React.ReactNode;
    align?: 'left' | 'center' | 'right' | 'justify' | 'char';
    colSpan?: number;
    primary?: boolean;
    Component?: 'td' | 'th';
};

const StyledTableCell: React.FC<StyledTableCellProps> = ({
    children,
    align,
    colSpan,
    primary,
    Component = 'td',
    ...props
}) => {
    const cellClass = classnames({
        [styles.cell]: true,
        [styles.primary]: primary,
    });

    return (
        <Component className={cellClass} align={align} colSpan={colSpan} {...props}>
            {children}
        </Component>
    );
};

export default StyledTableCell;
