'use client';

import { useRef } from 'react';
import styles from './OutlineEditor.module.scss';
import Button from '../../atoms/Button';
import Input from '../../atoms/Input';
import Box from '../../atoms/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface BlogOutline {
    type: HeaderType;
    content: string;
    children: BlogOutline[];
}

interface OutlineEditorProps {
    outline: BlogOutline[];
    setOutline: any;
}

const getHeaderFontSize = (type: string) => {
    switch (type) {
        case 'h2':
            return '2.5rem';
        case 'h3':
            return '2rem';
        case 'h4':
            return '1.75rem';
        case 'h5':
            return '1.5rem';
        case 'h6':
            return '1.25rem';
        default:
            return '2rem';
    }
};

const OutlineEditor: React.FC<OutlineEditorProps> = ({ outline, setOutline }) => {
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const buildPathList = (items: BlogOutline[], currentPath: number[] = []): number[][] => {
        let paths: number[][] = [];
        items.forEach((item, index) => {
            const path = [...currentPath, index];
            paths.push(path);
            if (item.children) {
                paths = paths.concat(buildPathList(item.children, path));
            }
        });
        return paths;
    };

    // Use this function to build the path list whenever the data changes
    const pathList = buildPathList(outline);

    const setInputRef = (el: HTMLInputElement | null, path: number[]) => {
        const flatIndex = pathList.findIndex((p) => JSON.stringify(p) === JSON.stringify(path));
        inputRefs.current[flatIndex] = el;
    };

    // Takes a path (array of indices) to the item, and the new content
    const handleEditHeader = (path: number[], newContent: string) => {
        // Function to recursively update the outline
        const updateItem = (items: BlogOutline[], path: number[]): BlogOutline[] => {
            // If we're at the last index, update the content
            if (path.length === 1) {
                return items.map((item, index) => {
                    if (index === path[0]) {
                        return { ...item, content: newContent };
                    }
                    return item;
                });
            }

            // If not, recursively update children
            const [currentIndex, ...restPath] = path;
            return items.map((item, index) => {
                if (index === currentIndex) {
                    return { ...item, children: updateItem(item.children, restPath) };
                }
                return item;
            });
        };

        setOutline(updateItem(outline, path));
    };

    const handleDeleteHeader = (path: number[]) => {
        // Function to recursively delete a header
        const deleteItem = (items: BlogOutline[], path: number[]): BlogOutline[] => {
            if (path.length === 1) {
                // If we're at the last index, delete the item from the array
                return items.filter((_, index) => index !== path[0]);
            }

            // If not, recursively update children
            const [currentIndex, ...restPath] = path;
            return items.map((item, index) => {
                if (index === currentIndex) {
                    return { ...item, children: deleteItem(item.children, restPath) };
                }
                return item;
            });
        };

        setOutline(deleteItem(outline, path));
    };

    const handleAddHeader = (initialPath: number[], path: number[], type: HeaderType) => {
        const newHeader: BlogOutline = {
            type,
            content: '',
            children: [],
        };

        const insertHeader = (
            items: BlogOutline[],
            path: number[],
            newItem: BlogOutline,
        ): BlogOutline[] => {
            if (path.length === 1) {
                setTimeout(() => {
                    const flatIndex = pathList.findIndex(
                        (p) => JSON.stringify(p) === JSON.stringify(initialPath),
                    );
                    const targetIndex = flatIndex + 1;

                    if (targetIndex >= 0 && targetIndex < inputRefs.current.length) {
                        inputRefs.current[targetIndex]?.focus();
                    }
                }, 0);

                return [...items.slice(0, path[0]), newItem, ...items.slice(path[0])];
            } else {
                // Recursive insertion for deeper levels
                const [currentIndex, ...restPath] = path;
                return items.map((item, index) =>
                    index === currentIndex
                        ? { ...item, children: insertHeader(item.children, restPath, newItem) }
                        : item,
                );
            }
        };

        setOutline((outline: any) => insertHeader(outline, path, newHeader));
    };

    const addHeaderToNextPath = (path: number[], type: HeaderType) => {
        // Retrieve the current item using the path
        const currentItem = getPathItem(outline, path);
        if (!currentItem) {
            return;
        }

        let newPath = [...path];
        if (currentItem.type !== 'h6') {
            // If it's an h2, add a new h3 as the first child of this h2
            newPath.push(0); // Insert at the start of the children array of h2
        } else {
            // For h3, add it after the current h3 at the same level
            newPath[newPath.length - 1] += 1; // Correctly increment the last index to insert after the current item
        }

        handleAddHeader(path, newPath, type);
    };

    const handleKeyPress = (
        e: React.KeyboardEvent<HTMLInputElement>,
        path: number[],
        nextHeader: HeaderType,
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default Enter behavior

            addHeaderToNextPath(path, nextHeader);
        } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault(); // Prevent default Enter behavior

            const flatIndex = pathList.findIndex((p) => JSON.stringify(p) === JSON.stringify(path));
            const targetIndex = e.key === 'ArrowUp' ? flatIndex - 1 : flatIndex + 1;

            if (targetIndex >= 0 && targetIndex < inputRefs.current.length) {
                inputRefs.current[targetIndex]?.focus();
            }
        } else if (e.key === 'Backspace' && e.currentTarget.value === '') {
            e.preventDefault();
            // Only proceed if the current input is empty
            handleDeleteHeader(path); // Call the delete function if input is empty
        }
    };

    const getPathItem = (items: BlogOutline[], path: number[]): BlogOutline | undefined => {
        let currentItem: BlogOutline | undefined = { type: 'h2', content: '', children: items }; // Temporary root
        for (let index of path) {
            if (!currentItem || index < 0 || index >= currentItem.children.length) {
                return undefined; // Guard against out-of-bounds access
            }

            currentItem = currentItem.children[index]; // Move deeper based on the current index
        }
        return currentItem;
    };

    const updateItem = (
        items: BlogOutline[], // Current structure
        path: number[], // Path to the item to be updated
        updatedItem: BlogOutline, // New content or item
        moveToParent?: boolean, // Whether the item moves to the parent level
    ): BlogOutline[] => {
        const [currentIndex, ...restPath] = path;

        if (typeof currentIndex === 'undefined') {
            return items;
        }

        if (path.length === 1) {
            // Base case: Update or move the specified item
            if (moveToParent) {
                // Remove the item from its current children level
                return [
                    ...items.slice(0, currentIndex),
                    ...items.slice(currentIndex + 1), // Exclude the moved item
                ];
            } else {
                return items.map((item, i) =>
                    i === currentIndex ? { ...item, ...updatedItem } : item,
                ); // Update the item
            }
        }

        if (!items[currentIndex]) {
            return items;
        }

        const updatedChildren = updateItem(
            items[currentIndex]?.children || [], // Add null check and provide a default value
            restPath,
            updatedItem,
            moveToParent,
        );

        const updatedItems = items
            .map((item, i) => {
                if (i === currentIndex) {
                    return { ...item, children: updatedChildren }; // Update the parent with new children
                }
                return item; // Keep other items unchanged
            })
            .filter((item) => item !== undefined) as BlogOutline[]; // Filter out undefined values

        if (moveToParent) {
            const movedItem =
                restPath[0] !== undefined
                    ? { ...updatedChildren[restPath[0]], ...updatedItem }
                    : undefined; // Ensure the type is updated
            return [
                ...updatedItems.slice(0, currentIndex + 1), // Insert before the moved item
                movedItem, // Add the moved item with the updated type
                ...updatedItems.slice(currentIndex + 1), // Rest of the items
            ] as BlogOutline[]; // Cast the array to BlogOutline[]
        }

        return updatedItems;
    };

    const changeToPreviousHedearType = (path: number[]) => {
        const currentItem = getPathItem(outline, path);
        if (!currentItem) {
            return; // Return if no item is found
        }

        // Determine new header type
        const currentType = currentItem.type;
        const newType = currentType
            ? (`h${Math.max(1, parseInt(currentType[1] || '2') - 1)}` as HeaderType)
            : 'h1'; // Change to previous header type, default to 'h1' if currentType is undefined

        if (newType === 'h1') {
            return; // If the current header is already h1, do nothing
        }

        // If it's h2 or more, move it to the same level as its parent
        if (path.length > 1) {
            // Set the updated outline by moving to parent level
            const newOutline = updateItem(outline, path, { ...currentItem, type: newType }, true);
            setOutline(newOutline); // Update the outline
        } else {
            // If there's only one level, update the type
            const newOutline = updateItem(outline, path, { ...currentItem, type: newType });
            setOutline(newOutline);
        }
    };

    // const changeToNextHedearType = (path: number[]) => {
    //     const currentItem = getPathItem(outline, path);
    //     if (!currentItem) {
    //         return; // Return if no item is found
    //     }

    //     // Determine new header type
    //     const currentType = currentItem.type;
    //     const newType = `h${Math.min(6, parseInt(currentType[1] || '5') + 1)}` as HeaderType; // Change to next header type

    //     // If it's h6 or less, move it to the same level as its parent
    //     if (path.length > 1) {
    //         // Set the updated outline by moving to parent level
    //         const newOutline = updateItem(outline, path, { ...currentItem, type: newType }, true);
    //         setOutline(newOutline); // Update the outline
    //     } else {
    //         // If there's only one level, update the type
    //         const newOutline = updateItem(outline, path, { ...currentItem, type: newType });
    //         setOutline(newOutline);
    //     }
    // };

    const HeaderRenderer = ({ header, path }: { header: BlogOutline; path: number[] }) => {
        const headerType = header.type;
        const marginSize = path.length * 1; // Increase margin for each level of depth

        const nextHeader =
            `h${Math.min(6, parseInt(headerType.split('h')[1] || '2') + 1)}` as HeaderType;

        return (
            <Box margin={`0 0 0 ${marginSize}rem`} key={path.join('-')}>
                <div className={styles.headerItem}>
                    <div className={styles.headerLabel} data-type={headerType}>
                        {/* <Box>D</Box> */}

                        {headerType === 'h3' && (
                            <Box
                                className={styles.headerLabelText}
                                onClick={() => changeToPreviousHedearType(path)}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <ChevronLeftIcon fontSize="small" />
                            </Box>
                        )}

                        <Box className={styles.headerLabelText}>{headerType}</Box>

                        {/* {path[path.length - 1] !== 0 && (
                            <Box
                                className={styles.headerLabelText}
                                onClick={() => changeToNextHedearType(path)}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <ChevronRightIcon fontSize="small" />
                            </Box>
                        )} */}

                        <Input
                            ref={(el: any) => setInputRef(el, path)}
                            containerStyle={{ margin: 0 }}
                            fullwidth
                            style={{
                                border: 0,
                                fontSize: getHeaderFontSize(header.type),
                                fontWeight: 'bold',
                                padding: '0.5rem 0',
                            }}
                            type="text"
                            value={header.content}
                            onChange={(e: any) => handleEditHeader(path, e.target.value)}
                            onKeyDown={(e: any) => handleKeyPress(e, path, nextHeader)} // Add this line
                        />
                    </div>

                    <Button
                        className={styles.actionButton}
                        onClick={() => addHeaderToNextPath(path, nextHeader)}
                    >
                        Add
                    </Button>

                    {headerType !== 'h1' && (
                        <Button
                            className={styles.actionButton}
                            onClick={() => handleDeleteHeader(path)}
                        >
                            <DeleteIcon />
                        </Button>
                    )}
                </div>

                {header.children.map((children, index) => {
                    const nextPath = [...path, index];

                    // Called as function instead of component because components loses focus
                    return HeaderRenderer({ header: children, path: nextPath });

                    // return (
                    //     <HeaderRenderer
                    //         key={`${nextPath.join('-')}-${index}`}
                    //         header={children}
                    //         path={nextPath}
                    //     />
                    // );
                })}
            </Box>
        );
    };

    // const outlineWithTitle: BlogOutline = {
    //     type: 'h1',
    //     content: 'Title',
    //     children: outline,
    // };

    return (
        <Box>
            <Typography variant="h2" fontSize="1.5rem" margin="1rem 0 0.5rem 0">
                Review and edit suggested outline before generating the article
            </Typography>

            <div className={styles.editorContainer}>
                {/* {outline && <HeaderRenderer key="h1-0" header={outline} path={[0]} />} */}

                {/* Called as function instead of component because components loses focus */}
                {outline?.map((children, index) =>
                    HeaderRenderer({ header: children, path: [index] }),
                )}
            </div>
        </Box>
    );
};

export default OutlineEditor;
