'use client';

import { generateBreadcrumbs } from '@repo/utils/breadcrumbUtils';
import { useMemo } from 'react';

import NavigateNext from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '../../atoms/Breadcrumbs';

export default function NextBreadcrumbs({
    defaultSeo,
    title,
    pathname,
    searchParams,
}: {
    defaultSeo: any;
    title?: string;
    pathname: string;
    searchParams: URLSearchParams;
}) {
    const breadcrumbs = useMemo(
        () => generateBreadcrumbs(defaultSeo, pathname, searchParams),
        // ?.filter(item => item.clickable),
        [pathname, searchParams],
    );

    if (breadcrumbs.length <= 1) {
        return null;
    }

    return (
        <Breadcrumbs
            title={title}
            breadcrumbs={breadcrumbs}
            separator={<NavigateNext fontSize="small" />}
        />
    );
}
