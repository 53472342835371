import { getComponentProps, muiStyleProps } from '../../../utils/component-style.utils';
import { ElementType, ReactNode, forwardRef } from 'react';

type BoxProps = {
    children?: ReactNode;
    component?: ElementType;
    className?: string;
    id?: string;
    onSubmit?: any;
    onClick?: any;
    ref?: any;
    [key: string]: any;
} & muiStyleProps;

export const Box = forwardRef<BoxProps, any>(
    ({ children, component: Component = 'div', ...props }, ref) => {
        const { style, rest } = getComponentProps(props);

        return (
            <Component {...rest} style={style} ref={ref}>
                {children}
            </Component>
        );
    },
);

export default Box;
