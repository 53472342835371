import type { ElementType, ReactNode } from 'react';
import { JsonLdHeadTag, SideMenu } from '@repo/ui';
import type { SeoObjectOutput } from '@repo/utils/seoUtils';
import type { JsonLdProps } from '@repo/utils/jsonLdUtils';
import { generateJsonLD } from '@repo/utils/jsonLdUtils';
// import TopMenu from "@/components/organisms/TopMenu";
import FullWidthLayoutContent from './FullWidthLayoutContent';
import styles from './FullWidthLayout.module.scss';
import classNames from 'classnames';
import { SideMenuItemType } from '../../organisms/SideMenu';

export interface HeaderSectionProps {
    title?: string;
    titleRendered?: ElementType;
    titleAdditionalProps?: any;
    right?: JSX.Element;
    rendered?: ElementType;
    mainButton?: JSX.Element;
    children?: string | JSX.Element;
    background?: string;
    childrenFlex?: number;
}

export interface FooterLink {
    title: string;
    links: {
        title: string;
        url: string;
    }[];
}

export interface FullWidthLayoutProps {
    defaultSeo?: any;
    className?: string;
    seoData: Partial<SeoObjectOutput>;
    Menu?: ElementType;
    children?: string | JSX.Element;
    loading?: boolean;
    headerSection?: HeaderSectionProps;
    HeaderSeparator?: ReactNode;
    jsonLDElementParams?: JsonLdProps;
    noHeader?: boolean;
    noFooter?: boolean;
    noBreadcrumbs?: boolean;
    appCategories: any;
    footerLinks: FooterLink[];
    sidebar?: {
        items: SideMenuItemType[];
        top?: JSX.Element;
        bottom?: JSX.Element;
    };
    sidebarCollapsed?: boolean;
    rightMenu?: any;
    Image: React.ElementType;
    pathname: string;
    searchParams: URLSearchParams;
}

const FullWidthLayout = ({
    defaultSeo,
    appCategories,
    className,
    seoData,
    Menu = () => <></>,
    children,
    loading = false,
    headerSection = undefined,
    HeaderSeparator,
    jsonLDElementParams,
    noHeader = false,
    noFooter = false,
    noBreadcrumbs = false,
    footerLinks,
    rightMenu,
    sidebar,
    sidebarCollapsed,
    Image,
    pathname,
    searchParams,
}: FullWidthLayoutProps) => {
    if (!Image || Image === 'img') {
        throw new Error('FullWidthLayout: Image props is required');
    }

    const jsonLd = generateJsonLD({
        defaultSeo,
        seoData,
        ...jsonLDElementParams,
    });

    const mainClass = classNames(styles['container-main'], className);

    return (
        <div className={styles['container-with-side-menu']}>
            <Menu />

            {sidebar && (
                <SideMenu
                    top={sidebar.top}
                    bottom={sidebar.bottom}
                    items={sidebar.items}
                    collapsed={sidebarCollapsed}
                />
            )}

            <div className={mainClass}>
                <JsonLdHeadTag jsonLd={jsonLd} />

                <div className={styles['full-width-layout-container']}>
                    <FullWidthLayoutContent
                        Image={Image}
                        appCategories={appCategories}
                        defaultSeo={defaultSeo}
                        seoData={seoData}
                        // Menu={Menu}
                        loading={loading}
                        headerSection={headerSection}
                        HeaderSeparator={HeaderSeparator}
                        noHeader={noHeader}
                        noFooter={noFooter}
                        noBreadcrumbs={noBreadcrumbs}
                        footerLinks={footerLinks}
                        pathname={pathname}
                        searchParams={searchParams}
                    >
                        {children}
                    </FullWidthLayoutContent>
                </div>
            </div>

            {rightMenu && <div className={styles['container-right']}>{rightMenu}</div>}
        </div>
    );
};

export default FullWidthLayout;
