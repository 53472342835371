'use client';

import { FC } from 'react';
import { TabItemType } from '../../..';
import styles from './Tab.module.scss';
import classNames from 'classnames';

interface ITab {
    name: TabItemType['name'];
    active: boolean;
    onTabClick: () => void;
}

const Tab: FC<ITab> = ({ name, active, onTabClick }) => {
    const containerClass = classNames(styles['item-container'], {
        [styles['active-item']]: active,
    });

    return (
        <div className={containerClass} role="button" onClick={onTabClick}>
            {name}
        </div>
    );
};

export default Tab;
