import { Button, Price, Spinner, Input, Box, Typography, Grid } from "@repo/ui"
import DeleteIcon from "@mui/icons-material/Delete"
import classNames from "classnames"
import { addTime, formatDateLL } from "@repo/utils/datetimeUtils"
import styles from "./ShoppingCartPreview.module.scss"

const ShoppingCartPreview = ({
  Image,
  isFreeCourse,
  coursePrice,
  applyPromoCode,
  removePromoCode,
  loading = false,
  simple,
  monthly = false,
  annual,
  monthlyPrice,
  qty = 1
}) => {
  const includesPromoCode = coursePrice?.items?.find(
    shoppingCartItem => shoppingCartItem.type === "coupon",
  )

  const shoppingCardItemClass = classNames(styles["shopping-cart-item"], {
    [styles["shopping-cart-item-simple"]]: simple,
  })

  return (
    <Box px="10px">
      <Typography fontSize="1.5rem !important" fontWeight="bold">
        Summary
      </Typography>

      {coursePrice?.items?.map((shoppingCartItem, index) => {
        const key = shoppingCartItem.id || index

        if (shoppingCartItem.type === "item") {
          return (
            <Box
              key={key}
              className={shoppingCardItemClass}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={2}
            >
              <Box display="flex" alignItems="center" className={styles["shopping-cart-left"]}>
                <Image
                  src={shoppingCartItem.item?.course?.coursePreviewVideo?.previewImage}
                  width={simple ? 290 : 86}
                  height={simple ? 217 : 64}
                  alt="Cart item preview"
                />
                <Typography margin="0 0 0 .5rem">{shoppingCartItem.item.course?.title}</Typography>
              </Box>

              {!simple && (
                <Typography className={styles["shopping-cart-right"]}>
                  <Price pricing={shoppingCartItem.item} flex payment />
                </Typography>
              )}

              {Boolean(simple) && (
                <div
                  style={{ width: "100%", borderTop: "1px solid rgb(96,96,96)", paddingTop: 16 }}
                >
                  <Grid container display="flex" alignItems="center">
                    <Grid item xs>
                      {monthly || annual ? (
                        <div>
                          <div>All-in-one ({monthly ? "Monthly" : "Annual"})</div>
                          {annual && (
                            <div>
                              <strike>
                                ${(Number(monthlyPrice.items[0].item.discountedPrice)).toFixed(2)}
                              </strike>{" "}
                              ${(Number(shoppingCartItem.item.discountedPrice) / 12).toFixed(2)} x 12
                              months
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <div>Original Price:</div>
                          {qty && <div style={{ marginTop: 5 }}>Quantity:</div>}
                        </>
                      )}
                    </Grid>
                    <Grid item>
                      <Price
                        pricing={{
                          discountedPrice: shoppingCartItem.item.price,
                        }}
                        flex
                        payment
                        percentage
                        monthly={shoppingCartItem.item.type === "MONTHLY"}
                        annual={shoppingCartItem.item.type === "ANNUAL"}
                      />
                      {qty && <>x {qty}</>}
                    </Grid>
                  </Grid>
                </div>
              )}
            </Box>
          )
        }

        if (shoppingCartItem.type === "coupon") {
          if (loading) {
            return <Spinner key={key} margin="1rem 0" />
          }

          return (
            <Box key={key} display="flex" justifyContent="space-between" alignItems="center" py={2}>
              <Box>
                <div style={{ color: "var(--green)" }}>Promo code applied</div>
                <Box display="flex" alignItems="center">
                  <Typography>{shoppingCartItem.item.promoCode}</Typography>
                  <Button onClick={removePromoCode} aria-label="Delete promo code" variant="text" padding=".5rem">
                    <DeleteIcon style={{ color: "var(--red)" }} />
                  </Button>
                </Box>
              </Box>

              <Price
                pricing={{
                  discountedPrice: -shoppingCartItem.item.discount,
                  discount: shoppingCartItem.item.discountPercentage,
                }}
                flex
                payment
                percentage
              />
            </Box>
          )
        }

        return null
      })}

      {!includesPromoCode &&
        (loading ? (
          <Spinner margin="1rem 0" />
        ) : (
          <Box margin="0.5rem 0 1rem 0" component="form" noValidate onSubmit={applyPromoCode}>
            <Grid container spacing={1} display="flex" justifyContent="center" alignItems="flex-end">
              <Grid item xs={9}>
                <Input
                  style={{ marginTop: "0.5rem" }}
                  containerStyle={{ marginBottom: 0 }}
                  id="promo-code"
                  label="Promo code"
                  name="promo-code"
                  aria-describedby="promo-code"
                  autoComplete="promo-code"
                  size="small"
                />
              </Grid>
              <Grid className={styles["apply-promo-code"]} item xs={3}>
                <Button variant="contained" fullWidth type="submit">
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        ))}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ borderTop: "1px solid rgb(96,96,96)", paddingTop: "1rem" }}
      >
        <Typography fontSize="1.5rem !important">
          {monthly || annual ? "Today's total" : "Total"}
        </Typography>

        {isFreeCourse ? "FREE" : <Price pricing={{ discountedPrice: coursePrice?.total }} />}
      </Box>

      {(monthly || annual) && (
        <Typography fontSize="0.75rem !important">
          This is a recurring subscription and will renew {annual ? "annually" : "monthly"} starting
          on {""}
          {formatDateLL(addTime(new Date(), 1, annual ? "year" : "month"))}. You may cancel at any
          time in your payment settings.
        </Typography>
      )}
    </Box>
  )
}

export default ShoppingCartPreview
