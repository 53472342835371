import styles from './LinearProgress.module.scss';
import cn from 'classnames';

interface LinearProgressProps {
    value: number; // Progress value, should be between 0 and 100
    className?: string; // Allow custom class names
    style?: React.CSSProperties; // Allow inline styles
}

const LinearProgress: React.FC<LinearProgressProps> = ({ value, className, style }) => {
    // Clamp value between 0 and 100
    const progressValue = Math.min(100, Math.max(0, value));

    return (
        <div className={cn(styles.progressContainer, className)} style={style}>
            <div className={styles.progressBar} style={{ width: `${progressValue}%` }}></div>
        </div>
    );
};

export default LinearProgress;
