'use client';

import { Box, Spinner, Footer, FullWidthLayoutProps } from '@repo/ui';
import { useRef, useMemo } from 'react';

import styles from './FullWidthLayout.module.scss';

type FullWidthLayoutPropsWithSocial = {
    social?: any;
    pageUrl?: string;
    headerChildren?: string | JSX.Element;
    companyName?: string;
} & FullWidthLayoutProps;

const FullWidthLayoutContentClient = ({
    social,
    Menu,
    headerChildren,
    children,
    loading = false,
    HeaderSeparator,
    noHeader = false,
    noFooter = false,
    pageUrl,
    footerLinks,
    Image,
    companyName,
}: FullWidthLayoutPropsWithSocial) => {
    const footerRef = useRef();
    const beforeContainerRef = useRef();

    // @ts-expect-error
    const footerHeight = footerRef.current?.getBoundingClientRect().height || 360;

    // @ts-expect-error
    const beforeContainerHeight = beforeContainerRef.current?.getBoundingClientRect().height || 0;

    const containerMinHeight = useMemo(() => {
        return `calc(100vh - var(--top-menu-height) - ${footerHeight}px - ${beforeContainerHeight}px)`;
    }, [beforeContainerHeight, footerHeight]);

    return (
        <>
            {!noHeader && <Box ref={beforeContainerRef}>{headerChildren}</Box>}

            {Boolean(HeaderSeparator) && <Box flex={1}>{HeaderSeparator}</Box>}

            <Box
                className={`${styles['regular-layout-main-container']} regular-layout-main-container`}
                style={{ minHeight: containerMinHeight }}
            >
                {Menu}
                {loading ? <Spinner /> : children}
            </Box>

            {!noFooter && (
                /* @ts-expect-error */
                <div ref={footerRef}>
                    <Footer
                        Image={Image}
                        pageUrl={pageUrl}
                        items={footerLinks}
                        social={social}
                        companyName={companyName}
                    />
                </div>
            )}
        </>
    );
};

export default FullWidthLayoutContentClient;
