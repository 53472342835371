'use client';

import { Link, Box, Typography } from '@repo/ui';
import type { AuthType } from '.';

const AuthRedirect = ({ type, title }: { type: AuthType; title: string }) => {
    const urlParams =
        typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
    const nextRoute = urlParams?.get('next');

    let redirectUrl = type === 'register' ? '/login' : '/register';
    if (nextRoute) {
        redirectUrl += `?next=${nextRoute}`;
    }

    const text =
        type === 'register'
            ? { description: 'Already have an account? ', button: 'Sign In' }
            : { description: "Don't have an account? ", button: 'Sign Up' };

    return (
        <Link to={redirectUrl} ariaLabel={`${title} page redirect`}>
            <Box display="flex" justifyContent="center">
                <Typography
                    color="GrayText"
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    style={{ cursor: 'pointer' }}
                >
                    {text.description}
                </Typography>

                <Typography color="secondary" margin="0 0 0 0.5rem">
                    {text.button}
                </Typography>
            </Box>
        </Link>
    );
};

export default AuthRedirect;
