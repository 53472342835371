import styles from './ArticleCategoryTag.module.scss';
import Link from '../Link';

const getColor = (id: number): string => {
    const mod = (id % 7) % 5;

    return (
        {
            0: 'var(--accent-color)',
            1: 'var(--primary-color)',
            2: 'var(--red)',
            3: 'var(--secondary-color)',
            4: 'var(--green)',
        }[mod] || 'var(--red)'
    );
};

export const ArticleCategoryTag = ({
    id,
    tag,
    urlSlug,
    noLink = false,
}: {
    id: number;
    tag: string;
    urlSlug: string;
    noLink?: boolean;
}) => {
    if (noLink) {
        return (
            <span className={styles['category-tag']} style={{ background: getColor(id) }}>
                {tag}
            </span>
        );
    }

    return (
        <Link to={urlSlug} className={styles['category-tag']} style={{ background: getColor(id) }}>
            {tag}
        </Link>
    );
};

export default ArticleCategoryTag;
