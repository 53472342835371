import styles from './Table.module.scss';
import StyledTableRow from '../../atoms/StyledTableRow';
import TableRowData from '../../atoms/TableRowData';

type TableProps = {
    headCells: any[];
    body: { title: string; data: any[] }[];
};

const Table: React.FC<TableProps> = ({ headCells = [], body = [] }) => {
    return (
        <table className={styles.table}>
            {headCells.length > 0 && (
                <thead className={styles.header}>
                    <StyledTableRow>{headCells}</StyledTableRow>
                </thead>
            )}

            <tbody>{body.map(TableRowData)}</tbody>
        </table>
    );
};

export default Table;
