'use client';

import { createRef, FC, useState, useEffect } from 'react';
import { Card } from '../Card';
import styles from './TableOfContent.module.scss';
import { slugify } from '@repo/utils/stringUtils';

import Box from '../Box';

export interface TableOfContentHeader {
    level: number;
    text: string;
}

interface TableOfContentProps {
    headers: TableOfContentHeader[];
    pathname: string;
}

function isElementVisible(element: any, container: any) {
    const containerTop = container.scrollTop + 77;
    const containerBottom = containerTop + container.clientHeight;

    const elementTop = element.offsetTop;
    const elementBottom = elementTop + element.clientHeight;

    return elementTop >= containerTop && elementBottom <= containerBottom;
}

const TOCItem = ({
    header,
    activeId,
    ulRef,
}: {
    header: TableOfContentHeader;
    activeId: string;
    ulRef: any;
}) => {
    const marginLeft = (header.level - 1) * 20;
    const isActive = slugify(header.text, '') === activeId;
    const ref = createRef<HTMLLIElement>();

    useEffect(() => {
        if (isActive && ref.current && !isElementVisible(ref.current, ulRef.current)) {
            const offsetTop = ref.current ? ref.current.offsetTop - 77 : 0;
            ulRef.current.scrollTop = offsetTop;
        }
    }, [isActive, ref, ulRef]);

    return (
        <li
            ref={ref}
            className={styles['toc-item-wrappe']}
            style={{
                background: isActive ? 'var(--secondary-color)' : undefined,
                borderRadius: '4px',
                padding: '1px 10px',
            }}
        >
            <Box
                style={{
                    display: 'list-item',
                    marginLeft: `${marginLeft}px`,
                }}
            >
                <Box margin="0.5rem 0">
                    <Box
                        onClick={() => {
                            window.location.replace(slugify(header.text));
                        }}
                        style={{ color: '#fff' }}
                    >
                        {header.text.replaceAll('`', '')}
                    </Box>
                </Box>
            </Box>
        </li>
    );
};

const TOC = ({ headers, activeId }: { headers: TableOfContentHeader[]; activeId: string }) => {
    const ulRef = createRef<HTMLUListElement>();

    return (
        <ul className={styles['toc-container-inner']} ref={ulRef}>
            {headers
                .filter((header) => header.level <= 3)
                .map((header) => (
                    <TOCItem header={header} key={header.text} activeId={activeId} ulRef={ulRef} />
                ))}
        </ul>
    );
};

const TableOfContent: FC<TableOfContentProps> = ({ headers, pathname }) => {
    const [activeId, setActiveId] = useState<string>(
        headers?.[0] ? slugify(headers[0].text, '') : '',
    );

    useEffect(() => {
        if (!activeId && headers?.length) {
            setActiveId(slugify(headers[0].text, ''));
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveId(entry.target.id);
                    }
                });
            },
            {
                // root: document.querySelector(".exercise-description-parsed"),
                rootMargin: '0px 0px -90% 0px',
                threshold: 1.0,
            },
        );

        headers.forEach((header: any) => {
            const element = document.querySelector(
                `.exercise-description-parsed ${slugify(header.text)}`,
            );
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            headers.forEach((header) => {
                const element = document.querySelector(
                    `.exercise-description-parsed ${slugify(header.text)}`,
                );
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, [activeId, pathname, headers]);

    if (!headers.length) {
        return null;
    }

    return (
        <Card className={styles['toc-container']} style={{ marginBottom: '1rem', padding: '1rem' }}>
            <strong>Table of Contents</strong>

            <TOC headers={headers} activeId={activeId} />
        </Card>
    );
};

export default TableOfContent;
