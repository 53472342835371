import fetchWrapper from '@repo/fetcher';

const getMe = (headers) => {
    return fetchWrapper.get(`/me`, {
        headers,
        cache: 'no-store',
    });
};

const loginUser = (data) => {
    return fetchWrapper.post(`/users/login`, data, { cache: 'no-store' });
};

const registerUser = (data) => {
    return fetchWrapper.post(`/users/register`, data, { cache: 'no-store' });
};

const logoutUser = (userId) => {
    return fetchWrapper.post(`/users/${userId}/logout`, { cache: 'no-store' });
};

const getUserByUsername = (username, config) => {
    return fetchWrapper.get(`/users/${username}`, config);
};

const followUser = (userId, followingUserId) => {
    return fetchWrapper.post(`/users/${userId}/follow/${followingUserId}`);
};

const unfollowUser = (userId, followingUserId) => {
    return fetchWrapper.post(`/users/${userId}/unfollow/${followingUserId}`);
};

const uploadProfileImage = (userId, files) => {
    const data = new FormData();

    for (const file of files) {
        data.append('files', file);
    }

    return fetchWrapper.post(`/users/${userId}/change_profile_image`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

const updateUserProfile = (userId, userData) => fetchWrapper.put(`/users/${userId}`, userData);

const contactUs = (data) => fetchWrapper.post(`/contact_us`, data);

const joinNewsletter = (data) => fetchWrapper.post(`/join_newsletter`, data);

const userService = {
    getMe,
    loginUser,
    registerUser,
    logoutUser,
    getUserByUsername,
    followUser,
    unfollowUser,
    uploadProfileImage,
    updateUserProfile,
    contactUs,
    joinNewsletter,
};

export default userService;
