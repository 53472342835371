import styles from './CircularProgress.module.scss';
import classNames from 'classnames';

type CircularProgressProps = {
    value?: number;
    className?: string;
    loading?: boolean;
    size?: number;
    thickness?: number;
};

const circleRadius = 20.2;

export const CircularProgress: React.FC<CircularProgressProps> = ({
    value: inheritValue = 0,
    className,
    loading,
    size = 40,
    thickness = 3.6,
}) => {
    // Calculate the progress arc
    const value = loading ? 10 : inheritValue;
    const progress = value >= 0 && value <= 100 ? value : 0;
    const circumference = 2 * Math.PI * circleRadius; // Adjusted radius to match the circle's radius
    const offset = ((100 - progress) / 100) * circumference;

    const containerStyles = classNames(styles.progressbarIcon, className);
    const foregroundStyles = classNames(styles.progressForeground, {
        [styles.loading]: loading,
    });

    return (
        <div className={containerStyles}>
            <svg style={{ width: size, height: size }} viewBox="22 22 44 44">
                {/* Background circle */}
                <circle
                    className={styles.progressBackground}
                    cx="44"
                    cy="44"
                    r={circleRadius}
                    strokeWidth={thickness}
                />

                {/* Foreground circle (progress indicator) */}
                <circle
                    className={foregroundStyles}
                    cx="44"
                    cy="44"
                    r={circleRadius}
                    style={{
                        strokeDasharray: `${circumference} ${circumference}`,
                        strokeDashoffset: offset,
                        transformOrigin: 'center',
                    }}
                    strokeWidth={thickness}
                />
            </svg>
        </div>
    );
};

export default CircularProgress;
