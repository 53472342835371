import styles from './TabMenu.module.scss';
import Box from '../../atoms/Box'; // Assuming Box is a styled div component
import classNames from 'classnames';
import Link from '../../atoms/Link';

interface Tab {
    label: string;
    url?: string;
    active?: boolean;
    target?: string;
}

interface TabMenuProps {
    tabs: Tab[];
    pathname: string;
}

const isActiveTab = (tab: Tab, pathname: string): boolean => tab.active || tab.url === pathname;

const TabMenu: React.FC<TabMenuProps> = ({ tabs, pathname }) => {
    return (
        <Box display="flex">
            <div className={styles.tabMenu} aria-label="tabs">
                {tabs.map((tab) => (
                    <Link
                        key={tab.label}
                        to={tab.url || ''}
                        target={tab.target}
                        className={classNames(styles.tabMenuItem, {
                            [styles.activeTab]: isActiveTab(tab, pathname),
                        })}
                        aria-controls={tab.label}
                    >
                        {tab.label}
                    </Link>
                ))}
            </div>
        </Box>
    );
};

export default TabMenu;
