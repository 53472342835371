import Typography from '../../atoms/Typography';
import Modal from '../Modal';

import ImageUploader from '../ImageUploader';

interface IImagePickerModal {
    isModalOpen: boolean;
    onCloseModal: () => void;
    modalTitle?: string;
    aspect?: number;
    // eslint-disable-next-line no-unused-vars
    uploadImage: (_file: any) => void;
}

const ImagePickerModal: React.FC<IImagePickerModal> = ({
    isModalOpen,
    onCloseModal,
    modalTitle = 'Upload a New Profile Image',
    aspect = 1,
    uploadImage,
}) => {
    if (!isModalOpen) return null;

    return (
        <Modal open={isModalOpen} onClose={onCloseModal}>
            <>
                <Typography variant="h5" margin="0 0 1rem 0">
                    {modalTitle}
                </Typography>

                <ImageUploader uploadImage={uploadImage} aspect={aspect} />
            </>
        </Modal>
    );
};

export default ImagePickerModal;
