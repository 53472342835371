import Link from "../../atoms/Link"
import { Card } from "../../atoms/Card"

import Box from "../../atoms/Box"
import Typography from "../../atoms/Typography"

const CategorySidebarCard = ({
  title,
  items = [],
  selector,
  urlPrefix = "",
  urlSuffix = "",
  activeUrl = "",
}) => {
  if (!items.length) return null

  return (
    <Card style={{ marginBottom: "1rem", padding: "1.5rem" }}>
      <Typography fontSize="1.5rem !important">{title}</Typography>

      <Box>
        {items.map(item => {
          const url = `${urlPrefix}/${item.urlSlug}${urlSuffix}`

          return (
            <Box key={item.id} margin="0.5rem 0">
              <Link
                to={url}
                style={{
                  color:
                    activeUrl === item.urlSlug ? "var(--secondary-color)" : "var(--primary-color)",
                }}
              >
                {item[selector]}
              </Link>
            </Box>
          )
        })}
      </Box>
    </Card>
  )
}

export default CategorySidebarCard
