import { HTMLAttributeAnchorTarget } from 'react';
import Link from '../Link';
import styles from './SidebarItem.module.scss';

import classNames from 'classnames';

const SidebarItem = ({
    collapsed,
    linkTo,
    linkTarget,
    isActive = false,
    noBorder,
    icon,
    onClick = () => { },
    name = '',
    disabled = false,
}: {
    collapsed: boolean;
    linkTo?: string;
    linkTarget?: HTMLAttributeAnchorTarget;
    isActive?: boolean;
    noBorder?: boolean;
    icon?: JSX.Element;
    onClick?: () => void;
    name?: string | JSX.Element;
    borderRadius?: boolean;
    disabled?: boolean;
}) => {
    const sidebarItemContainerClass = classNames(styles['sidebar-item-container'], {
        [styles['noBorder']]: noBorder,
    });

    const sidebarItemClass = classNames(styles['sidebar-item'], {
        [styles['isActive']]: isActive,
        [styles['collapsed']]: collapsed,
        [styles['disabled']]: disabled,
    });

    return (
        <div className={sidebarItemContainerClass}>
            <Link className={sidebarItemClass} to={disabled ? undefined : linkTo} onClick={disabled ? undefined : onClick} target={linkTarget}>
                <div className={styles['sidebar-wrapper']}>
                    {!!icon && <div className={styles['sidebar-icon']}>{icon}</div>}

                    <span>{name}</span>
                </div>
            </Link>
        </div>
    );
};

export default SidebarItem;
