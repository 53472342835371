import { toast } from 'react-toastify';

const getJsonData = async (data: any) => {
    try {
        const resp = await data.json();

        return resp;
    } catch (error) {
        return {};
    }
};

const fetchWrapper = {
    async request(
        method: string,
        url: string,
        data = null,
        additionalOptions: any = {},
        formData = false,
        baseUrl?: string,
    ) {
        const baseURL = baseUrl || process.env.NEXT_PUBLIC__BACKEND_URL;
        const fullUrl = `${baseURL}${url}`;

        const headers: any = {
            'X-Requested-With': 'XMLHttpRequest',
        };

        if (!formData) {
            headers['Content-Type'] = 'application/json';
        }

        let body: any = data || null;
        if (!formData && body) {
            body = JSON.stringify(body);
        }

        const options = {
            method,
            ...additionalOptions,
            headers: {
                ...headers,
                ...(additionalOptions.headers || {}),
            },
            credentials: 'include', // for withCredentials: true
            body,
        };

        try {
            const response: any = await Promise.race([
                fetch(fullUrl, options),
                new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 100000)),
            ]);

            if (!response.ok) {
                throw response;
                // return response.json().then(({ error }) => { throw error })
            }

            const resp = await getJsonData(response);
            return { data: { ...resp } };
        } catch (error: any) {
            const unregistredBuyTry = error.status === 401 && url.includes('/buy');

            if (url !== '/me' && !unregistredBuyTry) {
                if (typeof window !== 'undefined') {
                    const errorData = await getJsonData(error);

                    toast.error(errorData?.error?.message);
                }
            }

            return Promise.reject(error);
        }
    },
    // TODO: remove this when mindles ai is not needed anymore
    get(url: string, options?: any, baseUrl?: string) {
        return this.request('GET', url, undefined, options, undefined, baseUrl);
    },

    post(url: string, data?: any, options?: any, formData?: any, baseUrl?: string) {
        return this.request('POST', url, data, options, formData, baseUrl);
    },

    put(url: string, data?: any, options?: any, baseUrl?: string) {
        return this.request('PUT', url, data, options, undefined, baseUrl);
    },

    delete(url: string, data?: any, options?: any, baseUrl?: string) {
        return this.request('DELETE', url, data, options, undefined, baseUrl);
    },
};

export default fetchWrapper;
