import CardWrapper from '../../atoms/CardWrapper';
import FeaturedImage from '../../atoms/FeaturedImage';
import Link from '../../atoms/Link';
import { memo } from 'react';

import Box from '../../atoms/Box';
import Typography from '../../atoms/Typography';

interface IPeopleCard {
    imageSrc: string;
    name: string;
    position: string;
    subtitle: string;
    url: string;
    Image: React.ElementType;
}

const PeopleCard: React.FC<IPeopleCard> = ({ imageSrc, name, position, subtitle, url, Image }) => {
    if (!Image || Image === 'img') {
        throw new Error('PeopleCard: Image props is required');
    }

    return (
        <Link to={url}>
            <CardWrapper style={{ height: '100%' }}>
                <Box position="relative">
                    <FeaturedImage
                        Image={Image}
                        alt={name}
                        src={imageSrc}
                        width={422}
                        height={422}
                    />

                    <Box padding="1rem">
                        <Typography variant="h3" fontSize="2rem !important">
                            {name}
                        </Typography>

                        <Typography fontSize="1.5rem !important">{position}</Typography>

                        <Typography margin="0.5rem 0 0 0">{subtitle}</Typography>
                    </Box>
                </Box>
            </CardWrapper>
        </Link>
    );
};

export default memo(PeopleCard);
