import Box from '../Box';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

import styles from './RatingStars.module.scss';

export default function RatingStars({
    value,
    // edit = false,
    size = 22,
    // onChange,
    count = 5,
}: {
    value: number;
    // edit?: boolean
    size?: number;
    // onChange?: any
    count?: number;
}) {
    const numberValue = +(+value || 0); // 4.57
    const truncValue = Math.trunc(numberValue); // 4
    const roundedValue = Math.round(numberValue); // 5

    return (
        <Box display="flex" className={styles['stars-container']}>
            {Array(count)
                .fill(0)
                .map((_, star) => {
                    const starValue = star + 1;
                    if (starValue <= truncValue) {
                        return <StarIcon key={star} style={{ fontSize: size }} />;
                    }

                    if (starValue === roundedValue) {
                        return <StarHalfIcon key={star} style={{ fontSize: size }} />;
                    }

                    return <StarIcon key={star} style={{ fontSize: size, color: 'gray' }} />;
                })}
        </Box>
    );
}
