import { Container, Link, CatalogMenu, TopMenuDropdown, ResourcesMenu, Logo } from '@repo/ui';
import TopMenuHeight from './TopMenuHeight';
import { resourceMenuItems } from '@/constants/topMenuItems';
import TopMenuDropdownLayout from './TopMenuDropdownLayout';
import styles from './TopMenu.module.scss';

const TopMenu = ({
    hasBanner = false,
    appCategories,
    TopMenuRightSide = () => <></>,
    TopMenuBanner = () => <></>,
    TopMenuLeftSideAddition = () => <></>,
    logoSize = { width: 150, height: 60 },
    Image,
    customLeftSide = undefined,
    companyName,
}) => {
    return (
        <div>
            <div className={styles['top-menu-container']}>
                <TopMenuBanner />

                <div className={styles['top-menu-container-inner']}>
                    <Container className={styles['top-menu-container-content']}>
                        <div className={styles['top-menu-left-side']}>
                            <Link to="/">
                                <Logo
                                    Image={Image}
                                    width={logoSize.width}
                                    height={logoSize.height}
                                    companyName={companyName}
                                />
                            </Link>

                            <div className={styles['left-side-actions']}>
                                {customLeftSide ? (
                                    customLeftSide
                                ) : (
                                    <>
                                        <TopMenuDropdown
                                            placeholder={'Catalog'}
                                            dropdownMenuContent={
                                                <TopMenuDropdownLayout title="Catalog topics">
                                                    <CatalogMenu categories={appCategories} />
                                                </TopMenuDropdownLayout>
                                            }
                                        />

                                        <TopMenuDropdown
                                            placeholder={'Resources'}
                                            dropdownMenuContent={
                                                <TopMenuDropdownLayout title="Learning & practice">
                                                    <ResourcesMenu
                                                        resourceMenuItems={resourceMenuItems}
                                                    />
                                                </TopMenuDropdownLayout>
                                            }
                                        />
                                    </>
                                )}

                                <TopMenuLeftSideAddition />
                            </div>
                        </div>

                        <TopMenuRightSide />
                    </Container>
                </div>
            </div>

            <TopMenuHeight hasBanner={hasBanner} />
        </div>
    );
};

export default TopMenu;
