import ReactMarkdown from 'react-markdown';
import { useMemo } from 'react';
import rehypeRaw from 'rehype-raw';
import { CodeBlock, Link, Typography } from '@repo/ui';
import { slugify } from '@repo/utils/stringUtils';
import styles from './LectureDescription.module.scss';

function getChildrenText(children) {
    let label = '';

    if (typeof children !== 'string') {
        if (Array.isArray(children)) {
            children.forEach((child) => {
                label += getChildrenText(child);
            });
        } else {
            label += getChildrenText(children?.props?.children || '');
        }
    } else {
        label += children;
    }

    return label;
}

const Heading = ({ node: { tagName: Tag }, children, headerLinks }) => {
    const text = getChildrenText(children);
    const slug = slugify(text, '');

    const Header = () => <Tag id={slug}>{children}</Tag>;

    if (headerLinks) {
        return <Header />;
    }

    return <Header />;
};

const doFollowDomains = [
    'skylead.io',
    'code-hl.com',
    'yohdev.com',
    'codeinjs.net',
    'blogtube.in',
    'vioend.com',
    'https://www.instagram.com/code.highlights',
    'https://www.youtube.com/@codehighlights',
    'codeology.netlify.app',
    'codeology.net',
];

const CustomLink = ({ children, href }) => {
    const isInternal = href[0] === '/' || href.includes('code-hl.com');
    // extarnal link
    if (!isInternal) {
        const doFollow = doFollowDomains.find((domain) => href.includes(domain));
        const rel = doFollow ? 'noopener' : 'nofollow noopener noreferrer';

        return (
            <Link to={href} target="_blank" rel={rel}>
                {children}
            </Link>
        );
    }

    const internalLink = href.includes('?source') ? href : `${href}?source=blog`;

    // internal link
    return <Link to={internalLink}>{children}</Link>;
};

const CustomImage =
    (Image) =>
        ({ src, alt, title }) => {
            return (
                <Image
                    src={src}
                    alt={alt}
                    title={title || alt}
                    width={998}
                    height={749}
                    quality={100}
                    style={{ width: '100%', height: 'auto' }}
                />
            );
        };

const DefaultContentRendered = ({ children }) => <div>{children}</div>;

const MarkdownCodeComponent = ({ className = '', children, simple }) => {
    const splittedClassName = className.split('language-')[1];
    if (!splittedClassName) {
        return (
            // <span className="highlighted-code">
            <span className={styles['code']}>
                <code>{children}</code>
            </span>
        );
    }

    const titleStartsAt = splittedClassName?.indexOf('-');
    const title =
        titleStartsAt !== -1
            ? splittedClassName?.slice(titleStartsAt + 1).replaceAll('-', ' ')
            : '';
    const language =
        titleStartsAt !== -1 ? splittedClassName?.slice(0, titleStartsAt) : splittedClassName;
    const code = String(children).replace(/\n$/, '');

    return (
        <CodeBlock
            simple={simple}
            code={code}
            language={language}
            className={`language-${language}`}
            title={title}
        />
    );
};

const PreComp = ({ children }) => {
    return children;
};

const paragraph = ({ children }) => {
    return <Typography>{children}</Typography>;
};

const MarkdownComponents = (Image, headerLinks, simple) => {
    const Headers = (props) => <Heading {...props} headerLinks={headerLinks} />;
    const Code = (props) => <MarkdownCodeComponent {...props} simple={simple} />;

    return {
        pre: PreComp,
        code: Code,
        a: CustomLink,
        h1: Headers,
        h2: Headers,
        h3: Headers,
        h4: Headers,
        h5: Headers,
        h6: Headers,
        p: paragraph,
        img: CustomImage(Image),
    };
};

const MarkdownPreview = ({ Image, text, headerLinks, simple }) => {
    const allComponents = useMemo(() => {
        return MarkdownComponents(Image, headerLinks, simple);
    }, [headerLinks, simple]);

    if (typeof text !== 'string') {
        return text;
    }

    return (
        <ReactMarkdown
            // rehypeRaw enables HTML in markdown
            rehypePlugins={[rehypeRaw]}
            className={styles.markdown}
            components={allComponents}
        >
            {text}
        </ReactMarkdown>
    );
};

const LectureDescription = ({
    text,
    title = '',
    subtitle = '',
    headerLinks = false,
    ContentRendered = DefaultContentRendered,
    simple = true,
    Image,
}) => {
    return (
        <div className={`exercise-description-parsed ${styles['exercise-description-parsed']}`}>
            {(title || subtitle) && (
                <div className={styles['exercise-description-parsed-titles']}>
                    {subtitle && (
                        <Typography
                            className={styles['exercise-description-parsed-titles-subtitle']}
                        >
                            {subtitle}
                        </Typography>
                    )}

                    {title && (
                        <Typography variant="h1" fontSize="3rem !important">
                            {title}
                        </Typography>
                    )}
                </div>
            )}

            <ContentRendered>
                <MarkdownPreview
                    Image={Image}
                    text={text}
                    headerLinks={headerLinks}
                    simple={simple}
                />
            </ContentRendered>
        </div>
    );
};

export default LectureDescription;
