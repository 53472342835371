import { calculateReadingTime, getArticlePrefix, getFeaturedImage } from './article.utils';

export interface BreadcrumbObj {
    name: string;
    clickable: boolean;
    url: string;
}

export enum SEO_OBJECT_INPUT_TYPE {
    // eslint-disable-next-line no-unused-vars
    WEBSITE = 'website',
    // eslint-disable-next-line no-unused-vars
    ARTICLE = 'article',
    // eslint-disable-next-line no-unused-vars
    PROFILE = 'profile',
}

export interface BlogAuthor {
    name: string;
    image: string;
}

export interface SeoObjectInputs {
    title?: string;
    description?: string;
    type?: SEO_OBJECT_INPUT_TYPE;
    author: BlogAuthor;
    timeToRead?: string;
    articleTags?: string[];
    articleSection?: string;
    userFirstName?: string;
    userLastName?: string;
    userUsername?: string;
    jsonLd?: any;
    pageUrl?: string;
    imageUrl?: string;
    imageWidth?: number;
    imageHeight?: number;
    createdAt?: string;
    updatedAt?: string;
    canonical?: string;
    noIndex?: boolean;
}

export type SeoObjectOutput = SeoObjectInputs & {
    breadcrumbList: BreadcrumbObj[];
};

export const getProfileSeoData = (defaultSeo: any, user: any): Partial<SeoObjectOutput> => {
    const profilePicture = user?.profilePicture || `${defaultSeo.pageUrl}/default_avatar.png`;
    const [userFirstName, userLastName] = (user?.fullName || '').split(' ');

    return {
        ...user,
        profilePicture,
        title: `${user.fullName}'s Profile`,
        description: user.bio,
        imageUrl: profilePicture,
        imageWidth: 500,
        imageHeight: 500,
        noIndex: true,
        type: SEO_OBJECT_INPUT_TYPE.PROFILE,
        userFirstName,
        userLastName,
        userUsername: user.username,
    };
};

export const getArticleCategory = (article: any) => {
    return (
        article?.categoryLanguages?.[0]?.categoryLanguage ||
        article?.categorySubjects?.[0]?.categorySubject
    )?.urlSlug;
};

export const getArticleUrl = (article: any) => {
    const prefix = getArticlePrefix(article.type);
    const categoryPrefix = getArticleCategory(article);
    const realPrefix = categoryPrefix ? `${categoryPrefix}/${prefix}` : prefix;
    const url = `/${realPrefix}/${article.urlSlug}`;

    return url;
};

const defaultAuthor = {
    name: 'Code Highlights Team',
    image: 'https://static.code-hl.com/logo192.png',
};
export const getArticleSeoData = (activeArticle: any, author: BlogAuthor = defaultAuthor) => {
    const timeToRead = calculateReadingTime(activeArticle.content);

    const featuredImage = getFeaturedImage(activeArticle);

    const articleSubject = activeArticle?.categorySubjects?.[0]?.categorySubject?.urlSlug;
    const articleLanguage = activeArticle?.categoryLanguages?.[0]?.categoryLanguage?.urlSlug;
    const articleTags = [];
    if (articleSubject) {
        articleTags.push(articleSubject);
    }

    if (articleLanguage) {
        articleTags.push(articleLanguage);
    }

    const canonical = getArticleUrl(activeArticle);

    const seoData: Partial<SeoObjectInputs> = {
        ...activeArticle.seoMetadata,
        imageUrl: featuredImage,
        imageWidth: '1200',
        imageHeight: '900',
        canonical,
        type: SEO_OBJECT_INPUT_TYPE.ARTICLE,
        author,
        timeToRead,
        articleTags,
        articleSection: articleSubject,
        createdAt: activeArticle.createdAt,
        updatedAt: activeArticle.updatedAt,
    };

    return { seoData, articleSubject, articleLanguage };
};

export const getWebsiteData = async (url: string, itemKey = 'items') => {
    try {
        if (!url) {
            return [];
        }

        const request = await fetch(url);
        const items: any[] = (await request.json()).result[itemKey];

        return items.filter((item) => !item.draft);
    } catch (error) {
        return [];
    }
};

import type { ArticlePrefix } from '@repo/utils/articleUtils';
import { generateBreadcrumbs } from '@repo/utils/breadcrumbUtils';
import type { PageProps, PagePropsParams } from '@repo/utils/nextPagesUtils';

export const getSeoMetaObject = async (
    defaultSeo: any,
    seoData: Partial<SeoObjectInputs>,
    browserUrl?: string,
    addTitleSuffix = false,
): Promise<SeoObjectOutput> => {
    const titleSuffix = addTitleSuffix ? ` | ${defaultSeo.name}` : '';
    const title = `${(seoData.title || defaultSeo.title).replace(` | ${defaultSeo.name}`, '')}${titleSuffix}`;

    const description = seoData.description || defaultSeo.description;

    const pageUrl = browserUrl || defaultSeo.pageUrl || '';

    const imageUrl = seoData.imageUrl || defaultSeo.imageUrl;
    const imageWidth = seoData.imageWidth || defaultSeo.imageWidth;
    const imageHeight = seoData.imageHeight || defaultSeo.imageHeight;
    const type = seoData.type || SEO_OBJECT_INPUT_TYPE.WEBSITE;
    const articleTags = seoData.articleTags || [];
    const articleSection = seoData.articleSection || '';
    const noIndex = seoData.noIndex || false;
    const canonical = seoData.canonical || pageUrl;
    const author = seoData.author || defaultAuthor;

    return {
        ...seoData,
        title,
        description,
        imageUrl,
        breadcrumbList: generateBreadcrumbs(defaultSeo, pageUrl.replace(defaultSeo.pageUrl, '')),
        pageUrl,
        imageWidth,
        imageHeight,
        type,
        articleTags,
        articleSection,
        noIndex,
        canonical,
        author,
    };
};

export const getAppSeoMetadata = async (
    defaultSeo: any,
    seoData: Partial<SeoObjectInputs> = {},
    browserUrl?: string,
): Promise<any> => {
    const seoMeta = await getSeoMetaObject(defaultSeo, { ...seoData }, browserUrl, true);
    const images = [];
    if (seoMeta.imageUrl) {
        images.push({
            url: seoMeta.imageUrl,
            width: seoMeta.imageWidth,
            height: seoMeta.imageHeight,
            alt: seoMeta.title,
        });
    }

    // {/* Schema.org markup for Google+ */}
    // <meta content={title} itemProp="name" />
    // <meta content={description} itemProp="description" />
    // {!!imageUrl && <meta itemProp="image" content={imageUrl} />}

    const other: any = {
        'msapplication-TileColor': '#da532c',
    };

    if (seoMeta.author) {
        other['twitter:label1'] = 'Written by';
        other['twitter:data1'] = seoMeta.author.name;
    }

    if (seoMeta.timeToRead) {
        other['twitter:label2'] = 'Time to read';
        other['twitter:data2'] = seoMeta.timeToRead;
    }

    // {type === "profile" && (
    //   <>
    //     {userFirstName && <meta property="profile:first_name" content={userFirstName} />}
    //     {userLastName && <meta property="profile:last_name" content={userLastName} />}
    //     {userUsername && <meta property="profile:username" content={userUsername} />}
    //   </>
    // )}

    return {
        title: seoMeta.title,
        description: seoMeta.description,
        applicationName: defaultSeo.name,
        keywords: seoMeta.articleTags,
        authors: [{ name: seoMeta.author.name }],
        publisher: defaultSeo.name,
        metadataBase: new URL(defaultSeo.pageUrl),
        alternates: {
            canonical: seoMeta.canonical,
            types: {
                'application/rss+xml': defaultSeo.rssFeed || [],
            },
        },
        openGraph: {
            title: seoMeta.title,
            description: seoMeta.description,
            url: seoMeta.canonical,
            siteName: defaultSeo.name,
            images,
            locale: 'en_US',
            type: seoMeta.type,
            publishedTime: seoMeta.createdAt,
            authors: [seoMeta.author.name],
            // updated_time: updatedAt,
        },
        robots: {
            index: !seoMeta.noIndex,
            follow: !seoMeta.noIndex,
            googleBot: {
                index: !seoMeta.noIndex,
                follow: !seoMeta.noIndex,
                'max-video-preview': -1,
                'max-image-preview': 'large',
                'max-snippet': -1,
            },
        },
        icons: defaultSeo.favicons,
        manifest: '/site.webmanifest',
        twitter: defaultSeo.twitter
            ? {
                  site: defaultSeo.twitter,
                  card: 'summary_large_image',
                  title: seoMeta.title,
                  description: seoMeta.description,
                  images,
              }
            : undefined,
        category: defaultSeo.category,
        other,
    };
};

export const getCategorySlugs = (pageProps: PageProps, categories: any) => {
    if (pageProps.params.category) {
        const { category } = pageProps.params;
        let language;
        if (categories.languages) {
            language = categories.languages.find(
                (language: any) => language.urlSlug === category,
            )?.urlSlug;
        }

        let subject;
        if (categories.subjects) {
            subject = categories.subjects.find(
                (subject: any) => subject.urlSlug === category,
            )?.urlSlug;
        }

        return {
            language,
            subject,
        };
    }

    return {
        language: pageProps.params.language,
        subject: pageProps.params.subject,
    };
};

export const getArticlesCategorySeoData = (
    appCategories: any,
    pagePropsParams: PagePropsParams,
) => {
    const { language, subject } = getCategorySlugs(
        {
            params: pagePropsParams,
            searchParams: undefined,
        },
        appCategories,
    );
    const { languages, subjects } = appCategories || {};

    let selector;
    if (language) {
        selector = 'language';
    } else if (subject) {
        selector = 'subject';
    }

    let selectedValue: {
        title?: string;
        description?: string;
        imageUrl?: string;
        imageHeight: number;
        imageWidth: number;
        urlSlug?: string;
    } = {
        imageUrl: '/logo192.png',
        imageHeight: 150,
        imageWidth: 150,
    };

    if (selector) {
        const data = selector === 'language' ? languages : subjects;
        const dataCompare = selector === 'language' ? language : subject;

        if (!data) {
            return selectedValue;
        }

        const value: any = data.find((dataValue: any) => dataValue.urlSlug === dataCompare) || {
            [selector]: '',
            description: '',
            imageUrl: '/logo192.png',
        };

        selectedValue = { ...selectedValue, ...value };
        selectedValue.title = value[selector];
        selectedValue.description = value.description;
        selectedValue.imageUrl = value.imageUrl;
    }

    return selectedValue;
};

export const getArticlesListCleanSeoData = (
    appCategories: any,
    articlePrefix: ArticlePrefix,
    pageProps: PageProps,
) => {
    const selectedValue = getArticlesCategorySeoData(appCategories, pageProps.params);

    const textType = `${articlePrefix.at(0)?.toUpperCase()}${articlePrefix.slice(1)}`;
    const description =
        articlePrefix === 'blog'
            ? 'Blog posts are more in-depth explanations about concepts covered in Code Highlights courses.'
            : 'Tutorials are more in-depth explanations about concepts covered in Code Highlights courses.';

    const seoData: Partial<SeoObjectInputs> = {
        ...selectedValue,
        title: `${selectedValue.title || ''} ${textType}`.trim(),
        description: (selectedValue.description || '').trim() || description,
        canonical: selectedValue.urlSlug
            ? `/${selectedValue.urlSlug}/${articlePrefix}`
            : `/${articlePrefix}`,
    };

    return seoData;
};

export const getArticlesListPageSeo = (
    appCategories: any,
    defaultSeo: any,
    articlePrefix: ArticlePrefix,
    pageProps: PageProps,
    browserUrl?: string,
) => {
    return getAppSeoMetadata(
        defaultSeo,
        getArticlesListCleanSeoData(appCategories, articlePrefix, pageProps),
        browserUrl,
    );
};
