'use client';

import { useState } from 'react';
import classNames from 'classnames';
import styles from './Switch.module.scss';

interface SwitchProps {
    defaultChecked: boolean;
    name: string;
    id: string;
    label?: string;
}

const Switch: React.FC<SwitchProps> = ({ defaultChecked, name, id, label }) => {
    const [isChecked, setIsChecked] = useState(defaultChecked);

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            handleChange();
        }
    };

    return (
        <div className={styles.switchContainer}>
            <div
                className={classNames(styles.switch, {
                    [styles['switch--checked']]: isChecked,
                })}
                role="button"
                tabIndex={0}
                onClick={handleChange}
                onKeyDown={handleKeyDown}
            >
                <div className={styles.track}>
                    <div className={styles.thumb} />
                </div>
                <input type="checkbox" name={name} id={id} defaultChecked={isChecked} />
            </div>

            {label && <label htmlFor={id}>{label}</label>}
        </div>
    );
};

export default Switch;
