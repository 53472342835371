import classNames from 'classnames';
import styles from './Avatar.module.scss';

interface AvatarProps {
    src?: string;
    alt?: string;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    width?: number;
    height?: number;
    Component?: React.ElementType;
}

export const Avatar: React.FC<AvatarProps> = ({
    src,
    alt = 'image alt',
    size = 'medium',
    className,
    width = 48,
    height = 48,
    Component = 'img',
}) => {
    const avatarClass = classNames(styles.avatar, styles[size], className);

    if (!src) {
        return (
            <Component
                className={avatarClass}
                src="https://static.code-hl.com/resources/default-avatar.jpg"
                alt={alt}
                width={width}
                height={height}
            />
        );
    }

    return <Component className={avatarClass} src={src} alt={alt} width={width} height={height} />;
};

export default Avatar;
