'use client';

import { useState } from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import debounce from '@mui/material/utils/debounce';
import styles from './BlogEditor.module.scss';
import Spinner from '../../atoms/Spinner';
import classNames from 'classnames';

const editorConfig: IAllProps['init'] = {
    menubar: false,
    branding: false,
    highlight_on_focus: false,
    plugins: [
        'autoresize',
        'wordcount',
        'link',
        'lists',
        'media',
        'table',
        'image',
        'imagetools',
        'code',
        'codesample',
        'fullscreen',
        'preview',
        'searchreplace',
        'visualblocks',
        'visualchars',
        'insertdatetime',
        // 'mediaembed', // premium
        'nonbreaking',
        'pagebreak',
        'paste',
        'tabfocus',
        'textpattern',
        'toc',
        'advlist',
        'anchor',
        'autolink',
        'autosave',
        'charmap',
        'directionality',
        'emoticons',
        'fullpage',
        'hr',
        'insertdatetime',
        'print',
        'save',
        'spellchecker',
        'stickytoolbar',
        'template',
        'visualchars',
        'visualblocks',
    ],
    remove_script_host: false,
    document_base_url: 'https://mindlesswriter.com',
    relative_urls: false,
    setup: (editor) => {
        editor.ui.registry.addContextToolbar('imagealignment', {
            predicate: (node) => node.nodeName.toLowerCase() === 'img',
            items: 'alignleft aligncenter alignright | image',
            position: 'node',
            scope: 'node',
        });

        editor.ui.registry.addContextToolbar('textselection', {
            // eslint-disable-next-line no-unused-vars
            predicate: (_node) => !editor.selection.isCollapsed(),
            items: 'bold italic underline strikethrough | blockquote | link | forecolor backcolor | removeformat',
            position: 'selection',
            scope: 'node',
        });
    },
    content_css: ['https://static.code-hl.com/tinymce/content.min.css'],
    toolbar:
        'undo redo | styles | bold italic underline strikethrough removeformat | align | bullist numlist | forecolor backcolor | image media link blockquote code  table | emoticons',
};

export default function BlogEditor({
    preview,
    initialValue,
    onChange,
}: {
    preview?: boolean;
    initialValue: string;
    // eslint-disable-next-line no-unused-vars
    onChange: (_value: any) => void;
}) {
    const [value, setValue] = useState(initialValue);

    // Change the function so that it stores in the state and then calls the onChange function debounced
    const onChangeHandlerDebounce = (content: string) => {
        // Don't update the value if it's in preview mode
        if (preview) return;

        setValue(content);

        onChangeDebounce(content);
    };

    const onChangeDebounce = debounce((content) => {
        onChange(content);
    }, 500);

    const [loading, setLoading] = useState(true);

    const editorClass = classNames(styles.editor, {
        [styles['editor--preview']]: preview,
    });
    return (
        <div className={editorClass}>
            <div className={styles.stickyToolbalMock}>
                <div className={styles.stickyToolbalMockInner}></div>
            </div>

            <div className={styles.editorWrapper}>
                <Editor
                    disabled={preview}
                    apiKey={
                        process.env.NEXT_PUBLIC__TINY_MCE_API_KEY ||
                        'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'
                    }
                    onEditorChange={onChangeHandlerDebounce}
                    value={value}
                    init={editorConfig}
                    onLoadContent={() => {
                        setLoading(false);
                    }}
                />

                {loading && (
                    <div className={styles.loading}>
                        <Spinner />
                    </div>
                )}
            </div>
            <div className={styles.stickyStatusbarMock}>
                <div className={styles.stickyStatusbarMockInner}></div>
            </div>
        </div>
    );
}
