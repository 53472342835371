'use client';

import { useEffect, useMemo, useState } from 'react';
import { ISERPSEOChecklist } from '../components/organisms/SERPSEOChecklist/types';
import { debounce } from '@mui/material';

export default (
    projectId: string,
    contentId: string,
    { content, status, description, keyword, title, url, urlSlug, imageUrl }: ISERPSEOChecklist,
    readyToSave?: boolean,
    // eslint-disable-next-line no-unused-vars
    saveContentEndpoint?: (projectId: string, contentId: string, data: any) => any,
    // eslint-disable-next-line no-unused-vars
    saveContentFe?: (data: any) => any,
) => {
    const [loading, setLoading] = useState(false);
    const calculateDebounced = useMemo(() => {
        return debounce(async () => {
            setLoading(true);

            if (saveContentFe && saveContentEndpoint && !loading) {
                const newContentData = {
                    content,
                    status,
                    description,
                    focusKeyword: keyword,
                    title,
                    url,
                    urlSlug,
                    imageUrl,
                };
                const blog = await saveContentEndpoint(projectId, contentId, newContentData);

                await saveContentFe(blog);

                setLoading(false);
            }
        }, 1000);
    }, [content, status, description, keyword, title, url, urlSlug, saveContentFe]);

    useEffect(() => {
        if (!readyToSave) {
            return;
        }

        calculateDebounced();

        return () => {
            calculateDebounced.clear();
        };
    }, [content, status, description, keyword, title, url, urlSlug, readyToSave]);
};
