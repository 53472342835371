import styles from './LoadingPage.module.scss';

import Spinner from '../../atoms/Spinner';
import Logo from '../../atoms/Logo';
import PublicLayout from '../../layouts/PublicLayout';

const LoadingPage = ({ Image, companyName }) => {
    return (
        <div className={styles['loading-page']}>
            <Logo Image={Image} companyName={companyName} />
            <Spinner />
        </div>
    );
};

LoadingPage.getLayout = function getLayout(page) {
    return <PublicLayout>{page}</PublicLayout>;
};

export default LoadingPage;
