import { CSSProperties } from 'react';

export type muiStyleProps = {
    margin?: CSSProperties['margin'];
    padding?: CSSProperties['padding'];
    display?: CSSProperties['display'];
    flexDirection?: CSSProperties['flexDirection'];
    alignItems?: CSSProperties['alignItems'];
    justifyContent?: CSSProperties['justifyContent'];
    position?: CSSProperties['position'];
    top?: CSSProperties['top'];
    right?: CSSProperties['right'];
    bottom?: CSSProperties['bottom'];
    left?: CSSProperties['left'];
    width?: CSSProperties['width'];
    height?: CSSProperties['height'];
    backgroundColor?: CSSProperties['backgroundColor'];
    textAlign?: CSSProperties['textAlign'];
    flex?: CSSProperties['flex'];
    color?: CSSProperties['color'];
    fontSize?: CSSProperties['fontSize'];
    fontWeight?: CSSProperties['fontWeight'];
    style?: CSSProperties;
};

export const getComponentProps = (props: any) => {
    const {
        margin,
        padding,
        display,
        flexDirection,
        alignItems,
        justifyContent,
        position,
        top,
        right,
        bottom,
        left,
        width,
        height,
        backgroundColor,
        textAlign,
        flex,
        color,
        fontSize,
        fontWeight,
        style,
        ...rest
    } = props;

    const mainStyle: muiStyleProps = {
        margin,
        padding,
        display,
        flexDirection,
        alignItems,
        justifyContent,
        position,
        top,
        right,
        bottom,
        left,
        width,
        height,
        backgroundColor,
        textAlign,
        flex,
        color,
        fontSize,
        fontWeight,
    };
    Object.keys(mainStyle).forEach((key: string) => {
        if (mainStyle[key as keyof muiStyleProps] === undefined) {
            delete mainStyle[key as keyof muiStyleProps];
        }
    });

    return {
        style: { ...(style || {}), ...mainStyle },
        rest,
    };
};
