import Box from "../../atoms/Box"
import CategorySidebarCard from "../CategorySidebarCard"
import classNames from "classnames"
import styles from "./PlatformCategoriesSidebar.module.scss"

const PlatformCategoriesSidebar = ({
  languages,
  subjects,
  language,
  subject,
  prefix = "catalog",
  sticky = false,
  hideMobile,
}) => {
  const stickyData = sticky
    ? {
        position: "sticky",
        top: 90,
      }
    : {}

  const categoriesClassNames = classNames(styles["blog-categories-sidebar-wrapper"], {
    [styles["blog-categories-sidebar-wrapper-hide-mobile"]]: hideMobile,
  })

  const urlSuffix = prefix != "catalog" ? `/${prefix}` : ""
  return (
    <Box className={categoriesClassNames} position={stickyData.position} top={stickyData.top}>
      <CategorySidebarCard
        title="Languages"
        items={languages}
        selector="language"
        urlSuffix={urlSuffix}
        activeUrl={language}
      />

      <CategorySidebarCard
        title="Subjects"
        items={subjects}
        selector="subject"
        urlSuffix={urlSuffix}
        activeUrl={subject}
      />
    </Box>
  )
}

export default PlatformCategoriesSidebar
