import Typography from '../Typography';
import Link from '../Link';

export const Copyright: React.FC<any> = ({
    pageUrl,
    companyName,
}: {
    pageUrl: string;
    companyName: string;
}) => {
    return (
        <Typography variant="body2" color="#ffffff" align="center">
            {'Copyright © '}
            <Link to={pageUrl}>{companyName}</Link> {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

export default Copyright;
