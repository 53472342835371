import Box from '../../atoms/Box';

import ArticleCategoryTag from '../../atoms/ArticleCategoryTag';
import { ArticlePrefix } from '@repo/utils/articleUtils';

const AllArticleCategoryTags = ({
    article,
    prefix,
    noLink,
}: {
    article: any;
    prefix: ArticlePrefix;
    noLink?: boolean;
}) => {
    return (
        <Box>
            {article?.categoryLanguages?.map(({ categoryLanguage }: any) => (
                <ArticleCategoryTag
                    key={categoryLanguage.id}
                    id={categoryLanguage.id}
                    tag={categoryLanguage.language}
                    urlSlug={`/${categoryLanguage.urlSlug}/${prefix}`}
                    noLink={noLink}
                />
            ))}

            {article?.categorySubjects?.map(({ categorySubject }: any) => (
                <ArticleCategoryTag
                    key={categorySubject.id}
                    id={categorySubject.id}
                    tag={categorySubject.subject}
                    urlSlug={`/${categorySubject.urlSlug}/${prefix}`}
                    noLink={noLink}
                />
            ))}
        </Box>
    );
};

export default AllArticleCategoryTags;
