"use client";

import { Suspense, useState } from "react";
import { Box, Typography, LoginForm, RegisterForm } from "@repo/ui";

const AuthenticationForm = ({ callback }) => {
  const user = {};
  const loggedIn = Boolean(user.id);

  const [userAction, setUserAction] = useState("register");

  if (loggedIn) {
    return null;
  }

  return (
    <Box>
      <Typography textAlign="center" margin="0 0 0.5rem 0">
        {userAction === "register" ? "Sign up to start coding" : "Login"}
      </Typography>

      {userAction === "register" ? (
        <>
          <RegisterForm autoFocus={false} withoutReload callback={callback} />

          <div
            role="button"
            onClick={() => {
              setUserAction("login");
            }}
            aria-label="Already have an account? Sign In"
          >
            <Box display="flex" justifyContent="center">
              <Typography
                color="GrayText"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                display="flex"
                style={{ cursor: "pointer" }}
              >
                Already have an account?{" "}
              </Typography>

              <Typography color="secondary" margin="0 0 0 0.5rem">
                Sign In
              </Typography>
            </Box>
          </div>
        </>
      ) : (
        <>
          <Suspense>
            <LoginForm autoFocus={false} withoutReload callback={callback} />
          </Suspense>

          <div
            role="button"
            onClick={() => {
              setUserAction("register");
            }}
            aria-label="Don't have an account? Sign Up"
          >
            <Box display="flex" justifyContent="center">
              <Typography
                color="GrayText"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                display="flex"
                style={{ cursor: "pointer" }}
              >
                Don&apos;t have an account?{" "}
              </Typography>

              <Typography color="secondary" margin="0 0 0 0.5rem">
                Sign Up
              </Typography>
            </Box>
          </div>
        </>
      )}
    </Box>
  );
};

export default AuthenticationForm;
