import { getPropByString } from '@repo/utils/stringUtils';
import classNames from 'classnames';

import styles from './DataTable.module.scss';

type column = {
    name: string;
    key: string;
    // eslint-disable-next-line no-unused-vars
    component?: (row: any, value: any) => any;
    width?: string | number;
};

type DataTableProps = {
    columns: column[];
    data: any[];
    // eslint-disable-next-line no-unused-vars
    onRowClick?: (row: any) => void;
};

const DataTable = ({ columns, data = [], onRowClick }: DataTableProps) => {
    return (
        <table className={styles.table}>
            <thead className={styles.head}>
                <tr className={styles.headerRow}>
                    {columns.map(({ name }, index) => (
                        <th key={index} className={styles.headerRowCell} align="left">
                            {name}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody className={styles.body}>
                {data.map((row) => {
                    const rowClass = classNames(styles.bodyRow, {
                        [styles.clickable]: onRowClick,
                    });

                    return (
                        <tr
                            key={row.id}
                            className={rowClass}
                            onClick={() => {
                                if (onRowClick) {
                                    onRowClick(row);
                                }
                            }}
                        >
                            {columns.map(({ width, key, component = (_: any, v: any) => v }) => (
                                <td key={key} className={styles.bodyCell} width={width}>
                                    {component(row, getPropByString(row, key))}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default DataTable;
