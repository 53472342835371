'use client';

import Typography from '../Typography';
import { useEffect } from 'react';

export const DynamicTitle = ({ prefix, titles }: any) => {
    useEffect(() => {
        // Target the element where the title will be displayed
        const titleElement = document.getElementById('dynamic-title');
        let titleIndex = 0; // To keep track of the current title
        let letterIndex = titles[0].length; // To keep track of the current letter in the title
        let deleting = false; // To keep track if we are in "delete" mode
        let isPaused = false; // To handle pause state

        // Function to update the title
        async function updateTitle(titlesPrefix: any, titles: any) {
            let currentTitle = titles[titleIndex];
            if (isPaused) {
                return;
            }
            if (!deleting) {
                // Typing phase
                if (letterIndex <= currentTitle.length) {
                    const word = currentTitle.substr(0, letterIndex);
                    if (titleElement) {
                        titleElement.textContent = `${titlesPrefix}${word}|`;
                    }
                    letterIndex++;
                } else {
                    await pauseAndUpdateCursor(titlesPrefix, titles, 1000, 2);
                    deleting = true;
                }
                setTimeout(() => updateTitle(titlesPrefix, titles), 150); // Slower when typing
            } else {
                // Deleting phase
                if (letterIndex > 0) {
                    letterIndex--;
                    const word = currentTitle.substr(0, letterIndex);
                    if (titleElement) {
                        titleElement.textContent = `${titlesPrefix}${word}|`;
                    }
                } else {
                    await pauseAndUpdateCursor(titlesPrefix, titles, 1000, 1);
                    deleting = false;
                    titleIndex = (titleIndex + 1) % titles.length; // Move to the next title, loop back to the first if needed
                }
                setTimeout(() => updateTitle(titlesPrefix, titles), 50); // Faster when deleting
            }
        }

        // Function to introduce a pause and blink cursor
        function pauseAndUpdateCursor(titlesPrefix: any, titles: any, time: any, blinks: any) {
            return new Promise((resolve) => {
                isPaused = true;
                let blinkCount = 0;
                const blinkInterval = setInterval(() => {
                    const word = titles[titleIndex].substr(0, letterIndex);
                    if (titleElement) {
                        titleElement.textContent =
                            blinkCount % 2 === 0
                                ? `${titlesPrefix}${word} `
                                : `${titlesPrefix}${word}|`;
                    }
                    blinkCount++;
                    if (blinkCount >= blinks * 2) {
                        clearInterval(blinkInterval);
                        isPaused = false;
                        resolve(true);
                    }
                }, 400); // Slower blinking
            });
        }

        updateTitle(prefix, titles);
    }, [prefix, titles]);

    return (
        <Typography variant="h1" fontWeight="bold" id="dynamic-title">
            {`${prefix}${titles[0]}`}
        </Typography>
    );
};

export default DynamicTitle;
