import type { FullWidthLayoutProps } from '@repo/ui';
import { Container } from '@repo/ui';
import FullWidthLayout from '../FullWidthLayout';

const RegularLayout = ({
    defaultSeo,
    appCategories,
    footerLinks,
    seoData = {},
    Menu = () => <></>,
    children,
    loading = false,
    headerSection,
    HeaderSeparator,
    jsonLDElementParams,
    noHeader = false,
    noBreadcrumbs = false,
    Image,
    pathname,
    searchParams,
}: FullWidthLayoutProps) => {
    return (
        <FullWidthLayout
            Image={Image}
            Menu={Menu}
            defaultSeo={defaultSeo}
            appCategories={appCategories}
            footerLinks={footerLinks}
            noHeader={noHeader}
            seoData={seoData}
            jsonLDElementParams={jsonLDElementParams}
            loading={loading}
            headerSection={headerSection}
            HeaderSeparator={HeaderSeparator}
            noBreadcrumbs={noBreadcrumbs}
            pathname={pathname}
            searchParams={searchParams}
        >
            <Container style={{ padding: '4rem 0', width: '100%' }}>{children}</Container>
        </FullWidthLayout>
    );
};

export default RegularLayout;
