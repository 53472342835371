import type { CourseUserAccessProps } from '../course/courseStore';

export interface UserStoreProps {
    id: number;
    fullName?: string;
    userTypeId: number;
    links?: string[];
    email?: string;
    bio?: string;
    username?: string;
    followers?: number;
    following?: number;
    fake?: boolean;
    phone?: string | null;
    profilePicture?: string;
    emailVerified?: boolean;
    createdAt?: string;
    updatedAt?: string;
    courseUserAccess?: CourseUserAccessProps[];
    userAchivement?: {
        dailyXp: number;
        totalXp: number;
        currentStreakDays: number;
    };
    userGoal: {};
    paymentStatus?: string;
}

export interface UserStoreContextState {
    user: UserStoreProps;

    setUserState: (user: UserStoreProps) => void;
}

export const defaultUserStoreState: UserStoreContextState = {
    user: {
        id: 0,
        userTypeId: 1,
        links: [],
        userAchivement: {
            dailyXp: 0,
            totalXp: 0,
            currentStreakDays: 0,
        },
        userGoal: {},
    },
    setUserState: () => {},
};
