'use client';
import Card from '../../atoms/Card';
import Box from '../../atoms/Box';

import Grid from '../Grid';
import Typography from '../../atoms/Typography';
import styles from './MenuCard.module.scss';
import Link from '../../atoms/Link';

interface MenuCardProps {
    to: string;
    text: JSX.Element | string;
    bottomText?: string;
    rightContent?: JSX.Element;
    bottomRightContent?: string | JSX.Element;
    md?: number;
    lg?: number;
    padding?: undefined | string | number;
    // eslint-disable-next-line no-unused-vars
    TextRenderer?: ({ children }: any) => JSX.Element;
    serpLocation: JSX.Element;
    createdAt?: string | Date | undefined;
}

const MenuCard = ({
    to,
    text,
    rightContent,
    bottomText,
    bottomRightContent,
    md = 12,
    lg = 6,
    padding = undefined,
    TextRenderer = ({ children }: any) => <Typography variant="h3">{children}</Typography>,
    serpLocation,
    createdAt,
}: MenuCardProps): JSX.Element => {
    return (
        <Grid item sm={md} md={md} lg={lg} padding={padding}>
            <Card className={styles.contentCard}>
                <Link to={to} style={{ color: 'var(--text-color)' }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <TextRenderer>{text}</TextRenderer>

                        {!!rightContent && rightContent}
                    </Box>

                    {bottomText && <Typography>{bottomText}</Typography>}

                    <hr style={{ border: '1px solid var(--background-color)' }} />

                    <Box
                        margin="1rem 0 0 0"
                        display="flex"
                        justifyContent="space-between"
                    >
                        {serpLocation}

                        <Box display="flex" alignItems="center">
                            {createdAt && (
                                <Typography>
                                    {new Date(createdAt).toLocaleDateString(
                                        'en-US',
                                        {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        },
                                    )}
                                </Typography>
                            )}

                            {!!bottomRightContent && bottomRightContent}
                        </Box>
                    </Box>
                </Link>
            </Card>
        </Grid>
    );
};

export default MenuCard;
