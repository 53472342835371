import styles from './ImagePicker.module.scss';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { memo } from 'react';
import Grid from '../../organisms/Grid';
import Avatar from '../../atoms/Avatar';

interface IImagePicker {
    profilePicture?: string;
    fullName?: string;
    openUploadProfileImage: () => void;
    avatarProps?: any;
    disabled?: boolean;
}

const ImagePicker: React.FC<IImagePicker> = ({
    profilePicture,
    fullName,
    openUploadProfileImage,
    avatarProps,
    disabled,
}) => {
    return (
        <Grid item margin="0 2rem 0 0">
            <div className={styles['image-picker-wrapper']}>
                <Avatar
                    className={disabled ? undefined : styles['upload-profile-avatar']}
                    src={profilePicture}
                    alt={fullName}
                    {...(avatarProps || {})}
                    size="large"
                />
                <div
                    role="button"
                    aria-label="Upload Photo"
                    className={styles['upload-profile-image-hover']}
                    onClick={openUploadProfileImage}
                    style={avatarProps}
                >
                    <AddPhotoAlternateIcon fontSize="large" />
                </div>
            </div>
        </Grid>
    );
};

export default memo(ImagePicker);
