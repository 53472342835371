import ArticleCard from '../../atoms/ArticleCard';
import { ArticlePrefix } from '@repo/utils/articleUtils';

import Box from '../../atoms/Box';
import Typography from '../../atoms/Typography';
import Grid from '../Grid';

const TrendingArticles = ({
    prefix,
    articleState,
    Image,
}: {
    prefix: ArticlePrefix;
    articleState: any;
    Image: React.ElementType;
}) => {
    if (!Image || Image === 'img') {
        throw new Error('TrendingArticles: Image props is required');
    }

    const { trendingArticles } = articleState[prefix];

    if (!trendingArticles?.length) {
        return null;
    }

    const hotestArticle: any = trendingArticles[0];

    const title = `${prefix.charAt(0).toUpperCase()}${prefix.slice(1)}`;
    const description = `Trending ${title}${prefix === 'blog' ? ' Posts' : ''}`;
    return (
        <Box margin="0 0 1rem 0">
            <Typography margin="0 0 1rem 0" variant="h2">
                {description}
            </Typography>

            <Grid container spacing={4}>
                <ArticleCard
                    Image={Image}
                    article={hotestArticle}
                    gridSize={{ md: 6, sm: 12, xs: 12 }}
                    prefix={prefix}
                    featured
                />

                <Grid item md={6}>
                    <Grid container spacing={4}>
                        {trendingArticles.slice(1, 4).map((article: any) => (
                            <ArticleCard
                                Image={Image}
                                key={article.id}
                                hideDescription={prefix === 'blog'}
                                horizontal
                                article={article}
                                gridSize={{ md: 12, sm: 6, xs: 12 }}
                                prefix={prefix}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TrendingArticles;
